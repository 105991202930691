// const deliveryFee = 10.45
// const rate = 3
// const antiFraude = 0.39
// const totalPrice = totalPriceProduct([])

import currency from 'currency.js'

// const valueTax = getAllTax(totalPrice, deliveryFee, rate, antiFraude)
// const totalWithTax = getTotalWithTax(totalPrice, valueTax)
// const totalOrder = getTotalOrder(totalPrice, valueTax, deliveryFee)

export const getMaxOfValueOfSubstitutes = (subsitutes: any[]) => {
  const listMaxPrices = subsitutes?.map((substitute) => substitute.max_price)
  return listMaxPrices?.length > 0 ? Math.max(...listMaxPrices) : 0
}

export const getTotalProductWithValueCoupon = (totalProducts: number, couponValue: number) => {
  return currency(totalProducts).subtract(couponValue).value
}

export const getMultiply = (total: number, multiply: number) => {
  return currency(total).multiply(multiply).value
}

export const getTotalProductWithPercentCoupon = (
  totalProducts: number,
  couponValue: number
) => {
  const percent = totalProducts * (couponValue / 100)
  return totalProducts - percent
}

export const totalPriceProduct = (products: any[]) => {
  const totalOfProducts = products?.reduce((acc: number, product) => {
    const moreExpensiveSubstitute = getMaxOfValueOfSubstitutes(
      product?.substitutes
    )

    if (moreExpensiveSubstitute > product.max_price) {
      return acc + moreExpensiveSubstitute * product.quantity
    }

    if (product?.normal_price) {
      return acc + product.price * product.quantity
    }

    return acc + product.max_price * product.quantity
  }, 0)

  return totalOfProducts
}

export const getAllTax = (totalProduct: number, deliveryFee: number, rate: number, antiFraude: number, discount = 0) => {
  return currency(totalProduct).add(deliveryFee).add(antiFraude).subtract(discount).multiply(rate / 100).value
}

export const getTotalOrder = (totalProduct: number, tax: number, deliveryFee: number, discount = 0) => {
  return currency(totalProduct).add(tax).add(deliveryFee).subtract(discount).value
}

export const getMoreExpensiveProductSubstitute = (substitutes: any[]) => {
  const result = substitutes.reduce((latest, currentSubstitutes) => {
    return currentSubstitutes?.max_price > latest?.max_price
      ? currentSubstitutes
      : latest
  }, substitutes[0])
  return result
}

export const getQuatityOfProductsOnCart = (products: any[]) => {
  return products?.reduce((acc: number, product: { quantity: number}) => {
    return acc + product.quantity
  }, 0)
}

export const calcCoupon = (value: number, type: string, totalOfProducts: number, deliveryFee: number) => {
  if (type === 'delivery') {
    if (deliveryFee < value) return deliveryFee

    return currency(deliveryFee).subtract(value).value
  }

  if (type === 'percent') {
    return currency(totalOfProducts).multiply(currency(value).divide(100).value).value
  }

  return value
}

export const getDeliveryTax = (deliveryFee: number, coupon: any) => {
  if (coupon?.type !== 'delivery') return deliveryFee

  if (deliveryFee < coupon.value) return deliveryFee

  return currency(deliveryFee).subtract(coupon.value).value
}
