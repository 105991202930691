import { newAxiosInstance } from 'services/api'

export interface AddCardProps {
  name: string
  cpf: string
  number: string
  exp_year: string
  exp_month: string
  branch: string
  cvv: string
}

export interface CardProps {
  id: number
  customer_id: number
  token: string
  card_type: string
  last_numbers: string
  brand: string
  cpf: string
  default: boolean
  created_at: string
}

export const getCredcard = async () =>
  newAxiosInstance.get('/customers/cards')

export const postCredcard = async (data: AddCardProps) =>
  newAxiosInstance.post('/customers/cards', data)

export const defaultCredCard = async (card: string) =>
  newAxiosInstance.get(`/customers/cards/${card}/default`)

export const deleteCredcard = async (id: string) =>
  newAxiosInstance.delete(`/customers/cards/${id}`)
