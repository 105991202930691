export const toFixed = (value: number, decimal = 2) => {
  let item = value.toString()
  item = item.slice(0, item.indexOf('.') + decimal + 1)
  return Number(item)
}

export const numberFormat = (value: number) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(toFixed(value))
