import Auth from '@aws-amplify/auth'
import { captureException } from '@sentry/nextjs'
import axios from 'axios'
import { setup } from 'axios-cache-adapter'
import useSWR from 'swr'
import { BareFetcher, PublicConfiguration } from 'swr/dist/types'

import { getToken, setToken } from './AuthService'

export const lalamoveInstance = axios.create({
  baseURL: process.env.LALAMOVE
})

export const apiCache = setup({
  baseURL: process.env.NEW_BASE_URL,
  cache: {
    maxAge: 5 * 60 * 1000
  }
})

export const axiosCEP = axios.create({
  baseURL: 'https://viacep.com.br'
})

export const axiosGoogle = axios.create({
  baseURL: 'https://api.mapbox.com'
})

const axiosInstance = axios.create({
  baseURL: process.env.BASE_URL
})

export const newAxiosInstance = axios.create({
  baseURL: process.env.NEW_BASE_URL
})

const responseIntercept = (response: any) => {
  return response
}

const errorIntercept = async function (error: any) {
  const oldToken = getToken()
  const originalRequest = error.config

  if (error?.response?.status === 401 && oldToken) {
    originalRequest._retry = true
    const { idToken } = await refreshToken()

    const valueToken = `Bearer ${idToken}`
    originalRequest.headers.authorization = valueToken
    setToken(valueToken)

    return axiosInstance(originalRequest)
  }

  if (error.response?.status === 400 || error.response?.status >= 402) {
    captureException(error.response)
  }

  return Promise.reject(error)
}

const errorHandle = (error: any) => {
  Promise.reject(error)
}

const request = async (config: any) => {
  const token = await getToken()
  if (token) {
    config.headers = {
      Authorization: token,
      Accept: 'application/json'
    }
  }
  return config
}

newAxiosInstance.interceptors.request.use(request, errorHandle)
newAxiosInstance.interceptors.response.use(responseIntercept, errorIntercept)

axiosInstance.interceptors.request.use(request, errorHandle)
axiosInstance.interceptors.response.use(responseIntercept, errorIntercept)

export async function refreshToken () {
  return new Promise<{idToken: string}>((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((currentAuthenticatedUser) => {
        Auth.currentSession()
          .then((currentSession) => {
            currentAuthenticatedUser.refreshSession(
              currentSession.getRefreshToken(),
              (error: any, response: any) => {
                if (error) {
                  reject(error)
                } else {
                  resolve({ idToken: response.idToken.jwtToken })
                }
              }
            )
          })
          .catch((error: any) => {
            reject(error)
          })
      })
      .catch((error: any) => {
        reject(error)
      })
  })
}

export function useFetch<Data = any, Error = any> (url: string, options?: Partial<PublicConfiguration<Data, Error, BareFetcher<Data>>>) {
  const { data, error, mutate } = useSWR<Data, Error>(url, async url => {
    const response = await axiosInstance.get(url)
    return response.data
  }, {
    ...options,
    errorRetryInterval: 500,
    shouldRetryOnError: true,
    onError: async (error: any) => {
      const { idToken } = await refreshToken()
      const valueToken = `Bearer ${idToken}`
      setToken(valueToken)
      setTimeout(() => {
        location.replace(location.href)
      }, 300)
      return error
    },
    onErrorRetry: async () => {
      const { idToken } = await refreshToken()
      const valueToken = `Bearer ${idToken}`
      setToken(valueToken)
      setTimeout(() => {
        location.replace(location.href)
      }, 300)
    }
  })
  return { data, error, mutate }
}

export function useNewFetch<Data = any, Error = any> (url: string, options?: Partial<PublicConfiguration<Data, Error, BareFetcher<Data>>>) {
  const { data, error, mutate } = useSWR<Data, Error>(url, async url => {
    const response = await newAxiosInstance.get(url)
    return response.data
  }, {
    ...options,
    errorRetryInterval: 500,
    shouldRetryOnError: true,
    onError: async (error: any) => {
      if (error?.status === 401) {
        const { idToken } = await refreshToken()
        const valueToken = `Bearer ${idToken}`
        setToken(valueToken)
        setTimeout(() => {
          location.replace(location.href)
        }, 300)
      }
      return error
    },
    onErrorRetry: async () => {
      const { idToken } = await refreshToken()
      const valueToken = `Bearer ${idToken}`
      setToken(valueToken)
      setTimeout(() => {
        location.replace(location.href)
      }, 300)
    }
  })

  return { data, error, mutate }
}

export default axiosInstance
