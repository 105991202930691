import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div)`
  @media screen and (min-width: 600px) {
    width: 33rem;
  }
`

export const Scroll = styled(motion.div)`
  height: calc(100vh - 370px);
  overflow: auto;
  margin-top: 1rem;
  position: relative;
  @media screen and (min-width: 600px) {
    height: 260px;
  }
`

export const ListContainer = styled.ul`
  list-style: none;
  height: auto;
  overflow-y: auto;
`

export const ViewClose = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
`