import React, { ChangeEvent } from 'react'
import { Container as ContainerGrid, Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import Image from 'next/image'

import { Button, InputText } from 'components/form'
import { Text, View } from 'components/helpers'
import { useCard } from 'hooks/useCard'
import { RootState } from 'store/reducers'
import { formatCodeCard, formatCPF, formatDateCard, formatNumberCard } from 'utils/helper/string'

import { Container } from './styles'

const CredCardRegister: React.FC = () => {
  const { app } = useSelector((state: RootState) => state)
  const { register, handleSubmit, errors, setValue } = useForm()
  const { addCredCard } = useCard()
  const dispatch = useDispatch()

  const onChangeCpf = (e: ChangeEvent<HTMLInputElement>) =>
    setValue('cpf', formatCPF(e.target.value))

  const onChangeNumber = (e: ChangeEvent<HTMLInputElement>) =>
    setValue('number', formatNumberCard(e.target.value))

  const onChangeCode = (e: ChangeEvent<HTMLInputElement>) =>
    setValue('cvv', formatCodeCard(e.target.value))

  const onChangeDate = (e: ChangeEvent<HTMLInputElement>) =>
    setValue('date', formatDateCard(e.target.value))

  const onSubmit = (data: any) => {
    dispatch(addCredCard(data))
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <View className='create-credcard-container'>
        <View
          mt={0.1}
          mb={2}
        >
          <Text
            size={18}
            fontWeight={700}
            color='#EA2A2F'
          >
            Dados do cartão
          </Text>
        </View>

        <form onSubmit={handleSubmit(onSubmit)}>

          <ContainerGrid fluid>
            <Row>
              <Col xs={12}>
                <InputText
                  name='name'
                  errorMessage={errors.name?.message}
                  ref={register({
                    required: 'O nome do titular do cartão é obrigatório'
                  })}
                  placeholder='Nome do titular do cartão'
                />
                <Text size={11} ml={0.7} mt={0.2}>Exatamente como consta no cartão</Text>
              </Col>

              <Col xs={12} style={{ marginTop: '1rem' }}>
                <InputText
                  name='number'
                  errorMessage={errors.number?.message}
                  ref={register({
                    required: 'Digite seu número do cartão'
                  })}
                  placeholder='Número do cartão'
                  inputMode='numeric'
                  onChange={onChangeNumber}
                />
              </Col>

              <Col xs={6} style={{ marginTop: '1rem' }}>
                <InputText
                  name='date'
                  errorMessage={errors.data?.message}
                  ref={register({
                    required: 'Digite a data de validade'
                  })}
                  placeholder='Validade'
                  inputMode='numeric'
                  maxLength={5}
                  onChange={onChangeDate}
                />
              </Col>

              <Col xs={6} style={{ marginTop: '1rem' }}>
                <InputText
                  name='cvv'
                  errorMessage={errors.cvv?.message}
                  ref={register({
                    required: 'Digite código secreto do cartão'
                  })}
                  placeholder='cvv'
                  inputMode='numeric'
                  onChange={onChangeCode}
                  maxLength={3}
                />
              </Col>

              <Col xs={12} style={{ marginTop: '1rem' }}>
                <InputText
                  name='cpf'
                  errorMessage={errors.cpf?.message}
                  ref={register({
                    required: 'CPF é obrigatório.'
                  })}
                  onChange={onChangeCpf}
                  placeholder='CPF do titular do cartão'
                  inputMode='numeric'
                />
              </Col>
            </Row>

          </ContainerGrid>

          <View mt={2}>
            <Image
              src='/icon/icon-security.svg'
              height={20}
              width={20}
              alt='Site seguro'
            />
            <Text mt={1} size={10} textAlign='center'>Este é um ambiente seguro e protegido.</Text>
          </View>

          <View mt={2}>
            <Button
              label='Cadastrar'
              loading={app.loading}
              background='#3097E2'
            />
          </View>
        </form>
      </View>
    </Container>
  )
}

export default CredCardRegister
