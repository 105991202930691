import styled from 'styled-components'

export const Container = styled.div<{toggle: number}>`
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  width: ${({ toggle = 0 }) => toggle ? '100%' : '0'};
  padding-top: 3rem;
  overflow: hidden;
  @media screen and (min-width: 600px) {
    padding-top: 0rem;
  }
`

export const View = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

export const Content = styled.div<{toggle: number}>`
  position: fixed;
  bottom: 0;
  background-color: #fefefe;
  width: 100%;
  display: ${({ toggle = 0 }) => toggle ? 'inline-block' : 'none'};
  padding: 0rem;
  height: calc(100vh - 70px);
  border: 1px solid #EDEDED;
  transition: 0.6s;
  z-index: 5;

  @media screen and (min-width: 600px) {
    height: 100%;
    background-color: #fff;
    min-width: 30rem;
    width: 30rem;
    top: 0;
    right: 0;
    border-radius: 0;
  }

`
