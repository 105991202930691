
declare global {
  interface Window {
    gtag?: any
  }
}

export const GA_TRACKING_ID = process.env.GOOGLE_GA

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: any) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  })
}

export const eventGA = (eventCategory: string, eventLabel: string = '') => {
  window.gtag('send', 'event', {
    eventCategory,
    eventAction: 'click',
    eventLabel
  })
}
