import styled from 'styled-components'

export const Container = styled.div<{background?: string}>`
  width: 100%;
  background-color: ${({ background = '#F2F2F2' }) => background};
  position: sticky;
  z-index: 2;
  top: 0;

  @media screen and (min-width: 580px) {
    border-bottom: 1px solid #ECECEC;
  }
 
  @media screen and (max-width: 600px) {
    background-color: #ffffff;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  max-width: 1366px !important;
  margin: 0 auto;
  height: 4.4rem;
  @media screen and (min-width: 580px) {
    height: 5rem;
  }
`

export const Circle = styled.div`
  background-color: #FF0037;
  border-radius: 30px;
  padding: 0.7em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 20px;
  height: 20px;
  color: #fff;
  position: absolute;
  top: -10px;
  right: -9px;
`

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bolder;
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: 576px) {
    display: none;
  }
`
