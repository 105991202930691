import { isLogged } from 'services/AuthService'

export function getUserLocalLocation (user: any, addressTemporary: any) {
  if (isLogged()) {
    let userAddress
    if (user.address_selected) {
      userAddress = user.delivery_addresses.find((item: any) => item.pub_id === user.address_selected?.pub_id)
    } else {
      userAddress = user.delivery_addresses[0]
    }
    if (userAddress) return { lat: userAddress.lat, lng: userAddress.lng }
  }
  if (addressTemporary.lat) return { lat: addressTemporary.lat, lng: addressTemporary.lng }
  return {}
}
