import styled from 'styled-components'

interface RowProps {
  mb?: number
  mt?: number
  mr?: number
  ml?: number
  justify?: string
  align?: string
  width?: string
  height?: string
  pt?: number
  pb?: number
  pr?: number
  pl?: number
  cursor?: boolean
}

export default styled.div<RowProps>`
  position: relative;
  ${({ mb = 0 }) => mb && `margin-bottom: ${mb}rem`};
  ${({ mt = 0 }) => mt && `margin-top: ${mt}rem`};
  ${({ mr = 0 }) => mr && `margin-right: ${mr}rem`};
  ${({ ml = 0 }) => ml && `margin-left: ${ml}rem`};

  ${({ pb = 0 }) => pb && `padding-bottom: ${pb}rem`};
  ${({ pt = 0 }) => pt && `padding-top: ${pt}rem`};
  ${({ pr = 0 }) => pr && `padding-right: ${pr}rem`};
  ${({ pl = 0 }) => pl && `padding-left: ${pl}rem`};
  display: flex;
  flex-direction: row;
  ${({ width }) => width && `width: ${width};`}
  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ align }) => align && `align-items: ${align};`}
  ${({ cursor }) => cursor && 'cursor: pointer;'};
`
