import React from 'react'

import { Container, Box } from './styles'

const LoadingSimple: React.FC = () => {
  return (
    <Container>
      <Box>
        <div /><div />
      </Box>
    </Container>
  )
}

export default LoadingSimple
