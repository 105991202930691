import { forwardRef, InputHTMLAttributes } from 'react'

import { Container, InputSelect, Text } from './styles'

export interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  width?: string
  errorMessage?: string
}

const SelectShadow = (
  { children, width, errorMessage, ...props }: SelectProps,
  forwardedRef: React.Ref<HTMLSelectElement>
) => {
  return (
    <>
      <Container width={width}>
        <InputSelect ref={forwardedRef} {...props}>
          {children}
        </InputSelect>
      </Container>
      {errorMessage && (<Text>{errorMessage}</Text>)}
    </>
  )
}

export default forwardRef(SelectShadow)
