import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #ededed;
  width: 100%;
  border-radius: 8px;
  padding: 0.6rem;
`
export const TextInput = styled.textarea`
  width: 100%;
  max-width: 100%;
  line-height: 1.5;
  border-radius: 5px;
  border: none;
  resize: none;
`
