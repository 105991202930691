import styled from 'styled-components'

export default styled.div`
  display: flex;
  justify-content: space-between;
  overflow: auto;
  padding-bottom: 1rem;
  ::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: #EDEDED;
  }
  ::-webkit-scrollbar-thumb:horizontal{
    background-color: #9a9a9a;
    border-radius: 10px;
  }
`
