import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Link from 'next/link'

import Icon from 'components/elements/Icon'
import { Text } from 'components/helpers'
import { useAuth } from 'hooks/useAuth'
import { RootState } from 'store/reducers'
import { disableMenuListAction } from 'store/reducers/appReducer'

import { Container, Content, Item } from './styles'

const MenuSelect: React.FC = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state)
  const menuRef = useRef<HTMLDivElement>(null)
  const { signOut } = useAuth()

  const handleClick = (nativeEvent: { target: any }) => {
    if (
      menuRef.current !== null &&
      menuRef.current.contains(nativeEvent.target)
    ) {
      setTimeout(() => dispatch(disableMenuListAction()), 30)
      return
    }
    dispatch(disableMenuListAction())
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    return () => document.removeEventListener('click', handleClick, true)
  }, [])

  const onPressLogout = () => {
    signOut()
  }

  const handleHelp = () => {
    window.open(
      'https://resource.farmazon.com.br/faq/index.html',
      '_blank',
      'noopener,noreferrer'
    )
  }

  return (
    <Container ref={menuRef}>
      <Content>
        <Link href='/perfil' passHref>
          <Item>
            <Icon name='user-circle' />
            <Text ml={0.5} size={14}>
              Meus dados
            </Text>
          </Item>
        </Link>
        <Link href='/pedidos' passHref>
          <Item>
            <Icon name='receipt' />
            <Text ml={0.5} size={14}>
              Meus pedidos
            </Text>
          </Item>
        </Link>
        {user.userRole.includes('homecare') ? (
          <Link href='/homecare' passHref>
            <Item>
              <Icon name='heartbeat' />
              <Text ml={0.5} size={14}>
                Home care
              </Text>
            </Item>
          </Link>
        ) : null}
        <Link href='/cartoes' passHref>
          <Item>
            <Icon name='credit-card' />
            <Text ml={0.5} size={14}>
              Meus cartões
            </Text>
          </Item>
        </Link>
        <Link href='/enderecos' passHref>
          <Item>
            <Icon name='map-pin' />
            <Text ml={0.5} size={14}>
              Meus endereços
            </Text>
          </Item>
        </Link>

        <Item onClick={handleHelp}>
          <Icon name='question' />
          <Text ml={0.5} size={14}>
            Perguntas Frequentes
          </Text>
        </Item>

        {/* <Link href='/receitas' passHref>
          <Item>
          <Text size={14}>Minhas receitas</Text>
          </Item>
        </Link> */}
        <Item id='sair' onClick={onPressLogout}>
          <Icon name='sign-out' />
          <Text ml={0.5} size={14}>
            Sair
          </Text>
        </Item>
      </Content>
    </Container>
  )
}

export default MenuSelect
