import styled from 'styled-components'

export const Container = styled.div`
  border-bottom: 1px solid #ECECEC;
  border-top: 1px solid #ECECEC;
  background-color: #fff;

  @media screen and (max-width: 600px) {
    background: #F9F9F9;

    border-top: 0px;
    border-bottom: 0px;

    border-radius: 4px;
  }
`

export const Content = styled.div`
  display: flex;
  max-width: 1366px !important;
  margin: 0 auto;
`

export const Input = styled.input`
  padding: 1rem 0;
  color: #3E3E3E;
  display: flex;
  flex: 1;
  height: 50px;
  border: #3E3E3E;
  outline-width: 0;
  margin-left: 1rem;

  @media screen and (max-width: 600px) {
    background: #F9F9F9;
  }
`

export const SearchButton = styled.button`
  width: 50px;
  height: 50px;

  border: 0px;

  background: #1976D2;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`
