import styled from 'styled-components'

interface ViewProps {
  mb?: number
  mt?: number
  mr?: number
  ml?: number
  justify?: string
  align?: string
  width?: string
  height?: string
  pt?: number
  pb?: number
  pr?: number
  pl?: number
  padding?: number
  cursor?: boolean | string
  direction?: string
  gap?: number
}

export default styled.div<ViewProps>`
  ${({ mb = 0 }) => mb && `margin-bottom: ${mb}rem`};
  ${({ mt = 0 }) => mt && `margin-top: ${mt}rem`};
  ${({ mr = 0 }) => mr && `margin-right: ${mr}rem`};
  ${({ ml = 0 }) => ml && `margin-left: ${ml}rem`};

  ${({ pb = 0 }) => pb && `padding-bottom: ${pb}rem`};
  ${({ pt = 0 }) => pt && `padding-top: ${pt}rem`};
  ${({ pr = 0 }) => pr && `padding-right: ${pr}rem`};
  ${({ pl = 0 }) => pl && `padding-left: ${pl}rem`};
  ${({ padding = 0 }) => padding && `padding: ${padding}rem`};
  ${({ gap = 0 }) => gap && `gap: ${gap}rem`};

  display: flex;
  ${({ justify }) => justify && `
    display: flex;
    justify-content: ${justify};
  `}
  ${({ direction = 'column' }) => direction && `flex-direction: ${direction};`};
  ${({ align }) => align && `align-items: ${align};`};
  ${({ width }) => width && `width: ${width};`};
  ${({ height }) => height && `height: ${height};`};
  ${({ cursor }) => cursor && 'cursor: pointer;'};
  position: relative;
`
