import { Line, Text, View } from 'components/helpers'

export default function OptionsInformation () {
  return (
    <View style={{ maxWidth: '450px' }}>
      <Text
        mt={1}
        mb={1}
        size={22}
        fontWeight={500}
        textAlign='center'
        color='#EA2A2F'
      >
        Detalhes das suas opções
      </Text>

      <View mt={1}>
        <Text
          size={16}
          fontWeight={500}
          mb={1}
        >
          Farmácia pode escolher um similar
        </Text>
        <Text
          size={14}
          fontWeight={400}
        >
          Se você optar que a farmácia escolha um produto similar, ela poderá escolher qualquer outra marca relacionada ao produto que você incluir no carrinho.
        </Text>

        <View
          mt={1}
          mb={1}
        >
          <Line type='dashed' />
        </View>
      </View>

      {/* <View
        mt={1}
      >
        <Text
          size={16}
          fontWeight={500}
          mb={1}
        >
          Quero escolher um substituto
        </Text>
        <Text
          size={14}
          fontWeight={400}
        >
          Você poderá escolher 1 ou mais substitutos caso a farmácia não tenha o seu produto em estoque ou ele esteja esgotado.
        </Text>

        <View
          mt={1}
          mb={1}
        >
          <Line type='dashed' />
        </View>
      </View> */}

      <View
        mt={1}
      >
        <Text
          size={16}
          fontWeight={500}
          mb={1}
        >
          Tudo bem, não é obrigatório
        </Text>
        <Text
          size={14}
          fontWeight={400}
        >
          Marcando essa opção, você deixa claro para a farmácia que se ela não tiver esse produto ela não precisa incluir no pedido
        </Text>

        <View
          mt={1}
          mb={1}
        >
          <Line type='dashed' />
        </View>
      </View>

      <View
        mt={1}
      >
        <Text
          size={16}
          fontWeight={500}
          mb={1}
        >
          Este produto é obrigatório
        </Text>
        <Text
          size={14}
          fontWeight={400}
        >
          Marcando essa opção, você deixa claro para a farmácia que se ela não tiver esse produto ela não precisa incluir no pedido
        </Text>
      </View>

    </View>
  )
}
