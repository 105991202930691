import { AlertComponentPropsWithStyle } from 'react-alert'

import { getMobileClassName } from 'utils/helper/className'

import { Container, Content, Text, View } from './styles'

const Alert: React.FC<AlertComponentPropsWithStyle> = ({
  style,
  options,
  message,
  close
}: any) => {
  return (
    <Container style={style} className={getMobileClassName()} type={options.type}>
      <Content>
        <Text>{message}</Text>
        <View onClick={close}>X</View>
      </Content>
    </Container>
  )
}

export default Alert
