import { useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch } from 'react-redux'

import creditCardType from 'credit-card-type'
import { Dispatch } from 'redux'
import { defaultCredCard, deleteCredcard, getCredcard, postCredcard } from 'services/credcard'
import { RootState } from 'store/reducers'
import { disableLoadingAction, disableLocationAction, enableLoadingAction, enableLocationAction, setScreenAction } from 'store/reducers/appReducer'
import { addUserCardsAction } from 'store/reducers/userReducer'
import { mutate } from 'swr'

export const useCard = () => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const [cardForDetele, setCardForDelete] = useState<string>()

  const updateCardForDelete = (id?: string) => {
    return () => {
      setCardForDelete(id)
    }
  }

  const addCredCard = (data: any) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
      dispatch(enableLoadingAction())
      // @ts-ignore
      alert.removeAll()
      const month = data.date?.split('/')[0]
      const year = `20${data.date?.split('/')[1]}`
      const number = data.number.replace(/ /g, '').toString()
      try {
        const payload = {
          name: data.name,
          exp_year: year,
          exp_month: month,
          cvv: data.cvv,
          number,
          branch: creditCardType(number)[0].type,
          cpf: data.cpf.replace(/[^\w\s]/gi, '').toString()
        }
        const response = await postCredcard(payload)
        await defaultCredCard(response.data.pub_id)

        const { data: dataCards } = await getCredcard()

        mutate('/customers/cards/')

        batch(() => {
          dispatch(addUserCardsAction(dataCards.Items))
          dispatch(setScreenAction(''))
          dispatch(disableLocationAction())
        })

        alert.success('Cartão cadastrado com sucesso.')
      } catch (error: any) {
        alert.error(error.response?.data?.message || 'Não foi possível adicionar, verifique os dados.')
      } finally {
        dispatch(disableLoadingAction())
      }
    }
  }

  const deleteCardById = (id: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
      const { user } = getState()
      try {
        // @ts-ignore
        alert.removeAll()
        await deleteCredcard(id)
        dispatch(disableLocationAction())

        const listCard = user.cards.filter(item => item.pub_id !== id)
        dispatch(addUserCardsAction(listCard))
        mutate('/customers/cards/')

        alert.success('Cartão removido com sucesso.')
      } catch (error) {
        alert.error(`Erro ao tentar deletar o cartão: ${error}`)
      }
    }
  }

  const setDefaultCredcard = async (id: string) => {
    try {
      // @ts-ignore
      alert.removeAll()
      await defaultCredCard(id)
      dispatch(disableLocationAction())
      mutate('/customers/cards/')
      alert.success('Cartão definido com sucesso.')
    } catch (error) {
      alert.error(`Erro ao tentar definir um novo cartão: ${error}`)
    }
  }

  const handleAddCredcard = () => {
    batch(() => {
      dispatch(enableLocationAction())
      dispatch(setScreenAction('CredCardRegister'))
    })
  }

  return {
    cardForDetele,
    updateCardForDelete,
    addCredCard,
    deleteCardById,
    setDefaultCredcard,
    handleAddCredcard
  }
}
