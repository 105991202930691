
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import { setScreenAction } from 'store/reducers/appReducer'

import { BackView, Container, Content, View } from './styles'
import { iconSize } from 'styles'

interface ModalProps {
  onBack?: () => void
  onPressClose?: () => void
  disableClose?: boolean
  children?: any
  isFull?: boolean
  onBottom?: boolean
  paddingTop?: number
  widthContent?: string
}

const Modal: React.FC<ModalProps> = ({
  children,
  onBack,
  onPressClose,
  disableClose = false,
  isFull = false,
  onBottom = false,
  paddingTop,
  widthContent
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  })

  const handleClose = () => {
    if (onPressClose) onPressClose()
    else dispatch(setScreenAction(''))
  }

  return (
    <Container isFull={isFull} paddingTop={paddingTop}>
      <Content onBottom={onBottom} isFull={isFull} width={widthContent}>
        {onBack && (
          <BackView onClick={onBack}>
            <ArrowBackIcon style={iconSize} />
          </BackView>
        )}
        {!disableClose && (
          <View onClick={handleClose}>
            <CloseIcon style={iconSize} />
          </View>
        )}
        {children}
      </Content>
    </Container>
  )
}

export default Modal
