import Script from 'next/script'

import { GA_TRACKING_ID } from '../../utils/gtag'

const Analytics = () => (
  <>
    <Script
      strategy='afterInteractive'
      src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
    />
    <Script
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
      }}
    />

    <Script
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.GOOGLE_GTM}');
        `
      }}
    />

    <Script
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
        WebFontConfig = {
          google: {
            families: ['Ubuntu:300,400,500,700']
          }
        };
      
        (function(d) {
          var wf = d.createElement('script'), s = d.scripts[0];
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
          wf.async = true;
          s.parentNode.insertBefore(wf, s);
           })(document);
        `
      }}
    />

    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${process.env.GOOGLE_GTM}`}
        height='0' width='0' style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>

  </>
)

export default Analytics
