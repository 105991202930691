import { useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch } from 'react-redux'

import Router from 'next/router'

import { Auth } from 'aws-amplify'
import moment from 'moment'
import { isEmpty } from 'ramda'
import { Dispatch } from 'redux'
import { getAllAddress } from 'services/address'
import { cleanPhoneNumber, postSignIn, setToken, clearToken } from 'services/AuthService'
import { getCredcard } from 'services/credcard'
import { newVersion, RootState } from 'store/reducers'
import { addAddressTemporaryAction } from 'store/reducers/addressTemporaryReducer'
import { disableLoadingAction, enableLoadingAction, resetAppAction, setScreenAction, disableLocationAction, enableLocationAction } from 'store/reducers/appReducer'
import { addCPFOrderAction, resetCartAction } from 'store/reducers/cartReducer'
import { addListAddressesAction, addNameEmailAction, addProfileAction, addUserCardsAction, addUserNameAction, cleanPasswordAction, resetUserState, setAddressSelectedAction, updateCPF } from 'store/reducers/userReducer'

const authMessage: { [key: string]: string } = {
  'Incorrect username or password.': 'Telefone ou senha inválido',
  'Password attempts exceeded': 'Tentativas de senha excedidas',
  'Invalid verification code provided, please try again.':
  'Código de verificação inválido',
  'An account with the given phone_number already exists.':
  'Uma conta com este mesmo número já existe',
  'Usuário não pertence ao grupo.': 'Usuário não pertence ao grupo.',
  'Usuário não faz parte do grupo.': 'Usuário não faz parte do grupo.',
  'User does not exist.': 'Usuário não encontrado'
}

export const useAuth = () => {
  const alert = useAlert()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const signIn = (username: string, password: string) => {
    return async (dispatch: Dispatch) => {
      setLoading(true)
      // @ts-ignore
      alert.removeAll()

      // const phoneNumber = `+55${username.replace(/\D/g, '')}`
      try {
        const user = await postSignIn(username, password)

        if (user.userRole === undefined) {
          throw new Error('Usuário não faz parte do grupo.')
        }

        if (user.userRole instanceof Array && !user.userRole.includes('customers')) {
          throw new Error('Usuário não pertence ao grupo.')
        }

        if (user.status === 'FORCE_CHANGE_PASSWORD') {
          await Auth.forgotPassword(username)
          localStorage.setItem('@farmazon-client/recover-phone', username)

          alert.success('Recupere seu acesso seguindo próximo passo.')
          Router.push('/recoveryConfirm')
          return
        }

        const token_type = 'Bearer'
        const token_jwt = `${token_type} ${user.accessToken}`
        setToken(token_jwt)
        dispatch(disableLocationAction())
        dispatch(updateCPF(user.cpf))
        dispatch(addCPFOrderAction(user.cpf))
        updateProfile(user.userRole)(dispatch)

        const payload = {
          type: 'signedIn',
          params: {
            token: user.accessToken,
            isUserAdmin: user.userRole.includes('admin')
          }
        }

        if ((window as any).ReactNativeWebView) {
          (window as any).ReactNativeWebView.postMessage(JSON.stringify(payload))
        }

        alert.success('Usuário logado com sucesso.')

        Promise
          .all([getAllAddress(), getCredcard()])
          .then(([{ data }, { data: cards }]) => {
            batch(() => {
              dispatch(addListAddressesAction(data.Items))
              dispatch(addUserCardsAction(cards.Items))
            })

            if (data.Items?.length) {
              let currentAddress = data.Items.find(item => item.default)
              if (!currentAddress) currentAddress = data.Items?.[0]

              if (currentAddress) {
                dispatch(setAddressSelectedAction(currentAddress.street, currentAddress.pub_id, currentAddress.city))
              }
              Router.push('/')
            } else {
              batch(() => {
                dispatch(enableLocationAction())
                dispatch(setScreenAction('StreetRegister'))
              })
              Router.push('/enderecos')
            }
          })
      } catch (error: any) {
        if (error.code === 'UserNotConfirmedException') {
          await Auth.resendSignUp(username)
          Router.push('/signupconfirm')
          return
        }
        console.log(error.message)
        alert.error(authMessage[error.message] || error.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const forgetPassInit = (phone: string) => {
    return async (dispatch: Dispatch) => {
      dispatch(enableLoadingAction())
      const phoneNumber = cleanPhoneNumber(phone)
      try {
        await Auth.forgotPassword(`+55${phoneNumber}`)
        dispatch(addUserNameAction(`+55${phoneNumber}`))
        dispatch(setScreenAction('RecoverInput'))
      } catch (error: any) {
        alert.error(authMessage[error.message] || error.message)
      } finally {
        dispatch(disableLoadingAction())
      }
    }
  }

  const forgetPassFinal = (username: string, password: string, code: string) => {
    return async (dispatch: Dispatch) => {
      dispatch(enableLoadingAction())
      try {
        await Auth.forgotPasswordSubmit(username, code, password)
        dispatch(disableLocationAction())
        signIn(username, password)(dispatch)
      } catch (error: any) {
        alert.error(authMessage[error.message] || error.message)
      } finally {
        dispatch(disableLoadingAction())
      }
    }
  }

  const createNewUser = ({ name, cpf, username, password, email, birthdate }: any) => {
    return async (dispatch: Dispatch) => {
      dispatch(enableLoadingAction())
      const formatCpf = cpf.replace(/[^0-9]+/g, '')
      const formatUsername = `+55${username.replace(/\D/g, '')}`
      const formatBirthdate = moment(birthdate).format('DD/MM/YYYY')
      try {
        const signUpResponse = await Auth.signUp({
          username: formatUsername,
          password,
          attributes: {
            name,
            email,
            birthdate: formatBirthdate,
            'custom:cpf': formatCpf
          }
        })

        dispatch(addProfileAction({
          username: formatUsername,
          password,
          email: email,
          cpf: formatCpf,
          name,
          birthdate: formatBirthdate,
          userConfirmed: signUpResponse.userConfirmed
        }))
      } catch (error: any) {
        alert.error(error.message)
      } finally {
        dispatch(disableLoadingAction())
      }
    }
  }

  const checkCode = (phone: string, password: string, code: string) => {
    return async (dispatch: Dispatch) => {
      dispatch(enableLoadingAction())
      try {
        const confirmPINResponse = await Auth.confirmSignUp(String(phone), code)
        if (confirmPINResponse === 'SUCCESS') {
          dispatch(resetAppAction())
          dispatch(cleanPasswordAction())
          if (isEmpty(password)) {
            alert.success('Código verificado com sucesso, faço o login.')
            Router.push('/signin')
          } else {
            await signIn(String(phone), password)(dispatch)
            location.reload()
          }
          return
        }
        throw new Error(confirmPINResponse)
      } catch (error: any) {
        alert.error(error.message)
      } finally {
        dispatch(disableLoadingAction())
      }
    }
  }

  const updateProfile = (userRole: string[]) => {
    return async (dispatch: Dispatch) => {
      try {
        const { attributes } = await Auth.currentAuthenticatedUser()
        dispatch(addProfileAction({
          name: attributes.name,
          email: attributes.email,
          username: attributes.phone_number,
          birthdate: attributes.birthdate,
          cpf: attributes['custom:cpf'],
          userRole
        }))
      } catch (error) {}
    }
  }

  const signOut = () => {
    clearToken()
    localStorage.setItem(`persist:${newVersion}`, '')
    dispatch(resetUserState())
    dispatch(resetCartAction())
    setTimeout(() => {
      Auth.signOut()
      batch(() => {
        dispatch(addAddressTemporaryAction({
          street: 'Rua Almirante Pereira Guimarães',
          lat: -22.9864275,
          lng: -43.2167975
        }))
        dispatch(setScreenAction(''))
      })
      location.replace('/')
    }, 100)
  }

  const resendPin = (username: string) => {
    Auth.resendSignUp(username)
    alert.success('O código PIN foi reenviado com sucesso!')
  }

  const updateMyProfile = async (data: { username: string, email: string}) => {
    dispatch(enableLoadingAction())
    try {
      dispatch(addNameEmailAction(data))
      const user = await Auth.currentAuthenticatedUser()
      Auth.updateUserAttributes(user, data)
      alert.success('Dados atualizado com sucesso.')
    } catch (error: any) {
      alert.error(error.message)
    } finally {
      dispatch(disableLoadingAction())
    }
  }

  const doSignUp = async (data: any) => {
    setLoading(true)
    try {
      const formatUsername = `+55${data.username.replace(/\D/g, '')}`
      await Auth.signUp({
        username: formatUsername,
        password: data.password,
        attributes: {
          name: data.name,
          email: data.email
        }
      })

      localStorage.setItem('@farmazon-client/recover-phone', formatUsername)
      localStorage.setItem('@farmazon-client/temp-pass', data.password)

      Router.push('/signupconfirm')
    } catch (error: any) {
      if (error.code === '"UsernameExistsException"') {
        alert.error('Já existe uma conta com o número fornecido.')
      } else {
        alert.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const confirmPIN = (code: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
      setLoading(true)
      try {
        if (String(code).length < 6) throw new Error()

        const username = localStorage.getItem('@farmazon-client/recover-phone') ?? ''
        const password = localStorage.getItem('@farmazon-client/temp-pass') ?? ''

        const confirmPINResponse = await Auth.confirmSignUp(username, code)
        if (confirmPINResponse !== 'SUCCESS') throw new Error(confirmPINResponse)

        const result = await Auth.signIn(username, password)
        setToken(`Bearer ${result.signInUserSession.idToken.jwtToken}`)

        localStorage.removeItem('@farmazon-client/recover-phone')
        localStorage.removeItem('@farmazon-client/temp-pass')

        dispatch(setScreenAction('StreetRegister'))

        alert.success('Usuário cadastrado com sucesso.')
        Router.push('/')
      } catch (error: any) {
        alert.error('Falha na confirmação do PIN.')
      } finally {
        setLoading(false)
      }
    }
  }

  const handleReSend = async () => {
    try {
      const username = localStorage.getItem('@farmazon-client/recover-phone') ?? ''
      await Auth.resendSignUp(username)
      alert.success('Código reenviado com sucesso')
    } catch (error) {
      alert.error('Falha no envio do novo código')
    }
  }

  const forgotPasswordRequest = async (username: string) => {
    setLoading(true)
    try {
      const formatUsername = `+55${username.replace(/\D/g, '')}`
      await Auth.forgotPassword(formatUsername)

      localStorage.setItem('@farmazon-client/recover-phone', formatUsername)

      alert.success('Pedido de recuperar senha realizado. Você receberá um e-mail com pin.')
      Router.push('/recoveryConfirm')
    } catch (error) {
      alert.error('Falha envio do código.')
    } finally {
      setLoading(false)
    }
  }

  const forgotPasswordSubmit = async (code: string, password: string) => {
    setLoading(true)
    try {
      if (String(code).length < 6) throw new Error()

      const username = localStorage.getItem('@farmazon-client/recover-phone') ?? ''
      await Auth.forgotPasswordSubmit(username, code, password)

      alert.success('Senha alterada com sucesso.')

      localStorage.removeItem('@farmazon-client/recover-phone')
      Router.push('/')
    } catch (error) {
      alert.error('Falha envio do código.')
    } finally {
      setLoading(false)
    }
  }

  const resendSignPIN = async () => {
    try {
      const username = localStorage.getItem('@farmazon-client/recover-phone') ?? ''
      await Auth.forgotPassword(username)
      alert.success('Código reenviado com sucesso')
    } catch (error: any) {
      alert.error('Falha no envio do novo código')
    }
  }

  const resendPIN = async () => {
    try {
      const username = localStorage.getItem('@farmazon-client/recover-phone') ?? ''
      await Auth.resendSignUp(username)
      alert.success('Código reenviado com sucesso')
    } catch (error: any) {
      alert.error('Falha no envio do novo código')
    }
  }

  return {
    loading,
    resendSignPIN,
    resendPIN,
    doSignUp,
    confirmPIN,
    updateMyProfile,
    resendPin,
    signOut,
    updateProfile,
    signIn,
    forgetPassInit,
    forgetPassFinal,
    createNewUser,
    checkCode,
    handleReSend,
    forgotPasswordRequest,
    forgotPasswordSubmit
  }
}
