import { AnyAction } from 'redux'

const RESET_APP = 'addressTemporaryReducer/RESET_APP'
const ADD_ADDRESS = 'addressTemporaryReducer/ADD_ADDRESS'

export interface StateAddressTemporaryProps {
  hasTemporaryAddress: boolean
  street: string
  number: string
  more: string
  city: string
  state: string
  cep: string
  neighborhood: string
  lat: number
  lng: number
  country: string
}

const stateInit = {
  hasTemporaryAddress: false,
  street: '',
  number: '',
  more: '',
  city: '',
  state: '',
  cep: '',
  neighborhood: '',
  country: 'Brasil',
  lat: 0,
  lng: 0
}

export const addressTemporaryReducer = (state: StateAddressTemporaryProps = stateInit, { type, payload }: AnyAction) => {
  switch (type) {
    case ADD_ADDRESS:
      return {
        ...state,
        lat: payload.lat,
        lng: payload.lng,
        hasTemporaryAddress: true,
        cep: payload.cep,
        street: payload.street,
        neighborhood: payload.neighborhood,
        country: payload.country,
        state: payload.state,
        city: payload.city
      }
    case RESET_APP:
      return stateInit
    default:
      return state
  }
}

export const addAddressTemporaryAction = (payload: Partial<StateAddressTemporaryProps>) =>
  ({ type: ADD_ADDRESS, payload })

export const resetAddressTemporaryAction = () =>
  ({ type: RESET_APP })
