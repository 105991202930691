import { useEffect, useState } from 'react'

export default function useIsMobile () {
  const [size, setSize] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setSize(window.innerWidth)
    })

    return () => {
      window.removeEventListener('resize', () => {
        setSize(window.innerWidth)
      })
    }
  }, [])

  return size <= 768
}
