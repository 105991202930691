import { AnyAction } from 'redux'

const ADD_LIST = 'similarReducer/ADD_LIST'
const ADD_SIMILAR = 'similarReducer/ADD_SIMILAR'
const CLEAN = 'similarReducer/CLEAN'
const ADD_ID_PRODUCT = 'similarReducer/ADD_ID_PRODUCT'

export interface StateSimilarProps {
  idProduct: string
  listSimilar: any[]
  substitutes: string[]
}

const stateInit = {
  idProduct: '',
  listSimilar: [],
  substitutes: []
}

export const similarReducer = (state: StateSimilarProps = stateInit, { type, payload }: AnyAction) => {
  switch (type) {
    case ADD_ID_PRODUCT:
      return {
        ...state,
        idProduct: payload
      }
    case ADD_LIST:
      return {
        ...state,
        listSimilar: payload
      }
    case ADD_SIMILAR:
      return {
        ...state,
        substitutes: payload
      }
    case CLEAN:
      return stateInit
    default:
      return state
  }
}

export const addIdProductSubAction = (payload: string) =>
  ({ type: ADD_ID_PRODUCT, payload })

export const addListSimilarAction = (payload: any) =>
  ({ type: ADD_LIST, payload })

export const addSimilarAction = (payload: string[]) =>
  ({ type: ADD_SIMILAR, payload })

export const cleanSimilarAction = () =>
  ({ type: CLEAN })
