import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, div#__next {
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 16px;
    line-height: 1.2;
    color: #3E3E3E;
    height: 100vh;
    -webkit-overflow-scrolling:touch;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    line-height: inherit;
  }

  *:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .alert-custom > div{
    width: 100%;
  }
  ::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: #EDEDED;
  }
  ::-webkit-scrollbar-thumb{
    background-color: #9a9a9a;
    border-radius: 10px;
  }

  .BrainhubCarousel__arrows {
    background-color: #FF0037!important;
  }

  .BrainhubCarousel__arrowLeft {
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .BrainhubCarousel__arrowRight {
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .rmdp-shadow {
    box-shadow: none;
  }

  a[href^="http://maps.google.com/maps"]{display:none !important}
  a[href^="https://maps.google.com/maps"]{display:none !important}

  .gmnoprint a, .gmnoprint span, .gm-style-cc {
      display:none;
  }
  .gmnoprint div {
      background:none !important;
  }

  .BrainhubCarousel__dot--selected {
    background-color: #3097E2 !important;
  }

  .BrainhubCarousel__dot {
    width: 20px;
    height: 5px;
    border-radius: 4px;
    background-color: #C4C4C4;
    padding: 0!important;
  }
  .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    padding: 0;
    content: none!important;
  }

  .app-block {
    flex-wrap: wrap;
    background:url(/banner/background-banner.png) repeat-y 0 0 transparent;
    background-size:cover;
    min-height: 125px;
    width:100%;
    > p {
      text-align:center;
      max-width: 380px;
      @media(min-width:690px){
        max-width:380px;
      }
      @media(min-width:870px){
        text-align:left;
      }
    }
    &__apps {
      @media(min-width:1024px){
        display: flex;
        align-items: center
      }
      @media(min-width:1024px){
        width: calc(100% - 380px);
        justify-content: space-around;
      }
      > p {
        text-align:center;
        padding:10px 0;
      }
    }
    &__images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      @media(min-width:900px){
        flex-wrap:nowrap;
      }
    }
  }
  .cat {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));

    @media(min-width:750px){
      overflow-x:hidden;
    }
    
    &__block {
      height:auto;
      padding:7px 0;

      @media(min-width:1024px){
        padding:16px 0;
      }
      
      @media(min-width:1200px){
        padding:22px 0;
      }
    }
    &__link {
      margin: 0.2rem 0.5rem;
    }
  }
  .showcase {
    justify-content:flex-start;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 240px));
    grid-gap: 2rem;
    
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, auto));
      grid-gap: 0.5rem;
    }
    
    &__item {
      height:24rem;
      margin-bottom:1rem;
      cursor:pointer;
      justify-content:flex-start;
    }
    &__link {
      display:block;
      text-align: center;
    }
    &__image {
      display:block;
      width:100%;
    }
  }
  .subtitle__link {
    font-size: 13px;
    font-weight: 400;
    color: #3E3E3E;
    text-align: left;
    line-height: 1.7;
    cursor: pointer;
    margin-left: 1rem;
    text-decoration:underline;
    @media(min-width:360px){
      font-size:16px;
      margin-left:2rem;
    }
  }
  .product {
    &__list {
      @media(min-width:375px){
        margin:0 -10px;
        width:calc(100% + 20px)!important;
      }
    }
    &__item {
      margin:0 0 20px;
      width:100%;
      cursor:pointer;
      justify-content:flex-start;
      @media(min-width:375px){
        margin:0 10px 20px;
        width:calc((100% / 2) - 20px);
        height:27rem;
      }
      @media(min-width:768px){
        width:calc((100% / 3) - 20px);
        height:25rem;
      }
      @media(min-width:1024px){
        width:calc((100% / 4) - 20px);
      }
      @media(min-width:1200px){
        width:calc((100% / 5) - 20px);
      }
      .cat-list & {
        @media(min-width:768px){
          height:25rem;
        }
        @media(min-width:1200px){
          width:calc((100% / 4) - 20px);
        }
      }
    }
    &__title {
      height:5rem;
      @media(min-width:375px){
        height:7rem;
      }
      @media(min-width:768px){
        height:5rem;
      }
    }
    &__image {
      height:150px;
      @media(min-width:375px){
        height:130px;
      }
    }
  }
  .upload-pront-text {
    padding-left:15px;
  }
  .substitute-area {
    flex-direction:column;
    justify-content:space-between;
    align-items:flex-start;
    width:100%;
    margin-top: 1.2rem;
    @media(min-width:500px){
      margin-top:0;
      flex-direction:row;
      align-items:center;
    }
    &__box {
      flex-direction:row;
      justify-content:space-between;
      width:100%;
      padding:1rem;
      margin-bottom: 0.4rem;
      margin-top: 1.2rem;
      box-shadow:0px 0px 3px rgba(0, 0, 0, 0.25);
      @media(min-width:500px){
        width:50%;
      }
    }
  }
  .product {
    &-image {
      display:block;
      width:100%;
      @media(min-width:456px){
        width:auto;
        height:380px;
      }
    }
    &-details {
      flex-direction:column;
      justify-content:space-between;
      width:100%;
      margin-top:1rem;
      margin-bottom:1rem;
      @media(min-width:500px){
        flex-direction:row;
      }
      &__item {
        margin-top:2rem;
        @media(min-width:500px){
          margin-top:0;
        }
        &:first-child {
          margin-top:0;
        }
      }
    }
  }
  .price-block {
    flex-direction:column;
    width:100%;
    margin-top:1rem;
    margin-bottom:1rem;
    @media(min-width:500px){
      flex-direction:row;
    }
  }
  .order-mob {
    @media(min-width:960px){
      display:none;
    }
  }
  .order-desk {
    display:none;
    @media(min-width:960px){
      display:block;
    }
  }
  .hide {
    display: none;
  }

  #checkout-content {
    @media screen and (max-width:700px){
      padding-bottom: 4rem;
    }
  }

  #finalizar_pedido {
    @media screen and (max-width:700px){
      width: 100%;
   
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 2;
  
      background: #ffffff;
  
      padding: 8px 16px;
    }
  }

  .product-details-content {
    @media screen and (max-width: 600px) {
      padding-bottom: 10rem;
    }
  }

  .mobile-bottom-space-padding-safe {
    padding-bottom: 40px !important;
  }

  .mobile-bottom-space-margin-safe {
    margin-bottom: 40px !important;
  }

  .mobile-status-bar-padding-safe {
    padding-top: 40px !important;
  }

  .mobile-status-bar-margin-safe {
    margin-top: 40px !important;
  }

  .signin-back-link {
    position: absolute;
    z-index: 50;
    top: 30px;
  }

  .button-reset {
    border: 0;
    background: transparent;
  }

  .offer_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 18rem));
    grid-gap: 1rem;
    
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(auto-fit, minmax(11rem, auto));
      grid-gap: 0.5rem;
    }
  }

  .offer {
    &__item {
      height:24rem;
      
      margin-right:1rem;
      margin-bottom:1rem;
      
      cursor:pointer;
      
      justify-content:flex-start;

      @media(min-width:1300px){
        margin-right:43px;
      }

      @media screen and (max-width:600px){
        margin-right: auto;
        margin-left: auto;
      }

    }
    &__link {
      display:block;
      text-align: center;
    }
    &__image {
      display:block;
      width:100%;
    }
  }

  .profile_menu_item {
    border-bottom: 1px solid #ECECEC;
  }

  .tabbar-safe {
    @media screen and (max-width:600px){
      margin-bottom: 5rem !important;
    }
  }

  .tabbar-safe-padding {
    @media screen and (max-width:600px){
      margin-bottom: 5rem !important;
    }
  }

  #header {
    @media screen and (max-width: 600px) {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    }
  }

  .create-credcard-container {
    @media screen and (max-width: 600px) {
      padding: 1rem;
    }

    & > form > div {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .create-address-container {
    @media screen and (max-width: 600px) {
      padding: 1rem;

      & > form > div {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }

  }

  .create-address-select {
    @media screen and (max-width: 600px) {
      width: 100% !important;
    }
  }

  .float-goback {
    position: absolute;
    left: 1rem;
  }

  .pointer {
    cursor: pointer;
  }
`

export const defaultTheme = {
  fontSize: {
    small: 14,
    medium: 16,
    large: 18,
    big: 24
  },
  color: {
    primary: '#FF0037',
    secondary: '#EDEDED',
    thirdy: '#3E3E3E',
    gray: '##a6a5a5',
    grayOpacity: '#C4C4C4'
  }
}

export const iconSize = {
  fontSize: '1.7rem',
  color: '#3e3e3e'
}

const baseColors = {
  black: '#000',
  white: '#fff',
  lightgray: '#f5f5f5',
  darkgray: '#777',
  red: ['#FF5A5F', '#ce291d', '#c0392b'],
  green: ['#00997F', '#2db300', '#1b984b'],
  yellow: ['#ffff66', '#e6b800'],
  blue: ['#00b8e6', '#008ae6'],
  neutrals: [
    '#EEEEEE',
    '#DBDBDB',
    '#B5B1B1',
    '#999999',
    '#636e72',
    '#484848',
    '#333333',
    '#2d3436'
  ],
  checkoutConfirmation: ['#f1f1f1', '#b1b1b1', '#fefefe', '#777']
}

export const colors = {
  primary: '#383838',
  secondary: '#1b984b',
  text: {
    light: '#636e72',
    main: '#333333',
    dark: '#2d3436',
    secondary: '#D1D1D1'
  },
  background: {
    grey: baseColors.lightgray
  },
  error: {
    main: baseColors.red[1]
  },
  header: {
    border: '#4a4a4a'
  },
  ...baseColors
}
