import { ButtonC, Loading } from './styles'

import { ButtonProps } from './types'

const Button = ({
  id = '',
  label = '',
  loading = false,
  background,
  color,
  onClick,
  width,
  disabled = false,
  leftIcon,
  rightIcon,
  borderColor,
  decoration,
  fontSize,
  className,
  ...props
}: ButtonProps) => (
  <ButtonC
    id={id}
    width={width}
    background={disabled ? '#C4C4C4' : background}
    color={disabled ? '#fff' : color}
    onClick={onClick}
    disabled={loading || disabled}
    borderColor={disabled ? 'none' : borderColor}
    decoration={decoration}
    fontSize={fontSize}
    className={className}
    {...props}
  >
    {loading ? (
      <Loading color={color} />
    ) : (
      <>
        {leftIcon && (
          <img src={leftIcon} height='16' style={{ marginRight: 16 }} />
        )}

        {label}

        {rightIcon && (
          <img src={rightIcon} height='16' style={{ marginLeft: 16 }} />
        )}
      </>
    )}
  </ButtonC>
)

export default Button
