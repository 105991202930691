
import axios from 'axios'
import { newAxiosInstance } from 'services/api'

export interface OrderProps {
  [x: string]: any
  rate: number
  created_at: number
  total: number
  index_admin: string
  state: State
  bypass_delivery: boolean
  delivery_fee: number
  state_history: StateHistory[]
  products: Product[]
  max_total: number
  customer: string
  pub_id: string
  obs: string
  delivery_address: DeliveryAddress
  quantity_of_integrated_stores_near: number | null
  quantity_of_nonintegrated_stores_near: number | null
  delivery_order_driver_id?: string
  delivery_order_id?: string
  driver_lat?: string
  driver_lng: string
  store: null | {
    name: string
    pub_id: string
  }
  delivery_average: { label: string, value: string } | null
}

export interface DeliveryAddress {
  pub_id: string
  country: string
  lng: string
  city: string
  more: string
  created_at: number
  active: boolean
  cep: string
  reference: string
  number: number
  default: boolean
  street: string
  nickname: string
  state: string
  neighborhood: string
  lat: string
  customer: string
}

export interface Product {
  substitutes: any
  required: boolean | null
  pub_id: string
  image: Image
  characteristics: Characteristics
  quantity: number
  keywords: string[]
  ordering: number
  active: boolean
  description: string
  sale_price: number
  tags: Tag[]
  ean: string
  price: number
  name: string
  max_price: number
  min_price: number
}

export interface Characteristics {
  mode: string
  presentation: string
  unit: string
  temperature: string
  ncm: string
  max_price_multiplier: number
  sku: string
  weighing: string
}

export interface Image {
  normal: string[]
  highlights: string
  lists: string
  thumbs: string
}

export interface Tag {
  index: number
  tag: string
}

export interface State {
  description: string
  id: number
}

export interface StateHistory {
  description: string
  created_at: number
  id: number
}

export const postCreateOrder = async (data: any) =>
  newAxiosInstance.post('/customers/orders', data)

export const generatePixCode = async (id: string) =>
  newAxiosInstance.get(`/customers/orders/generate-pix/${id}`)

export const postCreatePayment = async (data: any) =>
  newAxiosInstance.post('/customers/orders/payment_authorization', data)

export const getCupom = async (coupon: string) =>
  newAxiosInstance.get(`/customers/orders/validate-coupon/${String(coupon).toLowerCase()}`)

export const verifyWhiteList = async (cep: string) =>
  axios.get(`https://k6hw5kmu78.execute-api.us-east-1.amazonaws.com/dev/cep?cep=${cep.replace('-', '')}`)

export const getDeliveryFee = async (addressId: string) =>
  newAxiosInstance.get(`/customers/orders/delivery_fee/${addressId}`).then(({ data }) => data)

export interface MedicalPrescriptionProps {
  name: string
  phone: string
  cpf: string
  file: string | null
}

export const postMedicalPrescription = async (data: any) =>
  newAxiosInstance.post('/customers/prescriptions/upload', data)

export const postPrescriptionCheck = async (data: { file: string}) =>
  newAxiosInstance.post('/customers/prescriptions/validate', data)

export const getCancelOrder = async (orderId: string) =>
  newAxiosInstance.get(`/customers/orders/${orderId}/cancel`)

export const getUpdateOrder = async (orderId: string) =>
  newAxiosInstance.get(`/customers/orders/${orderId}/delivery-confirmation`)
