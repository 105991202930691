import { useState } from 'react'
import { useAlert } from 'react-alert'

import { Dispatch } from 'redux'
import { MedicalPrescriptionProps, postMedicalPrescription, postPrescriptionCheck } from 'services/order'
import { RootState } from 'store/reducers'
import { setScreenAction } from 'store/reducers/appReducer'
import { setMedicalPrescriptionAction } from 'store/reducers/cartReducer'

const usePrescription = () => {
  const alert = useAlert()
  const [enableForm, setEnableForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkPrescription, setCheckPrescription] = useState(false)
  const [enable, setEnable] = useState(false)

  const handleToggleForm = () => {
    setEnableForm(state => !state)
  }

  const handleCheckPrescripton = async (file: string) => {
    try {
      setLoading(true)
      setEnable(true)
      const payload = { file: file }

      await postPrescriptionCheck(payload)
      setCheckPrescription(true)
      alert.success('Prescrição verificada')
    } catch (error) {
      setCheckPrescription(false)
      alert.error('Prescrição inválida')
    } finally {
      setLoading(false)
    }
  }

  const handleMedicalPrescription = (productPayload: MedicalPrescriptionProps) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
      try {
        setLoading(true)
        const { similar } = getState()

        const dataForm = new FormData()
        if (productPayload.file) dataForm.append('file', productPayload.file[0])
        dataForm.append('cpf', productPayload.cpf)
        dataForm.append('name', productPayload.name)
        dataForm.append('phone', productPayload.phone)

        const { data } = await postMedicalPrescription(dataForm)

        dispatch(setMedicalPrescriptionAction(similar.idProduct, data.pub_id))
        dispatch(setScreenAction(''))
        alert.success('Prescrição Anexada')
      } catch (error) {
        try {
          const errorMessage = error?.response?.data?.message;
          alert.error(`Não foi possível anexar: ${errorMessage}`)
        } catch (error) {
          alert.error('Não foi possível anexar, verifique os dados e tente novamente.')
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return {
    enable,
    loading,
    checkPrescription,
    enableForm,
    handleToggleForm,
    handleCheckPrescripton,
    handleMedicalPrescription
  }
}

export default usePrescription
