import styled from 'styled-components'

export const Container = styled.div`
  border-bottom: 1px solid #ECECEC;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 1rem;
  max-width: 1366px !important;
  margin: 0 auto;
`
