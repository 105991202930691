import { useDispatch } from 'react-redux'

import { Button } from 'components/form'
import { setScreenAction } from 'store/reducers/appReducer'

const NoAddress = () => {
  const dispatch = useDispatch()

  const handleNotification = () => {
    dispatch(setScreenAction(''))
  }

  return (
    <div className='pt-6'>
      <p className='text-red-main text-2xl'>
        Ops! Parece que ainda não chegamos aí 🚚
      </p>
      <p className='pt-6'>
        Desculpe, seu endereço atual está fora do nosso raio de entrega. Estamos
        expandindo rapidamente e esperamos estar aí em breve.
      </p>
      <p className='pt-6'>
        Notificaremos assim que começarmos a entregar na sua região.
        Estamos ansiosos para atendê-lo!
      </p>
      <p className='pt-10'>
        <Button
          label='Entendi'
          background='#3097E2'
          onClick={handleNotification}
        />
      </p>
    </div>
  )
}
export default NoAddress
