import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #ECECEC;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: #fff;
`

export const Input = styled.input`
  font-size: 15px;
  padding: 1rem 0;
  color: #3E3E3E;
  width: 91%;
  border: none#3E3E3E;
  outline-width: 0;
`
