import styled from 'styled-components'

export const Container = styled.div<{selected: boolean}>`
  background: #FFFFFF;
  border: 2px solid  ${({ selected = false }) => selected ? '#3097E2' : '#EDEDED'};
  border-radius: 4px;
  padding: 16px 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  cursor: pointer;
`
