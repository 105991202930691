import { forwardRef } from 'react'

import GpsFixedIcon from '@material-ui/icons/GpsFixed'

import { Container, Text, View } from './styles'

const ButtonLocation = ({
  ...props
}, forwardedRef: React.Ref<HTMLDivElement>
) => {
  return (
    <Container ref={forwardedRef} {...props}>
      <View>
        <GpsFixedIcon style={{ color: '#fff' }} />
      </View>
      <Text>User minha localização</Text>
    </Container>
  )
}

export default forwardRef(ButtonLocation)
