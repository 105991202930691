import styled from 'styled-components'

export const Container = styled.div`
  border-top: 1px solid #ECECEC;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 1rem 1rem 1.6rem;
  z-index: 2;
  right: 0;
  > div {
    flex-direction:column;
  }

  > div > .fixed-button {
    width: 100%;
    
    @media screen and (max-width: 600px) {
      margin-top: 1rem;
    }
  }

  .fixed {
    &-price {
      @media(min-width:375px){
        width:70%;
      }
      @media(min-width:425px){
        width:calc(100% - 166px);
      }
    }
    &-button {
      @media(min-width:375px){
        width:30%;
      }
      @media(min-width:425px){
        width:45%;
        max-width:166px;
      }
      button {
        margin-top:10px;
        @media(min-width:375px){
          margin-top:0;
        }
      }
    }
  }
`
