import styled from 'styled-components'

const selectColor = (color: string) => {
  switch (color) {
    case 'success':
      return '#1aaf26'
    case 'error':
      return '#f44336'
    case 'info':
      return '#d5be19'
    default:
      return '#ccc'
  }
}

export const Container = styled.div<{type: string}>`
  background-color: ${({ type = 'error' }) => selectColor(type)};
  color: white;
  font-weight: bold;
  font-size: 22px;
  line-height: 20px;
  transition: 0.3s;
  min-height: 3.8rem;
  width: calc(100%);
`

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  height: 100%;
  
  @media screen and (min-width: 600px) {
    width: 600px;
  }

  @media screen and (min-width: 900px) {
    width: 900px;
  }

  @media screen and (min-width: 1200px) {
    width: 1200px;
  }
`

export const View = styled.div`
  cursor: pointer;
  padding: 1rem;
  font-size: 16px;
  margin-left: auto;
  display: 'online-block';
`

export const Text = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-left: 2rem;
`
