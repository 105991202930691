import Link from 'next/link'

import {
  Container,
  Text,
  Button,
  Linka
} from './styles'

interface CookieWarningProps {
  onClick?: () => void
}

function CookieWarning ({
  onClick
}: CookieWarningProps) {
  return (
    <Container className='tabbar-safe'>
      <Text>
        Utilizamos os cookies para personalizar ofertas e melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa <Link href='/politica'><Linka> Política de Privacidade</Linka></Link>
      </Text>
      <Button onClick={onClick}>
        Concordo e fechar
      </Button>
    </Container>
  )
};

export default CookieWarning
