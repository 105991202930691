import styled from 'styled-components'

interface BoxProps {
  backgroundColor?: string
  justify?: string
  direction?: string
  align?: string
  cursor?: string
  height?: string
  selected?: number
  display?: string
  width?: string
  mr?: number
  minHeight?: string
}

const Box = styled.div<BoxProps>`
  display: ${({ display = 'flex' }) => display};
  ${({ mr = 0 }) => mr && `margin-right: ${mr}rem`};
  width: ${({ width = 'auto' }) => width};
  min-height: ${({ minHeight = '14rem' }) => minHeight};
  ${({ height }) => height && `height: ${height};`}
  border: 1px solid ${({ selected = 0, theme: { color } }) => selected ? color.primary : color.secondary};
  border-radius: 8px;
  padding: 1rem;
  flex-direction: ${({ direction = 'column' }) => direction};
  justify-content: ${({ justify = 'flex-end' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  background-color: ${({ backgroundColor = '#fff' }) => backgroundColor};
  cursor: ${({ cursor = 'auto' }) => cursor};
`

export default Box
