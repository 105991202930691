import { useMemo, useState } from 'react'

import 'moment/locale/pt-br'

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-datepicker/dist/react-datepicker.css'
import { batch, useDispatch, useSelector } from 'react-redux'

import DatePicker from '@trendmicro/react-datepicker'
import { Button, SelectShadow } from 'components/form'
import { Text, View } from 'components/helpers'

// import { addScheduleAction } from 'store/reducers/cartReducer'

import { addHours, addMinutes, eachMinuteOfInterval, format, getHours, isToday, isValid, roundToNearestMinutes, set, setHours, startOfDay, startOfToday, subHours, addDays } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import moment from 'moment'
import { RootState } from 'store/reducers'
import { disableLocationAction, setScreenAction } from 'store/reducers/appReducer'
import { addScheduleAction } from 'store/reducers/cartReducer'

import { Container } from './styles'
const INTERVAL = 1
const DEFAULT_AVERAGE_DELIVERY_TIME = 120

function Scheduling () {
  const {
    schedule,
    scheduleDateTime,
    operating_hours_open,
    operating_hours_close,
    operating_hours_delay,
    average_delivery_time
  } = useSelector((state: RootState) => ({
    scheduleDateTime: state.cart?.scheduleTo ? new Date(state.cart?.scheduleTo) : addDays(new Date(), 1),
    operating_hours_open: state.user.setup?.admin?.operating_hours_open,
    operating_hours_close: state.user.setup?.admin?.operating_hours_close,
    operating_hours_delay: state.user.setup?.admin?.operating_hours_delay,
    average_delivery_time: state.user.setup?.admin?.average_delivery_time,
    schedule: state.cart.scheduleTo
  }))

  const dispatch = useDispatch()

  const [now, setNow] = useState(scheduleDateTime)

  const minimumScheduleDateTime = useMemo(() => {
    const store_close = set(now, {
      hours: operating_hours_close,
      minutes: 0,
      seconds: 0
    })

    const nowRounded = roundToNearestMinutes(addHours(now, INTERVAL), {
      nearestTo: INTERVAL
    })
    const closeWithoutDate = subHours(store_close, operating_hours_delay)

    const nextDay = startOfDay(addDays(now, 1))
    const nextDayOnOpenStore = set(nextDay, {
      hours: operating_hours_open,
      minutes: 0,
      seconds: 0
    })

    return nowRounded >= closeWithoutDate ? nextDayOnOpenStore : nowRounded
  }, [now, operating_hours_open, operating_hours_delay, operating_hours_close])

  const [scheduleTo, setScheduleTo] = useState(
    scheduleDateTime || minimumScheduleDateTime
  )

  const availableHoursToScheduleOrder = useMemo(() => {
    if (!isValid(scheduleTo) || !isValid(minimumScheduleDateTime)) return []

    const rangeOfHours = eachMinuteOfInterval(
      {
        start: isToday(scheduleTo)
          ? minimumScheduleDateTime
          : set(scheduleTo, {
            hours: operating_hours_open,
            minutes: 0,
            seconds: 0
          }),
        end: setHours(
          startOfDay(scheduleTo),
          operating_hours_close - operating_hours_delay
        )
      },
      {
        step: 60
      }
    )
    return rangeOfHours.map((item) => ({
      hour: item,
      exibition: `${format(item, 'HH')}h até ${format(
        addMinutes(
          item,
          average_delivery_time || DEFAULT_AVERAGE_DELIVERY_TIME
        ),
        'HH'
      )}h`,
      weekname: format(item, 'EEEE', {
        locale: ptBR
      })
    }))
  }, [
    scheduleTo,
    minimumScheduleDateTime,
    operating_hours_open,
    operating_hours_close,
    operating_hours_delay,
    average_delivery_time
  ])

  const [hour] = useState(
    getHours(scheduleDateTime) === 0 ? availableHoursToScheduleOrder[0].hour : scheduleTo
  )
  const [hourSelected, setHourSelected] = useState(() => {
    return schedule ? new Date(schedule) : hour
  })

  const handleChangeHour = (e: any) =>
    setHourSelected(new Date(e.target.value))

  const handleSubmitScheduling = () => {
    const date = set(scheduleTo, {
      hours: getHours(hourSelected),
      minutes: 0,
      seconds: 0
    })

    batch(() => {
      dispatch(disableLocationAction())
      dispatch(addScheduleAction(date))
      dispatch(setScreenAction(''))
    })
  }

  const handleCancel = () =>
    dispatch(setScreenAction(''))

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <Text
        size={22}
        fontWeight={500}
        textAlign='center'
        color='#3097E2'
      >
        Agendamento do pedido
      </Text>

      <View mb={2} mt={0.6}>
        <Text size={13}>Defina o dia e a hora que você quer receber o seu pedido</Text>
      </View>

      <View align='center'>
        <DatePicker
          locale='pt-br'
          date={now}
          minDate={addDays(new Date(), 1)}
          onSelect={(date: any) => {
            setNow(moment(date).toDate())
            setScheduleTo(moment(date).toDate())
          }}
        />
      </View>

      <View mt={1} align='center'>
        <SelectShadow
          onChange={handleChangeHour}
        >
          {availableHoursToScheduleOrder.map((item, index) => (
            <option key={index} selected={hourSelected.toString() === item.hour.toString()} value={item.hour.toString()}>{item.exibition}</option>
          ))}
        </SelectShadow>
      </View>

      <View
        mt={1}
        style={{
          background: '#F2F2F2',
          padding: '1rem'
        }}
      >
        <Text textAlign='center' size={13} fontWeight={400}>Confirmar para</Text>
        <View mt={0.6}>
          <Text
            size={16}
            fontWeight={700}
            color='#00997F'
            textAlign='center'
          >
            Agendado dia {format(scheduleTo, 'dd/MM')} às {format(hourSelected, 'HH')}h
          </Text>
        </View>
      </View>

      <View mt={1}>
        <Button
          label='Confirmar agendamento'
          onClick={handleSubmitScheduling}
          background='#3097E2'
          color='#fff'
        />
        <View mt={1}>
          <Button
            label='Cancelar'
            background='#fff'
            color='#3097E2'
            borderColor='#3097E2'
            onClick={handleCancel}
          />
        </View>
      </View>
    </Container>
  )
}

export default Scheduling
