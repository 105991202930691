import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.color.secondary};
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
  height: 2.2rem;
  background-color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
`

export const Text = styled.p`
  font-size: 15px;
  font-weight: 100;
  padding: 0 0.6rem;
  color: ${({ theme }) => theme.color.secondary};
`

export const Button = styled.div`
  color: #FF0037;
  font-weight: 600;
  font-size: 22px;
  padding: 0 0.3rem;
  cursor: pointer;
  
`
