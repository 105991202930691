import { AnyAction } from 'redux'

const ENABLE_LOCATION = 'appReducer/ENABLE_LOCATION'
const DISABLE_LOCATION = 'appReducer/DISABLE_LOCATION'
const ENABLE_CART = 'appReducer/ENABLE_CART'
const DISABLE_CART = 'appReducer/DISABLE_CART'
const DISABLE_MENU = 'appReducer/DISABLE_MENU'
const ENABLE_MENU = 'appReducer/ENABLE_MENU'
const SET_SCREEN = 'appReducer/SET_SCREEN'
const RESET_APP = 'appReducer/RESET_APP'

const DISABLE_LIST_MENU = 'appReducer/DISABLE_LIST_MENU'
const ENABLE_LIST_MENU = 'appReducer/ENABLE_LIST_MENU'

const ENABLE_STREET_MENU = 'appReducer/ENABLE_STREET_MENU'
const DISABLE_STREET_MENU = 'appReducer/DISABLE_STREET_MENU'

const ENABLE_LOADING = 'appReducer/ENABLE_LOADING'
const DISABLE_LOADING = 'appReducer/DISABLE_LOADING'

export interface StateAppProps {
  locationToggle: boolean
  cartToggle: boolean
  menuToggle: boolean
  menuListToggle: boolean
  screenRegister: string
  menuStreetToggle: boolean
  loading: boolean
  tempPassword: string
}

const stateInit = {
  locationToggle: false,
  cartToggle: false,
  menuToggle: false,
  menuListToggle: false,
  screenRegister: '',
  menuStreetToggle: false,
  loading: false,
  tempPassword: ''
}

export const appReducer = (state: StateAppProps = stateInit, { type, payload }: AnyAction) => {
  switch (type) {
    case RESET_APP:
      return stateInit
    case ENABLE_LOADING:
      return { ...state, loading: true }
    case DISABLE_LOADING:
      return { ...state, loading: false }
    case DISABLE_LIST_MENU:
      return { ...state, menuListToggle: false }
    case ENABLE_LIST_MENU:
      return { ...state, menuListToggle: true }
    case ENABLE_LOCATION:
      return { ...state, locationToggle: true }
    case DISABLE_LOCATION:
      return { ...state, locationToggle: false }
    case ENABLE_CART:
      return { ...state, cartToggle: true }
    case DISABLE_CART:
      return { ...state, cartToggle: false }
    case SET_SCREEN:
      return { ...state, screenRegister: payload }
    case DISABLE_MENU:
      return { ...state, menuToggle: false }
    case ENABLE_MENU:
      return { ...state, menuToggle: true }
    case ENABLE_STREET_MENU:
      return { ...state, menuStreetToggle: true }
    case DISABLE_STREET_MENU:
      return { ...state, menuStreetToggle: false }
    default:
      return state
  }
}

export const resetAppAction = () => ({ type: RESET_APP })

export const enableLoadingAction = () => ({ type: ENABLE_LOADING })
export const disableLoadingAction = () => ({ type: DISABLE_LOADING })

export const enableStreetAction = () => ({ type: ENABLE_STREET_MENU })
export const disableStreetAction = () => ({ type: DISABLE_STREET_MENU })

export const enableMenuListAction = () => ({ type: ENABLE_LIST_MENU })
export const disableMenuListAction = () => ({ type: DISABLE_LIST_MENU })
export const enableLocationAction = () => ({ type: ENABLE_LOCATION })
export const disableLocationAction = () => ({ type: DISABLE_LOCATION })
export const enableCartAction = () => ({ type: ENABLE_CART })
export const disableCartAction = () => ({ type: DISABLE_CART })
export const enableMenuAction = () => ({ type: ENABLE_MENU })
export const disableMenuAction = () => ({ type: DISABLE_MENU })
export const setScreenAction = (payload: string) => ({ type: SET_SCREEN, payload })
