import { forwardRef } from 'react'

import { Container, CheckboxInput } from './styles'

interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  background?: string
  borderWidthCheck?: string
  rotate?: number
}

function CheckBox (
  {
    onClick,
    background,
    rotate,
    borderWidthCheck,
    disabled = false,
    ...rest
  }: CheckBoxProps,
  forwardedRef: React.Ref<HTMLInputElement>
) {
  return (
    <Container
      rotate={rotate}
      borderWidthCheck={borderWidthCheck}
    >
      <CheckboxInput
        {...rest}
        background={disabled ? '#C4C4C4' : background}
        type='checkbox'
        ref={forwardedRef}
        readOnly={disabled}
        disabled={disabled}
      />
      <span className='checkmark' onClick={disabled ? undefined : onClick} />
    </Container>
  )
}

export default forwardRef(CheckBox)
