interface IconProps {
  name: string
  color?: string
  size?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg' | 'xxlg' | 'xxxlg'
}

const Icon = ({ size = 'md', name, color = 'black' }: IconProps) => {
  return <i className={`icon icon-${size} icon-${name} icon-${color}`} />
}

export default Icon
