import { AnyAction } from 'redux'

import { CardProductProps } from './cartReducer'

const CLEAN = 'optionProductReducer/CLEAN'
const ADD_PRODUCT = 'optionProductReducer/ADD_PRODUCT'

export interface StateOptionProductProps {
  product: null | CardProductProps
}

const stateInit = {
  product: null
}

export const optionProductReducer = (state: StateOptionProductProps = stateInit, { type, payload }: AnyAction) => {
  switch (type) {
    case ADD_PRODUCT:
      return {
        ...state,
        product: payload
      }
    case CLEAN:
      return stateInit
    default:
      return state
  }
}

export const addOptionProductAction = (payload: string) =>
  ({ type: ADD_PRODUCT, payload })

export const cleanOptionProductAction = () =>
  ({ type: CLEAN })
