import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border: 1px solid #ECECEC;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  padding-left: .6rem;
`

export const Input = styled.input`
  width: 90%;
  border: none;
  outline: none;
  font-size: 15px;
`

export const View = styled.div`
  margin-right: 1rem;
`

export const Close = styled.div`
  margin-right: 1rem;
`
