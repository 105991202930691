import { GetServerSideProps } from 'next'

import {
  addMinutes,
  set,
  isValid,
  toDate
} from 'date-fns'
import queryString from 'query-string'
import { newAxiosInstance, refreshToken } from 'services/api'
// import { setToken } from 'services/AuthService'
import { setSetupAction } from 'store/reducers/userReducer'

import { setToken } from './AuthService'

// import { clearToken, getToken } from './AuthService'

export interface HomeCategoryProps {
  url: string
  name: string
  description: string
  image: string | null
  enabled: boolean
  parent_categories: string[]
  id: number
  subcategories: string[]
}

interface GetProductOffersByLatLngParams {
  lat: number
  lng: number
  range: number
}

const ISSERVER = typeof window === 'undefined'

export const getSetupSession = () => {
  if (!ISSERVER) {
    const data = sessionStorage.getItem('setup')
    if (!data) return false
    return JSON.parse(data)
  }
}

export const getProductCategoryByName = async (url: string) => {
  const { data } = await newAxiosInstance.get(url)
  return data
}

export const getProductOffersByAddressId = async (address: string, range: number) =>
  newAxiosInstance.post<any, any>('/products/offers/by-address', { address, range })

export const getProductOffersByLatLng = async ({ lat, lng, range }: GetProductOffersByLatLngParams) =>
  newAxiosInstance.post<any, any>('/products/offers/by-coords', { lat, lng, range })

export const getProductCategory = async (location: any = {}) =>
  newAxiosInstance.get(`/products/category?${queryString.stringify(location)}`)

export const getProductCategoryByQuery = async (category: string = '', limit = 5, location: any = {}) => {
  return newAxiosInstance.get(`/products/category/productMain?q=${category}&limit=${limit}&${queryString.stringify(location)}`)
}

export const getSimilarProduct = async (idProduct: string, limit = 100, lat?: number, lng?: number) =>
  newAxiosInstance.get(`/products/similar/${idProduct}`, {
    params: {
      limit,
      lat,
      lng
    }
  })

export const setSetupSession = (data: any) =>
  sessionStorage.setItem('setup', data)

export const getSetupApp = async () =>
  newAxiosInstance.get('/setup')

export const getSearchSuggest = async (q: string, lat: number, lng: number) =>
  newAxiosInstance.get(`/products/suggest/${q}?lat=${lat}&lng=${lng}`)

export const getSearchProducts = async (params: any) =>
  newAxiosInstance.get(`/products/search?${queryString.stringify(params)}`
  )

export const getSearchProductsFallBack = async (params: any) =>
  newAxiosInstance.get(`/products/fallbackSearch?${queryString.stringify(params)}`
  )

export const getProductById = async (slug: string, location: any = {}) =>
  newAxiosInstance.get(`/products/${slug}?${queryString.stringify(location)}`)

export const getOfferById = async (slug: string) =>
  newAxiosInstance.get(`/products/offers/${slug}`)

export const extraDelivery = (products: any[] = []): number => {
  const { prices } = getSetupSession()
  const result = products.some(item => getIfNeedPrescription(item.prescription))
  return result ? Number(prices.extraDelivery) : 0
}

export const getIfNeedPrescription = (prescription: string) => {
  let feedback = false
  const { prescriptionTypes } = getSetupSession()
  if (prescription && prescriptionTypes) {
    prescriptionTypes.forEach((type: string) => {
      if (prescription.indexOf(type) > -1) {
        feedback = true
      }
    })
  }
  return feedback
}

export const isntSpecialOrder = ({ place, target, products }: any) => {
  let check = false
  for (let j = 0; j < products.length; j++) {
    const element = products[j][place]
    if (element) {
      if (element.indexOf(target) === -1) {
        check = true
      }
    }
  }
  return check
}

export const getDescriptionIfHas = (value: number, product: any) => {
  const { prices } = getSetupSession()
  const isnt_special_product = isntSpecialOrder({
    place: 'scientific_name',
    target: 'produto-doacao',
    products: [product]
  })
  const feedback0 = 'Valor 100% destinado à campanha.'
  if (!isnt_special_product) {
    return feedback0
  }
  const feedback1 =
      'Preço médio calculado entre as principais lojas.\nO valor da Nota Fiscal pode variar em até 8%'
  const feedback2 =
      'Nossos sistemas procuram a disponibilidade deste produto nas lojas parceiras mais próximas, mas infelizmente nenhum foi encontrado.'
  if (value >= prices?.minimum) {
    return feedback1
  }
  return feedback2
}

export const getTokenAndRefresh = async (store: any) => {
  try {
    const resSetup = await getSetupApp()
    store.dispatch(setSetupAction(resSetup.data))

    const { idToken } = await refreshToken()
    const valueToken = `Bearer ${idToken}`
    setToken(valueToken)
  } catch (error) {
    // clearToken()
  }
}

export interface ProductDetailProps {
  product: any
  ean: string
  active: boolean
  sale_price: number
  ordering: number
  image: Image
  keywords: string[]
  characteristic: Characteristics
  price: number
  description: string
  pub_id: string
  name: string
  tags: Tag[]
  img: string
  max_price: number
  min_price: number
  required?: boolean | null
  substitutes?: string[]
  available_quantity?: number
  is_available?: boolean
}

interface Characteristics {
  active_principle: any[]
  active_principle_name: string[]
  brand: string
  bula_link: string
  code: string
  manufacturer: string
  medication_type: string
  ms_registry: string
  prescription: boolean
  quantity: string
  url_product: string
  warning: string
  weight: string
}

interface Image {
  normal: string[]
  highlights: string
  lists: string
  thumbs: string
}

interface Tag {
  index: number
  tag: string
}

export const getProductPageById: GetServerSideProps = async (context) => {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )

  const params = context.params
  const { slug } = Array.isArray(params) ? params[0] : params

  try {
    const { data } = await getProductById(slug)
    return { props: { data } }
  } catch (error) {
    return { notFound: true }
  }
}

export const eachMinuteOfInterval = (interval: any, options: any) => {
  if (options?.step && options?.step < 1) {
    throw new Error(
      `(EachMinuteOfInterval) The step should be 1 or more. Params: ${interval.start}, ${interval.end}, ${options?.step}`
    )
  }

  const { start, end } = interval
  if (!isValid(start) || !isValid(end)) {
    throw new Error(
      `(EachMinuteOfInterval) The start and end should be end date. Params: ${interval.start}, ${interval.end}, ${options?.step}`
    )
  }

  // if (isAfter(toDate(start), toDate(end))) {
  //   throw new Error(
  //     `(EachMinuteOfInterval) The start date should be before end date. Params: ${interval.start}, ${interval.end}, ${options?.step}`
  //   )
  // }

  const startTime = toDate(
    set(start, { seconds: 0, milliseconds: 0 })
  ).getTime()
  const endTime = toDate(set(end, { seconds: 0, milliseconds: 0 })).getTime()

  const range = []

  let current: any = startTime

  while (current <= endTime) {
    range.push(current)
    current = addMinutes(current, options?.step || 1)
  }

  return range
}

interface CheckCartProductsParams {
  lat: number | string
  lng: number | string
  products: any[]
}

export const checkCartProducts = async (params: CheckCartProductsParams) => {
  return newAxiosInstance.post('/products/check-cart-products', params).then(({ data }) => data)
}

export const getCategoryProducts = async (params: any) => {
  return newAxiosInstance.get('/products/category/search', {
    params: params
  })
}
