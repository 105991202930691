
import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #ededed;
  width: 100%;
  border-radius: 8px;
  padding: 0.6rem;
`

export const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
  color: #3E3E3E;
  font-size: 15px;
  ::placeholder {
    color: ${({ theme: { color } }) => color.grayOpacity};
  }
`

export const Text = styled.p`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.32px;
  text-align: left;
  color: #da1e28;
  margin: 6px 0 ;
`
