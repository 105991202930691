import { forwardRef } from 'react'

import SearchComponet from '@material-ui/icons/Search'

import { Container, Input } from './styles'
import { iconSize } from 'styles'

interface SearchProps extends Record<string, any> {
  handleIconPress: () => void
}

const Search = ({
  handleIconPress,
  ...props
}: SearchProps, forwardedRef: React.Ref<HTMLInputElement>
) => {
  return (
    <Container>
      <Input ref={forwardedRef} {...props} />
      <SearchComponet style={{ ...iconSize, cursor: 'pointer' }} onClick={handleIconPress} />
    </Container>
  )
}

export default forwardRef(Search)
