import { Visible } from 'react-grid-system'
import { useDispatch, useSelector } from 'react-redux'

import Image from 'next/image'
import Router from 'next/router'

import { KeyboardBackspace } from '@material-ui/icons'
import { Column, Text, View } from 'components/helpers'
import { useAuth } from 'hooks/useAuth'
import { isLogged } from 'services/AuthService'
import { RootState } from 'store/reducers'
import { disableMenuAction } from 'store/reducers/appReducer'
import { getMobileClassName } from 'utils/helper/className'

import { Container, Content, Item, Box, BoxVersion, CloseButton } from './styles'

import packageJson from '../../../package.json'
import { MenuSideProps } from './types'

const MenuSide: React.FC<MenuSideProps> = ({
  menuToogle = false
}) => {
  const pages = useSelector((state: RootState) => state.user.setup.customer?.static_pages ?? [])

  const dispatch = useDispatch()
  const { signOut } = useAuth()

  const onPressClose = () =>
    dispatch(disableMenuAction())

  const onPressLogin = () => {
    Router.push('/signin')
  }

  const onPressRegister = () => {
    Router.push('/signup')
  }

  const onPressData = () => {
    dispatch(disableMenuAction())
    Router.push('/perfil')
  }

  const onPressSolicitation = () => {
    dispatch(disableMenuAction())
    Router.push('/pedidos')
  }

  const onPressCredCard = () => {
    dispatch(disableMenuAction())
    Router.push('/cartoes')
  }

  const onPressAddress = () => {
    dispatch(disableMenuAction())
    Router.push('/enderecos')
  }

  const onPressLogout = () =>
    signOut()

  const onPressParams = (url: string) => {
    return () => {
      dispatch(disableMenuAction())
      Router.push(url)
    }
  }

  return (
    <>
      <Container toggle={menuToogle ? 1 : 0} onClick={onPressClose} />
      <Content className={getMobileClassName()} toggle={menuToogle ? 1 : 0}>
        <Box style={{ position: 'relative' }}>
          <Visible xs>
            <CloseButton onClick={onPressClose}>
              <KeyboardBackspace />
            </CloseButton>
          </Visible>

          <Image
            src='/images/logo.svg'
            width='150'
            height='58'
            alt='Logo Farmazon'
          />
          <View mt={0.6}>
            <Text>Viva a experiência Farmazon</Text>
          </View>
        </Box>
        {isLogged() ? (
          <>
            <Item onClick={onPressData} borderTop={1}>Meus dados</Item>
            <Item onClick={onPressSolicitation} borderTop={1}>Meus pedidos</Item>
            <Item onClick={onPressCredCard}>Meus cartões</Item>
            <Item onClick={onPressAddress}>Meus endereços</Item>
            {/* <Item onClick={onPressFavoritos}>Favoritos</Item> */}
          </>
        ) : (
          <>
            <Item borderTop={1} onClick={onPressLogin}>Entrar</Item>
            <Item onClick={onPressRegister}>Cadastrar</Item>
          </>
        )}
        <Column>
          {pages.map((item: any, index: number) => (
            <Item key={index} onClick={onPressParams(`/sobre/${item.page_name}`)}>{item.page_name}</Item>
          ))}
        </Column>

        {isLogged() && <Item onClick={onPressLogout}>Sair</Item>}
        <BoxVersion>
          Versão: {packageJson.version}
        </BoxVersion>
      </Content>
    </>
  )
}

export default MenuSide
