import styled from 'styled-components'

export const Container = styled.div<{toggle: number}>`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  width: ${({ toggle = 0 }) => toggle ? '100%' : '0'};
`

export const Content = styled.div<{toggle: number}>`
  height: 100%;
  background-color: #fff;
  width: ${({ toggle = 0 }) => toggle ? '72%' : '0'};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.3s;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    width: ${({ toggle = 0 }) => toggle ? '100%' : '0'};
  }
`

export const CloseButton = styled.div`
  position: absolute;
  z-index: 100;
  left: 1rem;
  top: 50%;
  transform: translate(0px, -50%);
`

export const Item = styled.a<{borderTop?: number}>`
  padding: 1rem ;
  border-bottom: 1px solid #ECECEC;
  margin-top: 1rem;
  ${({ borderTop = 0 }) => borderTop && 'border-top: 1px solid #ECECEC'};
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
`

export const Box = styled.div`
  padding: 1rem;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BoxVersion = styled.p`
  margin-top: auto;
  padding: 1rem;
  text-align: center;
  font-size: 12px;
  margin-bottom: 1rem;
`
