import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: fit-content;
`

export const Text = styled.p`
`

export const Content = styled.div`
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: 40px;
  left: 0;
  min-width: 17rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  border: 1px solid #ECECEC;
`

export const Item = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
`

export const Box = styled.div`
  border: 1px solid ${({ theme: { color } }) => color.secondary};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
`
