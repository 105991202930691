import { AnyAction } from 'redux'

const ADD_PRESCRIPTION = 'prescriptionReducer/ADD_LIST'
const CLEAN = 'prescriptionReducer/CLEAN'

interface ProductPrescriptionProps {
  idProduct: string
  isValid: boolean
}

export interface StatePrescriptionrProps {
  prescriptions: ProductPrescriptionProps[]
}

const stateInit = {
  prescriptions: []
}

export const prescriptionReducer = (state: StatePrescriptionrProps = stateInit, { type, payload }: AnyAction) => {
  switch (type) {
    case ADD_PRESCRIPTION:
      return {
        ...state,
        prescriptions: [...state.prescriptions, payload]
      }
    case CLEAN:
      return stateInit
    default:
      return state
  }
}

export const addProductPrescriptionAction = (payload: ProductPrescriptionProps) =>
  ({ type: ADD_PRESCRIPTION, payload })

export const resetProductPrescriptionState = () =>
  ({ type: CLEAN })
