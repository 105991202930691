/* eslint-disable @typescript-eslint/restrict-plus-operands */
import axios from 'axios'
import { axiosCEP, axiosGoogle } from 'services/api'

export const getAddressByCep = async (cep: string) =>
  axiosCEP.get(`/ws/${cep}/json/`)

export const getGoogleMapsAddress = async (response: any) => {
  let str_param_address = response.logradouro + '+'
  str_param_address += response.bairro + ',+'
  str_param_address += response.localidade + ',+'
  str_param_address += response.uf
  str_param_address = str_param_address.replace(' ', '+')
  const str_param_address_replaced = str_param_address.split(' ').join('+')
  return axiosGoogle.get(`/geocoding/v5/mapbox.places/${str_param_address_replaced}.json?limit=1&fuzzyMatch=true&language=pt-BR&types=place&access_token=${process.env.APP_MAPBOX}`)
}

export const getAddressByText = async (text: string) =>
  axiosGoogle.get(`/geocoding/v5/mapbox.places/${text}.json?limit=5&fuzzyMatch=true&language=pt-BR&types=place&country=BR&access_token=${process.env.APP_MAPBOX}`)

export const getByLatLng = async (lat: number, lng: number) =>
  axiosGoogle.get(`/geocoding/v5/mapbox.places/${lng},${lat}.json?language=pt-BR&types=address&access_token=${process.env.APP_MAPBOX}`)

export const getAllState = async () =>
  axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')

export const getAllCityByState = async (state: string) =>
  axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`)
