import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div)`
  padding-top: 1rem;
  @media screen and (min-width: 600px) {
    width: 33rem;
  }
`

export const Scroll = styled.div<{desktopHeight?: string, mobileHeight?: string}>`
  // height: calc(100vh - 370px);
  height: ${({ mobileHeight }) => mobileHeight || 'inherit'} ;
  min-height: 150px;
  overflow: scroll;
  margin-top: 1rem;
  position: relative;
  @media screen and (min-width: 600px) {
    height: ${({ desktopHeight }) => desktopHeight || 'inherit'} ;
  }
`
