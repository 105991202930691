
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Radio } from '@material-ui/core'
import { ButtonOption, LoadingSimple } from 'components/elements'
import { Button } from 'components/form'
import { Text, View } from 'components/helpers'
import { useProduct } from 'hooks/useProduct'
import { getSimilarProduct } from 'services/product'
import { RootState } from 'store/reducers'
import { eventGA } from 'utils/gtag'
import { getUserLocalLocation } from 'utils/helper/location'

export default function OptionsModal () {
  const { optionProduct, cart, user, addressTemporary } = useSelector((state: RootState) => state)
  const { handleSubmitOption } = useProduct()
  const [loading, setLoading] = useState(true)
  const [similar, setSimiliar] = useState([])
  const { getEventName } = useProduct()

  const [option, setOption] = useState<{required: null|boolean, substitutes: any[]}>(() => {
    return {
      required: optionProduct.product?.required ?? null,
      substitutes: optionProduct.product?.substitutes ?? []
    }
  })

  useEffect(() => {
    const location = getUserLocalLocation(user, addressTemporary)
    getSimilarProduct(optionProduct?.product?.pub_id ?? '', undefined, location.lat, location.lng)
      .then(({ data }) => setSimiliar(data))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
      .finally(() => setLoading(false))
  }, [])

  const handleProductOption = (required: any, substitutes: any[]) => {
    return () => {
      eventGA(getEventName(required, substitutes))
      setOption({ required, substitutes })
    }
  }

  const handleSubmit = () => {
    handleSubmitOption(option, optionProduct.product, similar)
  }

  return (
    <View style={{ maxWidth: '370px' }}>
      <Text
        mt={2}
        size={22}
        fontWeight={500}
        textAlign='center'
        color='#EA2A2F'
      >
        Se a farmácia não tiver este produto
      </Text>

      <View mt={1}>

        {loading && (
          <View height='10rem' justify='center' align='center'>
            <LoadingSimple />
          </View>
        )}

        {!loading && (
          <>
            <ButtonOption
              selected={option.required === null && option.substitutes.length === 0}
              onClick={handleProductOption(null, [])}
            >
              <View cursor style={{ marginRight: '5px' }}>
                <Radio
                  checked={option.required === null && option.substitutes.length === 0}
                  style={{ color: '#474747', padding: 0 }}
                />
              </View>
              <Text size={14} fontWeight={option.required === null && option.substitutes.length === 0 ? 700 : 400} style={{ flexGrow: 0.7 }}>
                Ela pode escolher um similar
              </Text>
            </ButtonOption>

            {/* {similar.length > 0 && (
              <ButtonOption
                selected={option.required === null && option.substitutes.length > 0}
                onClick={handleProductOption(null, [1])}
              >
                <View cursor style={{ marginRight: '5px' }}>
                  <Radio
                    checked={option.required === null && option.substitutes.length > 0}
                    style={{ color: '#474747', padding: 0 }}
                  />
                </View>
                <Text size={14} fontWeight={option.required === null && option.substitutes.length > 0 ? 700 : 400} style={{ flexGrow: 0.7 }}>
                  Quero escolher um substituto
                </Text>
              </ButtonOption>
            )} */}

            {cart.items.length > 1 && (
              <ButtonOption
                selected={option.required === false}
                onClick={handleProductOption(false, [])}
              >
                <View cursor style={{ marginRight: '5px' }}>
                  <Radio
                    checked={option.required === false}
                    style={{ color: '#474747', padding: 0 }}
                  />
                </View>
                <Text size={14} fontWeight={option.required === false ? 700 : 400} style={{ flexGrow: 0.7 }}>
                  Tudo bem, não é obrigatório
                </Text>
              </ButtonOption>
            )}

            <ButtonOption
              selected={option.required === true}
              onClick={handleProductOption(true, [])}
            >
              <View cursor style={{ marginRight: '5px' }}>
                <Radio
                  checked={option.required === true}
                  style={{ color: '#474747', padding: 0 }}
                />
              </View>
              <Text size={14} fontWeight={option.required === true ? 700 : 400} style={{ flexGrow: 0.7 }}>
                Este produto é obrigatório
              </Text>
            </ButtonOption>
          </>
        )}

      </View>

      <View mt={2}>
        <Button
          onClick={handleSubmit}
          background='#3097E2'
          label='Escolher opção'
        />
      </View>
    </View>
  )
}
