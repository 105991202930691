import { forwardRef } from 'react'

import { Container, TextInput } from './styles'

function TextArea ({
  ...props
}: any, forwardedRef: React.Ref<HTMLTextAreaElement>) {
  return (
    <Container>
      <TextInput ref={forwardedRef} {...props} />
    </Container>
  )
};

export default forwardRef(TextArea)
