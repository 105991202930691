import styled from 'styled-components'

interface ContainerProps {
  selected?: boolean
  radiusRight?: boolean
  radiusLeft?: boolean
}

export const Container = styled.div<ContainerProps>`
  padding: 13px;
  background-color: ${({ selected = false }) => selected ? '#000000' : '#fff'};
  border-top-right-radius: ${({ radiusRight = false }) => radiusRight ? '4px' : '0px'};
  border-bottom-right-radius: ${({ radiusRight = false }) => radiusRight ? '4px' : '0px'};

  border-top-left-radius: ${({ radiusLeft = false }) => radiusLeft ? '4px' : '0px'};
  border-bottom-left-radius: ${({ radiusLeft = false }) => radiusLeft ? '4px' : '0px'};
  border: 1px solid #C4C4C4;
  width: 100%;
  cursor: pointer;

  display: flex;
  justify-content: center;

  p {
    color: ${({ selected = false }) => selected && '#fff'};
  }
`
