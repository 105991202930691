
import { useDispatch, useSelector } from 'react-redux'

import { Button, CheckBox } from 'components/form'
import { Table, Text, View } from 'components/helpers'
import { useProduct } from 'hooks/useProduct'
import { RootState } from 'store/reducers'
import { numberFormat } from 'utils/helper/currency'

export default function Similar () {
  const { cart, similar } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()

  const {
    handleAddSimilarProduct,
    handleAddSimilarsProduct
  } = useProduct()

  const handleSimilarAddCard = () =>
    dispatch(handleAddSimilarsProduct())

  const handleAddSimilar = (id: string) =>
    () => dispatch(handleAddSimilarProduct(id))

  return (
    <>
      <View style={{ width: '100%', maxWidth: '450px' }}>
        <View mb={2.2}>
          <Text size={18} fontWeight={500} textAlign='center'>Produtos substitutos</Text>
        </View>

        <View align='center' mb={1}>
          <Text style={{ width: '100%', maxWidth: '350px' }} size={14} textAlign='center'>
            {cart.product?.name}
          </Text>
        </View>

        <View style={{ minHeight: '19rem', maxHeight: '30rem', overflow: 'auto' }}>

          <View direction='row' justify='center' style={{ padding: '0.6rem 0 ', borderBottom: '4px solid #E4E4E4' }}>
            <Text size={14}>Selecione até 5 produtos substitutos</Text>
          </View>

          <table>
            <tbody>

              {similar.listSimilar.map((item, index: number) => {
                const isSelected = similar.substitutes?.includes(item.pub_id)

                return (
                  <tr key={index}>
                    <Table.Row>
                      <Text size={14} fontWeight='bold'>{item.name}</Text>
                    </Table.Row>
                    <Table.Row>
                      <Text size={13}><span style={{ color: '#27AE60' }}>{numberFormat(item.max_price)}</span></Text>
                      {/* <Text size={12}>Preço: <span style={{ color: '#27AE60' }}>{numberFormat(item.min_price)} - {numberFormat(item.max_price)}</span></Text> */}
                    </Table.Row>
                    <Table.Row>
                      <View align='flex-end'>
                        <CheckBox
                          onClick={handleAddSimilar(item.pub_id)}
                          background='#27AE60'
                          checked={isSelected}
                          disabled={similar.substitutes.length === 5 && !isSelected}
                        />
                      </View>
                    </Table.Row>
                  </tr>
                )
              })}
            </tbody>

          </table>
        </View>

      </View>

      <View style={{ marginTop: 'auto' }} pt={0.8}>
        <View mb={0.8}>
          <Button
            label='Incluir produtos substitutos'
            disabled={similar.substitutes?.length === 0}
            background='#3097E2'
            borderColor='#3097E2'
            onClick={handleSimilarAddCard}
          />
        </View>
      </View>
    </>
  )
}
