import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export interface UseSearchProps {
  query: string
  limit: number
  page: number
  setQuery: (text: string, limit?: number) => void
  setIncrementPage: () => void
}

const useSearch = create(
  persist<UseSearchProps>(
    (set) => {
      return ({
        query: '',
        limit: 10,
        page: 1,
        setQuery: (query: string) => set(() => ({ query, page: 1 })),
        setIncrementPage: () => set((state) => ({ page: state.page + 1 }))
      })
    },
    {
      name: 'search-storage',
      storage: createJSONStorage(() => localStorage)
    }
  )
)

export default useSearch
