import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div`
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: 54px;
  right: 25px;
  min-width: 17rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  border: 1px solid #ECECEC;
`

export const View = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`

export const Item = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }
`
