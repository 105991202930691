export const getMobileClassName = (padding = true, onBottom = false) => {
  const os = localStorage.getItem('farmazon/os')

  if (os && os === 'ios' && onBottom) {
    return padding ? 'mobile-bottom-space-padding-safe' : 'mobile-bottom-space-margin-safe'
  }

  if (os && os === 'ios') {
    return padding ? 'mobile-status-bar-padding-safe' : 'mobile-status-bar-margin-safe'
  }

  return ''
}
