/* eslint-disable @typescript-eslint/no-empty-function */
import { forwardRef } from 'react'

import Image from 'next/image'

import { Loading } from 'components/elements'

import { Container, Input, View, Close } from './styles'

const InputCep = ({
  loading = false,
  enableRemove = false,
  handleRemove = () => {},
  ...props
}, forwardedRef: React.Ref<HTMLInputElement>
) => {
  return (
    <Container>
      <View>
        {loading ? <Loading /> : (
          <Image
            src='/icon/icon-pin.svg'
            width={21}
            height={26}
            alt='Location'
            priority
          />
        )}
      </View>
      <Input ref={forwardedRef} {...props} />
      {enableRemove && (
        <Close onClick={handleRemove}>
          <Image
            src='/icon/icon-close.svg'
            width={15}
            height={15}
            alt='Fechar'
            priority
          />
        </Close>
      )}
    </Container>
  )
}

export default forwardRef(InputCep)
