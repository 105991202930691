import styled from 'styled-components'

export const Container = styled.div<{selected?: boolean}>`
  padding: 1rem;
  border: 1px solid ${({ selected = false }) => selected ? '#EA2A2F' : '#c4c4c4'};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
`
