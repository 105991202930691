import { AnyAction } from 'redux'

const SET_COOKIE_READ = 'configReducer/SET_COOKIE_READ'

export interface StateConfigProps {
  enableCookie: boolean
}

const stateInit = {
  enableCookie: false
}

export const configReducer = (state: StateConfigProps = stateInit, { type, payload }: AnyAction) => {
  switch (type) {
    case SET_COOKIE_READ:
      return { ...state, enableCookie: true }
    default:
      return state
  }
}

export const setReadCookieAction = () => ({ type: SET_COOKIE_READ })
