import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background-color: #fff;
  padding: .6rem 1.2rem;
  width: 240px;
`
export const Label = styled.p`
  font-size: 11px;
  margin-bottom: .3rem;
`

export const Text = styled.p`
  font-size: 15px;
  font-weight: 600;
`
