import styled from 'styled-components'

export const Container = styled.div`

`

export const Card = styled.div<{ primary: string, secondary: string}>`
  background-image: ${({ primary, secondary }) => `linear-gradient(${primary}, ${secondary})`} ;
  height: 300px;
  border-radius: 8px;
`
