import { ReactNode } from 'react'

import { Container } from './styles'

interface ButtonSwitchProps {
  id?: string
  radiusRight?: boolean
  radiusLeft?: boolean
  children: ReactNode
  selected?: boolean
  onClick?: () => void
}

function ButtonSwitch ({
  radiusRight,
  radiusLeft,
  selected,
  children,
  onClick,
  id
}: ButtonSwitchProps) {
  return (
    <Container
      id={id}
      selected={selected}
      radiusRight={radiusRight}
      radiusLeft={radiusLeft}
      onClick={onClick}
    >
      {children}
    </Container>
  )
};

export default ButtonSwitch
