import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Card } from '@material-ui/core'
import { LoadingSimple } from 'components/elements'
import { Row, Text, View } from 'components/helpers'
import { useAddress } from 'hooks/useAddress'
import { AddressResponseProps } from 'services/address'
import { useNewFetch } from 'services/api'
import { RootState } from 'store/reducers'
import { setScreenAction } from 'store/reducers/appReducer'

import { Container, Scroll } from './styles'

const Location: React.FC = () => {
  const { data } = useNewFetch<AddressResponseProps>('/customers/delivery_address/')
  const { user } = useSelector((state: RootState) => state)
  const { setDefaultAddress } = useAddress()
  const dispatch = useDispatch()

  const onPressDefault = (id: string, data: any) =>
    () => {
      setDefaultAddress(id, data)
      dispatch(setScreenAction(''))
    }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <View
        mb={2}
      >
        <Text
          size={18}
          fontWeight={700}
          color='#EA2A2F'
        >
          Meus endereços
        </Text>
      </View>
      <Scroll desktopHeight='24rem' mobileHeight='70%'>
        {!data && (
          <View height='10rem' justify='center' align='center'>
            <LoadingSimple />
          </View>
        )}

        {data?.Items.map((item, index) => (
          <Card
            variant='outlined'
            key={index}
            style={((item.pub_id === user.address_selected?.pub_id) ? { border: `1px solid ${'red'}`, marginBottom: '1rem' } : { marginBottom: '1rem' })}
            onClick={onPressDefault(item.pub_id, data?.Items)}
          >
            <Row style={{ padding: '1rem' }} cursor>
              <Text size={12} fontWeight={700}>{item.nickname}</Text>
              <Text ml={2} size={12} fontWeight={500}>{item.street}, {item.number}, {item.more}, {item.neighborhood} {item.city} - {item.state}</Text>
            </Row>
          </Card>
        ))}
      </Scroll>

    </Container>
  )
}

export default Location
