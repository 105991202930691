
import styled from 'styled-components'

interface ContainerProps {
  width?: number
  height?: number
  selected?: boolean
  disabled?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: ${({ width = 18 }) => `${width}rem`};
  height: ${({ height = 13 }) => `${height}rem`};
  background: #FFFFFF;
  border: 2px solid ${({ selected = false }) => selected ? '#3097E2' : '#eee'};
  box-sizing: border-box;
  border-radius: 5px;
  cursor: ${({ disabled = false }) => disabled ? 'auto' : 'pointer'};
`

export const Header = styled.div`
  padding: 0.8rem 0.8rem 0.6rem 1rem;
  border-bottom: 1px solid #C4C4C4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.8rem;
`

export const Body = styled.div`
  padding: 1rem ;
  display: flex;
  flex-direction: column;
  height: 82%;
`

export default {
  Container,
  Header,
  Body
}
