import styled from 'styled-components'

export const Container = styled.div<{background?: string}>`
  background-color: ${({ background = '#DC3545' }) => background};
  border-radius: 20px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
