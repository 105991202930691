import React from 'react'
import { Visible, Hidden } from 'react-grid-system'
import { useDispatch } from 'react-redux'

import Image from 'next/image'
import Router from 'next/router'

import CloseIcon from '@material-ui/icons/Close'
import { Button } from 'components/form'
import { Column, Text, View } from 'components/helpers'
import { useAddress } from 'hooks/useAddress'
import { setScreenAction } from 'store/reducers/appReducer'

import { Container, ListContainer, ViewClose } from './styles'
import { iconSize } from 'styles'

const Welcome: React.FC = () => {
  const {
    listAddress,
    showSearchInput,
    handleAddLocation,
    handleToggleShowSearchInput
  } = useAddress()

  const dispatch = useDispatch()

  const handleLogin = () => {
    dispatch(setScreenAction(''))
    Router.push('/signin')
  }

  // Default location: Leblon, RJ
  const defaultLocation = {
    lat: -22.9864275,
    lng: -43.2167975
  }

  return (
    <>
      <Hidden xs>
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Column align='center' mt={2}>

            <ViewClose className='fechar_carrinho' onClick={handleAddLocation(defaultLocation)}>
              <CloseIcon style={iconSize} />
            </ViewClose>

            <View justify='center' mb={1}>
              <Image
                src='/images/logo-small.svg'
                width={50}
                height={50}
                alt='Farmazon Logo'
                priority
              />
            </View>

            <View justify='center' mb={1}>
              <Text color='#FF0139' size={36} fontWeight={700} textAlign='center'>Vamos começar?</Text>
            </View>

            <View justify='center' mb={3}>
              <Text textAlign='center' color='#474747' size={18} fontWeight={400}>
                Informe sua localidade para visualizar ofertas especiais e preços atualizados.
              </Text>
            </View>

            <View width='100%'>
              <Button
                onClick={handleAddLocation(defaultLocation)}
                background='#3097E2'
                rightIcon='/icon/icon-caret-right.svg'
                label='Entrar como visitante'
              />
            </View>

            {listAddress.length ? (
              <View width='100%'>
                <ListContainer>
                  {
                    listAddress.map((item: any, index) => (
                      <li key={index}>
                        <View padding={1} direction='row' align='center' cursor onClick={handleAddLocation(item.geometry.location)}>
                          <div style={{ width: '40px' }}>
                            <Image
                              src='/icon/icon-result-address.svg'
                              width={20}
                              height={24}
                              alt='Location PIN'
                              priority
                            />
                          </div>
                          <View ml={1}>
                            <Text>{item.formatted_address}</Text>
                          </View>
                        </View>
                      </li>
                    ))
                  }
                </ListContainer>
              </View>
            ) : (
              <View mt={3}>
                <View mb={1}>
                  <Text size={16}>Já tenho um endereço salvo</Text>
                </View>
                <View>
                  <Text
                    cursor
                    onClick={handleLogin}
                    color='#EA2A2F'
                    size={16} fontWeight={700}
                  >
                    Entrar ou fazer cadastro
                  </Text>
                </View>
              </View>
            )}

          </Column>
        </Container>
      </Hidden>

      <Visible xs>
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <View style={{ position: 'absolute', top: '2rem', right: '2rem', zIndex: 2 }}>
            <Image
              src='/images/logo-small-white.svg'
              width='50'
              height='50'
              alt='Farmazon Logo'
              priority
            />
          </View>
          <View>
            <Image
              src='/images/pharmaceutical.png'
              width='100%'
              height={350}
              objectFit='fill'
              unoptimized
              alt='pharmaceutical'
            />

            <View pt={1} pr={2} pl={2}>
              <Text color='#FF0139' size={28} fontWeight={700} textAlign='left'>Vamos começar?</Text>

              <Text mt={0.6} color='#474747' size={14} fontWeight={400}>
                A farmazon quer te ajudar a encontrar as melhores ofertas e produtos mais pertos de você
              </Text>
            </View>

            {showSearchInput ? (
              <View pt={2.5} pr={2} pl={2}>
                <View width='100%'>
                  <Button
                    onClick={handleAddLocation(defaultLocation)}
                    background='#3097E2'
                    rightIcon='/icon/icon-caret-right.svg'
                    label='Entrar como visitante'
                  />
                </View>
                {/* <InputCep
                    name='address'
                    ref={register}
                    placeholder='Digite a localidade, bairro, cep etc'
                    loading={app.loading}
                    onChange={handleSearchAddress}
                    handleRemove={handleRemoveAddress}
                    enableRemove={listAddress.length > 0}
                  />
                </View>

                {listAddress.length ? (
                  <View width='100%'>
                    <ListContainer>
                      {
                        listAddress.map((item: any, index) => (
                          <li key={index}>
                            <View padding={1} direction='row' align='center' cursor onClick={handleAddLocation(item.geometry.location)}>
                              <div style={{ width: '40px' }}>
                                <Image
                                  src='/icon/icon-result-address.svg'
                                  width={20}
                                  height={24}
                                  alt='Location PIN'
                                  priority
                                />
                              </div>
                              <View ml={1}>
                                <Text>{item.formatted_address}</Text>
                              </View>
                            </View>
                          </li>
                        ))
                      }
                    </ListContainer>
                  </View>
                ) : null} */}

                <View mt={2}>
                  <Text
                    cursor
                    onClick={handleToggleShowSearchInput}
                    textAlign='center'
                    decoration='underline'
                    size={14}
                  >
                    Voltar
                  </Text>
                </View>
              </View>
            ) : (
              <View pt={2.5} pr={2} pl={2}>
                <Button
                  onClick={handleAddLocation(defaultLocation)}
                  background='#3097E2'
                  rightIcon='/icon/icon-caret-right.svg'
                  label='Entrar como visitante'
                />
                <View mt={2}>
                  <Text
                    cursor
                    onClick={handleLogin}
                    textAlign='center'
                    decoration='underline'
                    size={14}
                  >
                    Já possuo cadastro
                  </Text>
                </View>
              </View>
            )}

          </View>
        </Container>
      </Visible>
    </>
  )
}

export default Welcome
