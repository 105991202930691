import { newAxiosInstance } from 'services/api'

export interface AddressProps {
  country: string
  state: string
  city: string
  number: number
  street: string
  cep: string
  neighborhood: string
  more: string
  nickname: string
}

export interface AddressResponseProps {
  Count: number
  Items: any[]
}

export const getAllAddress = async () =>
  newAxiosInstance.get<AddressResponseProps>('/customers/delivery_address/')

export const postAddress = async (data: AddressProps) =>
  newAxiosInstance.post('/customers/delivery_address/', data)

export const defaultAddressCard = async (id: string) =>
  newAxiosInstance.get(`/customers/delivery_address/${id}/default`)

export const deleteAddress = async (id: string) =>
  newAxiosInstance.delete(`/customers/delivery_address/${id}`)

export const getAvaliableStore = async (addressId?: string | null) =>
  newAxiosInstance.get(`/customers/delivery_address/is_available/${addressId}`)
