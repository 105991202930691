import { useMemo } from 'react'

import { createStore, applyMiddleware, Action, Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistedReducer } from 'store/reducers'

let store: (Store<unknown, Action<any>> & { dispatch: unknown }) | undefined

function initStore (initialState: {} | undefined) {
  return createStore(
    persistedReducer,
    initialState,
    applyMiddleware(thunkMiddleware)
  )
}

export const initializeStore = (preloadedState: {} | undefined) => {
  let _store = store ?? initStore(preloadedState)

  if (preloadedState && store) {
    const stores = Object.assign({}, store.getState())

    _store = initStore({
      ...stores,
      ...preloadedState
    })
    store = undefined
  }

  if (typeof window === 'undefined') return _store
  if (!store) store = _store

  return _store
}

export function useStore (initialState: any) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}
