import { ReactNode } from 'react'

import { Container } from './styles'

interface CarouselResponsiveProps {
  children: ReactNode
}

function CarouselResponsive ({ children }: CarouselResponsiveProps) {
  return (
    <Container>
      {children}
    </Container>
  )
};

export default CarouselResponsive
