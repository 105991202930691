import styled from 'styled-components'

export const Container = styled.div<{width?: string}>`
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0.44rem;
  width: 100%;
`

export const InputSelect = styled.select`
  width: 100%;
  height: 35px;
  background: white;
  color: ${({ theme: { color } }) => color.thirdy};
  font-size: 15px;
  border: none;
  text-indent: 7rem;

  option {
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }


  &:focus{
    outline: none;
  }
`

export const Text = styled.p`
  color: red;
`
