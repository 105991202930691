import styled from 'styled-components'

const ImageContainer = styled.div<{url?: string}>`
width: calc(100%);
background-repeat: no-repeat;
background-size: cover;
background-position: center;
background-image: ${({ url }) => `url(${url})`};
position: relative;
z-index: 1;
height: 100%;

&::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.1);
}
`

export default ImageContainer
