import styled from 'styled-components'

const Divider = styled.div<{background?: number}>`
  height: 30px;
  width: 4px;
  margin-left: 8px;
  background-color: ${({ background = 0, theme: { color } }) => background === 0 ? ' rgba(201, 201, 201, 1)' : color.primary};
  transition: all 800ms ease;
`

const Node = styled.div<{background?: number}>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display:inline-block;
  transition: all 1000ms ease;
  background-color: ${({ background = 0, theme: { color } }) => background === 0 ? ' rgba(201, 201, 201, 1)' : color.primary};
`

const Li = styled.li`
  list-style: none;
  line-height: 1px;
  display: flex;
  align-items: center;
  p {
    display:inline-block;
    margin-left: 1rem;
  }
`

export default {
  Divider,
  Node,
  Li
}
