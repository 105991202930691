import React from 'react'
import { Visible } from 'react-grid-system'
import { useSelector } from 'react-redux'

import { Modal, ModalCart } from 'components/elements'
import { StreetRegister, CartBasket, CredCardRegister, Location, OptionsModal, OptionsInformation, NoAddress } from 'container'
import MenuSide from 'container/MenuSide'
import PrescriptionProduct from 'container/PrescriptionProduct'
import Scheduling from 'container/Scheduling'
import Similar from 'container/Similar'
import Welcome from 'container/Welcome'
import { RootState } from 'store/reducers'

import { Container } from './styles'

const ModalManager: React.FC = ({ children }: any) => {
  const { app } = useSelector((state: RootState) => state)

  const selectScreen = (name: string) => {
    switch (name) {
      case 'Init':
        return (
          <Modal>
            <Location />
          </Modal>
        )
      case 'Start':
        return (
          <Modal isFull disableClose>
            <Welcome />
          </Modal>
        )
      case 'StreetRegister':
        return (
          <Modal isFull paddingTop={3}>
            <StreetRegister />
          </Modal>
        )
      case 'NoAddress':
        return (
          <Modal widthContent='50%'>
            <NoAddress />
          </Modal>
        )
      case 'CredCardRegister':
        return (
          <Modal isFull paddingTop={3}>
            <CredCardRegister />
          </Modal>
        )
      case 'Similar':
        return (
          <Modal>
            <Similar />
          </Modal>
        )
      case 'PrescriptionProduct':
        return (
          <Modal>
            <PrescriptionProduct />
          </Modal>
        )
      case 'Scheduling':
        return (
          <Modal>
            <Scheduling />
          </Modal>
        )
      case 'OptionsModal':
        return (
          <Modal>
            <OptionsModal />
          </Modal>
        )
      case 'OptionsInformation':
        return (
          <Modal>
            <OptionsInformation />
          </Modal>
        )
    }
  }

  return (
    <Container>
      {children}
      <Visible xs sm>
        <MenuSide menuToogle={app.menuToggle} />
      </Visible>
      {selectScreen(app.screenRegister)}
      <ModalCart cartToggle={app.cartToggle}>
        <CartBasket />
      </ModalCart>
    </Container>
  )
}

export default ModalManager
