import styled from 'styled-components'

export const Container = styled.div<{borderWidthCheck?: string, rotate?: number}>`
  display: inline-block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  height: 18px;
  width: 18px;

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #666;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;

    border-width: ${({ borderWidthCheck = '0 3px 3px 0' }) => borderWidthCheck};
    transform: rotate(${({ rotate = 45 }) => `${rotate}deg`});
  }

`

export const CheckboxInput = styled.input<{background?: string}>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    background-color: ${({ background = '#2196F3' }) => background};
    border: none;
  }

  &:checked ~ .checkmark:after {
    display: block;
  }

`
