import { useEffect, useMemo } from 'react'
import { Hidden, Visible } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { batch, useDispatch, useSelector } from 'react-redux'

import Image from 'next/image'
import Link from 'next/link'
import Router from 'next/router'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Search } from 'components/elements'
import Icon from 'components/elements/Icon'
import { Row, Text, View } from 'components/helpers'
import { MenuSelect, SearchLayout } from 'container'
import { useAuth } from 'hooks/useAuth'
import useIsMobile from 'hooks/useIsMobile'
import useSearch from 'hooks/useSearch'
import { isLogged } from 'services/AuthService'
import { RootState } from 'store/reducers'
import { enableCartAction, enableLocationAction, enableMenuListAction } from 'store/reducers/appReducer'
import { getMobileClassName } from 'utils/helper/className'
import { getFirstName } from 'utils/helper/string'

import { Circle, Container, Content, Title } from './styles'

interface HeaderProps {
  logo?: string | any
  background?: string
  isNear?: boolean
  title?: string
}

const defaultProps: HeaderProps = {
  isNear: true
}

const HIDDEN_SEARCH_INPUT = ['cartoes', 'enderecos', 'perfil', 'pedidos', 'produto', 'pedido', 'ofertas', 'termos', 'politica', 'sobre-farmazon', 'homecare']
const HIDDEN_BACKBUTTON = ['pedidos', 'ofertas']

const Header: React.FC<HeaderProps> = ({ ...props }) => {
  const { user, app, cart, addressTemporary } = useSelector((state: RootState) => state)
  const { register, handleSubmit } = useForm()
  const { updateProfile } = useAuth()
  const dispatch = useDispatch()

  const isMobile = useIsMobile()
  const { setQuery } = useSearch(state => state)

  useEffect(() => {
    if (isLogged()) dispatch(updateProfile(user.userRole))
  }, [])

  const onPressEnter = () => {
    if (isLogged()) {
      dispatch(enableMenuListAction())
    } else {
      batch(() => {
        dispatch(enableLocationAction())
      })
      Router.push('/signin')
    }
  }

  const onPressCart = () =>
    dispatch(enableCartAction())

  const onSubmit = (data: any) => {
    setQuery(data.name)
    setTimeout(() => {
      Router.push({
        pathname: '/busca',
        query: { q: data.name }
      })
    }, 100)
  }

  const address = useMemo(() => {
    return user.delivery_addresses.find(item => item.pub_id === user.address_selected?.pub_id)
  }, [user.address_selected])

  const hiddenSearchInput = useMemo(() => {
    return isMobile && !!HIDDEN_SEARCH_INPUT.find(item => window.location.pathname.includes(item))
  }, [window.location.href, isMobile])

  const hiddenBackButton = useMemo(() => {
    return (isMobile && window.location.pathname === '/') || (isMobile && !!HIDDEN_BACKBUTTON.find(item => window.location.pathname.includes(item)))
  }, [window.location.pathname, isMobile])

  const handleGoBack = () => {
    // if is search backing
    if (window.location.pathname.includes('/busca')) {
      Router.replace('/')
      return
    }
    if (window.location.pathname.includes('/pedido/')) {
      Router.replace('/pedidos')
      return
    }

    Router.back()
  }

  const handleGoToAddresses = () => {
    if (isLogged()) {
      Router.push('/enderecos')

      return
    }

    Router.push('/signin')
  }

  return (
    <Container id='header' className={getMobileClassName()} background={props.background}>
      <Content>
        <Visible xs>
          {!hiddenBackButton ? (
            <button onClick={handleGoBack} className='button-reset' style={{ marginRight: '1rem' }}>
              <Icon name='caret-left' />
            </button>
          ) : null}
        </Visible>

        <View
          cursor='true'
          mr={1}
        >
          <Hidden xs>
            <Link href='/' passHref>
              <a>
                <Image
                  src={props.logo ? props.logo : '/images/logo.svg'}
                  width={140}
                  height={38}
                  alt='Farmazon Logo'
                  priority
                />
              </a>
            </Link>
          </Hidden>
        </View>

        {!hiddenSearchInput ? (
          <Hidden xs>
            <View mr={1} width='46%'>
              <form id='searchForm' onSubmit={handleSubmit(onSubmit)}>
                <Search
                  placeholder='O que você está procurando?'
                  ref={register}
                  name='name'
                  handleIconPress={handleSubmit(onSubmit)}
                />
                <input type='submit' style={{ position: 'absolute', left: '-9999px' }} />
              </form>
            </View>

          </Hidden>
        ) : null}

        {props.title ? <Title>{props.title}</Title> : null}

        <Visible sm>
          <View width='180px'>
            <Text fontWeight={500} size={12} color='#a6a5a5'>ENTREGAR EM</Text>
            {(isLogged()
              ? user.address_selected ? (
                <View width='12rem'>
                  <Text size={11} fontWeight={500} color='#EA2A2F'>{address?.street}, {address?.number}</Text>
                </View>
              ) : (
                <View width='12rem'>
                  <Text size={12} fontWeight={500}>Cadastrar endereço</Text>
                </View>
              ) : (
                <View width='12rem'>
                  <Text size={11} fontWeight={500} color='#EA2A2F'>
                    {addressTemporary.hasTemporaryAddress ? `${addressTemporary.street}, ${addressTemporary.number}` : '-'}
                  </Text>
                </View>
              ))}
          </View>
        </Visible>

        {window.location.pathname === '/' ? (
          <Hidden sm>
            <View direction='row' align='center' onClick={handleGoToAddresses} style={{ cursor: 'pointer' }}>
              <Icon name='map-pin' />

              <View ml={0.5} width='180px'>
                <Text size={12}>Onde vamos entregar?</Text>
                {(isLogged()
                  ? user.address_selected ? (
                    <View width='12rem'>
                      <Text size={13} fontWeight={600}>{address?.street}, {address?.number}</Text>
                    </View>
                  ) : (
                    <View width='12rem'>
                      <Text size={13} fontWeight={600}>Cadastrar endereço</Text>
                    </View>
                  ) : (
                    <View width='12rem'>
                      <Text size={13} fontWeight={600}>
                        {addressTemporary.hasTemporaryAddress ? `${addressTemporary.street}, ${addressTemporary.number}` : '-'}
                      </Text>
                    </View>
                  ))}
              </View>
            </View>
          </Hidden>
        ) : null}

        <View style={{ marginLeft: 'auto' }}>
          <Row>

            {/* <View justify='center' align='center' cursor='true' mr={1}>
              <Image
                src='/icon/icon-oferta.svg'
                width={26}
                height={26}
                alt='Oferta'
                priority
              />
            </View> */}

            <Hidden xs>
              {props.isNear && (
                <View onClick={onPressCart} justify='center' align='center' cursor='true' mr={1}>
                  {cart.items.length > 0 && (<Circle>{cart.items.length}</Circle>)}
                  <Image
                    src='/icon/icon-carrinho.svg'
                    width={26}
                    height={26}
                    alt='Carrinho'
                    priority
                  />
                </View>
              )}
            </Hidden>

            <Hidden xs>
              <View onClick={onPressEnter} justify='center' align='center' cursor='true' mr={1}>
                <Row mr={0.5} align='center'>
                  <View mr={1}>
                    <Image
                      src='/icon/icon-user.svg'
                      width={26}
                      height={26}
                      alt='Usuário'
                      priority
                    />
                  </View>
                  <Hidden xs lg md sm>

                    <View>
                      <Text size={14} fontWeight={500} color='#474747'>Olá, {user.name !== '' ? getFirstName(user.name?.toLocaleUpperCase()) : 'Visitante'}</Text>
                    </View>

                    <View ml={0.4}>
                      <ArrowDropDownIcon fontSize='small' />
                    </View>

                  </Hidden>
                </Row>
              </View>
            </Hidden>

            {isLogged() && (app.menuListToggle && <MenuSelect />)}

          </Row>
        </View>

      </Content>

      {!hiddenSearchInput ? (<SearchLayout />) : null}
    </Container>
  )
}

Header.defaultProps = defaultProps

export default Header
