import styled from 'styled-components'

interface PositionProps {
  position?: string
  bottom?: number
  top?: number
  right?: number
  left?: number
}

export default styled.div<PositionProps>`
  position: ${({ position = 'relative' }) => position};
  ${({ bottom = 0 }) => bottom && `bottom: ${bottom}rem`};
  ${({ top = 0 }) => top && `top: ${top}rem`};
  ${({ right = 0 }) => right && `right: ${right}rem`};
  ${({ left = 0 }) => left && `left: ${left}rem`};
`
