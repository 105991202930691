import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 6px;
  border: 1px solid #FF0000;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  top: auto;
  background-color: #fff;
  justify-content: space-between;
  z-index: 20;
  margin-bottom: 1.4rem;
  
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 1rem;
  }
`

export const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin-right: 1rem;

  @media screen and (max-width: 500px) {
    margin-right: 0;
    margin-bottom: 0.8rem;
  }
`

export const Linka = styled.span`
  color: #FF0000;
  font-weight: 500;
  cursor: pointer;
`

export const Button = styled.button`
  background-color: #FF0000;
  padding: 12px;
  border: none;
  border-radius: 4px;
  color: #fff;
  width: 15rem;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

`
