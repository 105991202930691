import { Container, Text, Button } from './styles'

import { CountProps } from './types'

const Count: React.FC<CountProps> = ({
  quantity = 0,
  disabledIncrement = false,
  disabledDecrement = false,
  onIncrement,
  onDecrement
}) => {
  return (
    <Container>
      <Button onClick={disabledDecrement ? undefined : onDecrement}>-</Button>
      <Text>{quantity}</Text>
      <Button onClick={disabledIncrement ? undefined : onIncrement}>+</Button>
    </Container>
  )
}

export default Count
