
import { useCallback, useState } from 'react'
import { useAlert } from 'react-alert'
import { batch, useDispatch, useSelector } from 'react-redux'

import { Dispatch } from 'redux'
import { getUpdateOrder } from 'services/order'
import { checkCartProducts, getProductCategory, getSearchProducts, getSearchProductsFallBack, getSearchSuggest, getSimilarProduct } from 'services/product'
import { RootState } from 'store/reducers'
import { disableCartAction, disableLoadingAction, enableCartAction, enableLoadingAction, setScreenAction } from 'store/reducers/appReducer'
import { addItemCartAction, addProductMainAction, cleanProductMainAction, removeItemCartAction, resetCartAction, setCartAction, setCountItemCartAction, updateItemCardAction } from 'store/reducers/cartReducer'
import { addOptionProductAction, cleanOptionProductAction } from 'store/reducers/optionProductReducer'
import { addListSimilarAction, addSimilarAction, cleanSimilarAction } from 'store/reducers/similarReducer'
import { mutate } from 'swr'
import { getUserLocalLocation } from 'utils/helper/location'
import { useRouter } from 'next/router'
import useSearch from './useSearch'

export interface ProductListProps {
  max_price: number
  min_price: number
  ean: string
  active: boolean
  ordering: number
  image: Image
  keywords: string[]
  characteristics: Characteristics
  price: number
  description: string
  pub_id: string
  name: string
  tags: Tag[]
  timestamp: string
  slug: string
  img: string
  available_quantity?: number
  store?: string
  store_product_id?: string
  is_available?: boolean
  characteristic?: any
}

export interface Characteristics {
  mode: string
  presentation: string
  unit: string
  temperature: string
  ncm: string
  max_price_multiplier: number
  sku: string
  weighing: string
}

export interface Image {
  normal: string[]
  highlights: string
  lists: string
  thumbs: string
}

export interface Tag {
  index: number
  tag: string
}

export interface CategoryProps {
  image: string
  name: string
  subcategories: Array<{name: string}>
}

const CATEGORY = 'CATEGORY'

export const useProduct = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { user, addressTemporary } = useSelector((state: RootState) => state)
  const [products, setProduct] = useState<ProductListProps[]>([])
  const [categories, setCategories] = useState<CategoryProps[]>(() => {
    const categories = localStorage.getItem(CATEGORY)
    if (categories) {
      return JSON.parse(categories)
    }
    return []
  })
  const [similars, setSimilars] = useState<any[]|null>(null)
  const [loading, setloading] = useState(false)
  const [buyAgainLoading, setBuyAgainLoading] = useState(false)

  const { page, setIncrementPage } = useSearch()
  const [lastPage, setLastPage] = useState(true)
  const [suggest, setSuggest] = useState([])

  const handleConfirmDelivery = (orderId: string) => {
    return async () => {
      try {
        setloading(true)
        await getUpdateOrder(orderId)
        mutate(`/orders/${orderId}`)
      } finally {
        setloading(false)
      }
    }
  }

  const alert = useAlert()

  const onIncrementProduct = (idProduct: string) =>
    (dispatch: Dispatch, getState: () => RootState) => {
      const { cart } = getState()
      const found = cart.items.find(cart => idProduct === cart.pub_id)
      if (found) dispatch(setCountItemCartAction(idProduct, (found.quantity + 1)))
    }

  const onDecrementProduct = (idProduct: string) =>
    (dispatch: Dispatch, getState: () => RootState) => {
      const { cart } = getState()
      const found = cart.items.find(cart => idProduct === cart.pub_id)
      if (found && found.quantity === 1) dispatch(removeItemCartAction(idProduct))
      if (found && found.quantity > 1) dispatch(setCountItemCartAction(idProduct, (found.quantity - 1)))
    }

  const searchProductSplit = async (q: string, lat: number, lng: number) => {
    const { data } = await getSearchProducts({ lat, lng, page, q, limit: 10 })
    return data?.data
  }

  const getFallBack = async (lat: number, lng: number) => {
    const { data } = await getSearchProductsFallBack({ lat, lng, limit: 30 })
    return data?.data
  }

  const searchProductByName = async (lat: number, lng: number, q: string, limit: number) => {
    setloading(true)
    dispatch(enableLoadingAction())
    // @ts-ignore
    alert.removeAll()
    try {
      const { data } = await getSearchProducts({ lat, lng, page, q, limit })
      const products = data.data
      setLastPage(!data.lastPage)

      const productsFilter = products.filter((item: any) => item.img)

      setProduct(productsFilter)
      // setCount(data.pagination.total)
    } catch (error: any) {
      alert.error(error.message)
    } finally {
      setloading(false)
      dispatch(disableLoadingAction())
    }
  }

  const searchSuggest = async (query: string, lat: number, lng: number) => {
    try {
      const { data } = await getSearchSuggest(query, lat, lng)
      const filterWords = data.filter((item: string) => query.toLowerCase() !== item.toLowerCase())
      setSuggest(filterWords)
    } catch (error: any) {
      // alert.error(error.message)
    }
  }

  const getMoreProductOffset = async (lat: number, lng: number, q: string, limit: number) => {
    setloading(true)
    dispatch(enableLoadingAction())
    // @ts-ignore
    alert.removeAll()
    try {
      const { data } = await getSearchProducts({ lat, lng, q, limit, page: page + 1 })
      const productsLocal = data.data
      setLastPage(!data.lastPage)

      setIncrementPage()

      setProduct(old => [...old, ...productsLocal])
    } catch (error: any) {
      alert.error(error.message)
    } finally {
      setloading(false)
      dispatch(disableLoadingAction())
    }
  }

  const handleCartSimilar = (productId: string, product: any) => {
    return async () => {
      const location = getUserLocalLocation(user, addressTemporary)
      const { data } = await getSimilarProduct(productId, undefined, location.lat, location.lng)

      if (data.length) {
        dispatch(disableCartAction())
        const subs = product.substitutes?.map((item: any) => item.pub_id)
        batch(() => {
          dispatch(addProductMainAction(product))
          dispatch(addListSimilarAction(data))
          dispatch(setScreenAction('Similar'))
          dispatch(addSimilarAction(subs))
        })
      }
    }
  }

  const handleAddCartItem = (product: any, disableShowCart? : boolean) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
      const { cart } = getState()
      const found = cart.items.some(cart => product.pub_id === cart.pub_id)
      if (!found) {
        batch(() => {
          dispatch(addItemCartAction({
            pub_id: product?.pub_id,
            name: product?.name,
            quantity: product.quantity,
            price: product?.price,
            description: product?.description,
            min_price: product?.min_price,
            max_price: product?.max_price,
            substitutes: [],
            required: true,
            prescription: product.characteristic.prescription,
            img: product?.img,
            available_quantity: product?.available_quantity,
            store: product?.store,
            store_product_id: product?.store_product_id,
            is_available: product?.is_available
          }))
          dispatch(cleanProductMainAction())
          dispatch(cleanSimilarAction())
        })
      }
      if (!disableShowCart) setTimeout(() => dispatch(enableCartAction()), 500)
    }
  }

  const handleAddCartSingle = (product: any) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
      const { cart } = getState()
      const found = cart.items.some(cart => product.pub_id === cart.pub_id)
      if (!found) {
        batch(() => {
          dispatch(addItemCartAction({
            pub_id: product?.pub_id,
            name: product?.name,
            quantity: 1,
            price: product?.price,
            description: product?.description,
            min_price: product?.min_price,
            max_price: product?.max_price,
            substitutes: product.substitutes,
            required: product?.required || true,
            prescription: product?.characteristic?.prescription,
            img: product?.img,
            available_quantity: product?.available_quantity,
            store: product?.store,
            store_product_id: product?.store_product_id,
            is_available: product?.is_available
          }))
          dispatch(cleanProductMainAction())
          dispatch(cleanSimilarAction())
          dispatch(setScreenAction(''))
          dispatch(cleanOptionProductAction())
        })
      }
    }
  }

  const handleChargeOption = (product: any) => {
    return () => {
      batch(() => {
        dispatch(addOptionProductAction(product))
        dispatch(setScreenAction('OptionsModal'))
      })
    }
  }

  const handleAddProductCart = (product: any) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
      const { cart } = getState()
      const found = cart.items.find(cart => product.pub_id === cart.pub_id)

      if (!found) {
        batch(() => {
          dispatch(addItemCartAction({
            pub_id: product?.pub_id,
            name: product?.name,
            quantity: 1,
            price: product?.price,
            description: product?.description,
            min_price: product?.min_price,
            max_price: product?.max_price,
            substitutes: product.substitutes,
            required: product?.required,
            prescription: product?.characteristic?.prescription,
            img: product?.img,
            available_quantity: product?.available_quantity,
            store: product?.store,
            store_product_id: product?.store_product_id,
            is_available: product?.is_available
          }))
          dispatch(cleanProductMainAction())
          dispatch(cleanSimilarAction())
          dispatch(setScreenAction(''))
          dispatch(cleanOptionProductAction())
        })
      } else {
        batch(() => {
          dispatch(updateItemCardAction({
            pub_id: product?.pub_id,
            name: product?.name,
            quantity: found?.quantity,
            price: product?.price,
            description: product?.description,
            min_price: product?.min_price,
            max_price: product?.max_price,
            substitutes: product.substitutes,
            required: product?.required,
            prescription: product?.characteristic?.prescription,
            img: product?.img,
            available_quantity: product?.available_quantity,
            store: product?.store,
            store_product_id: product?.store_product_id,
            is_available: product?.is_available
          }))
          dispatch(cleanProductMainAction())
          dispatch(cleanSimilarAction())
          dispatch(setScreenAction(''))
          dispatch(cleanOptionProductAction())
        })
      }
    }
  }

  const handleAddSimilarsProduct = () => {
    return (dispatch: Dispatch, getState: () => RootState) => {
      try {
        const { cart, similar } = getState()
        const found = cart.items.find(item => cart?.product?.pub_id === item.pub_id)
        const listSubstitutes = similar.listSimilar.filter(item => similar.substitutes.includes(item.pub_id))

        if (!found) {
          batch(() => {
            dispatch(addItemCartAction({
              pub_id: cart.product?.pub_id,
              name: cart.product?.name,
              quantity: cart.product?.quantity ?? 1,
              price: cart.product?.price,
              description: cart.product?.description,
              min_price: cart.product?.min_price,
              max_price: cart.product?.max_price,
              substitutes: listSubstitutes,
              required: null,
              prescription: cart.product?.characteristic?.prescription,
              img: cart.product?.img,
              available_quantity: cart?.product?.available_quantity,
              store: cart?.product?.store,
              store_product_id: cart?.product?.store_product_id,
              is_available: cart?.product?.is_available
            }))

            dispatch(cleanProductMainAction())
            dispatch(setScreenAction(''))
            dispatch(cleanSimilarAction())
          })
        } else {
          batch(() => {
            dispatch(updateItemCardAction({
              pub_id: found?.pub_id,
              name: found?.name,
              quantity: found?.quantity,
              price: found?.price,
              description: found?.description,
              min_price: found?.min_price,
              max_price: found?.max_price,
              substitutes: listSubstitutes,
              required: null,
              prescription: found?.characteristic?.prescription,
              img: found?.img,
              available_quantity: found?.available_quantity,
              store: found?.store,
              store_found_id: found?.store_product_id,
              is_available: found?.is_available
            }))

            dispatch(cleanProductMainAction())
            dispatch(setScreenAction(''))
            dispatch(cleanSimilarAction())
          })
        }

        setTimeout(() => dispatch(enableCartAction()), 500)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleAllSimilarProduct = () => {
    return (dispatch: Dispatch, getState: () => RootState) => {
      const { similar } = getState()
      if (similar.listSimilar.length === similar.substitutes.length) {
        dispatch(addSimilarAction([]))
      } else {
        dispatch(addSimilarAction(similar.listSimilar.map(item => item.pub_id)))
      }
    }
  }

  const handleAddSimilarProduct = (id: string) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
      const { similar } = getState()

      if (similar.substitutes.includes(id)) {
        const substitutes = similar.substitutes.filter(item => item !== id)
        dispatch(addSimilarAction(substitutes))
      } else {
        dispatch(addSimilarAction([...similar.substitutes, id]))
      }
    }
  }

  const handlePrescription = () => {
    dispatch(setScreenAction('PrescriptionProduct'))
  }

  const handleProductCategory = async () => {
    const location = getUserLocalLocation(user, addressTemporary)
    const { data } = await getProductCategory(location)
    localStorage.setItem(CATEGORY, JSON.stringify(data))
    setCategories(data)
  }

  const handleAddCart = (product: any) =>
    () => dispatch(handleAddCartSingle(product))

  const handleIncrement = (idProduct: string) =>
    () => dispatch(onIncrementProduct(idProduct))

  const handleDecrement = (idProduct: string) =>
    () => dispatch(onDecrementProduct(idProduct))

  const getProductSimilar = async (productId: string) => {
    try {
      const location = getUserLocalLocation(user, addressTemporary)
      const { data } = await getSimilarProduct(productId, 5, location.lat, location.lng)
      setSimilars(data.slice(0, 5))
    } catch (error) {}
  }

  const getProductOption = useCallback((required: any = null, substitutes: any = []) => {
    if (required === null && substitutes.length === 0) return 'Ela pode escolher um similar'
    if (required === null && substitutes.length > 0) return 'Substituir por'
    if (required === false && substitutes.length === 0) return 'Tudo bem, não é obrigatório.'
    return 'Este produto é obrigatório.'
  }, [])

  const getIconOption = useCallback((required: any = null, substitutes: any = []) => {
    if (required === null && substitutes.length === 0) return '/icon/icon-change.svg'
    if (required === null && substitutes.length > 0) return '/icon/icon-change.svg'
    if (required === false && substitutes.length === 0) return '/icon/icon-up.svg'
    return '/icon/icon-important.svg'
  }, [])

  const getEventName = useCallback((required = null, substitutes = []) => {
    if (required === null && substitutes.length === 0) return 'farmacia_escolhe'
    if (required === null && substitutes.length > 0) return 'produto_substituto'
    if (required === false && substitutes.length === 0) return 'produto_nao_obrigatorio'
    return 'produto_obrigatorio'
  }, [])

  const handleSubmitOption = (option: any, product: any, similars: any) => {
    if (option.required === null && option.substitutes.length > 0) {
      dispatch(disableCartAction())
      const subs = product.substitutes?.map((item: any) => item.pub_id)
      batch(() => {
        dispatch(addProductMainAction({ ...product, required: option.required }))
        dispatch(addListSimilarAction(similars))
        dispatch(setScreenAction('Similar'))
        dispatch(addSimilarAction(subs ?? []))
      })
      return
    }

    const productCopy = {
      ...product,
      required: option.required,
      substitutes: option.substitutes
    }

    dispatch(handleAddProductCart(productCopy))
  }

  const handleAddOfferCart = (offerId: string, product: any) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
      const { cart } = getState()
      const found = cart.items.some(cart => product.pub_id === cart.pub_id)
      if (!found) {
        batch(() => {
          dispatch(addItemCartAction({
            pub_id: offerId,
            name: product?.name,
            quantity: 1,
            price: product?.price,
            description: product?.description,
            min_price: product?.normal_price,
            max_price: product?.price,
            substitutes: product.substitutes,
            required: product?.required,
            img: product?.img,
            isOffer: true
          }))
          dispatch(cleanProductMainAction())
          dispatch(cleanSimilarAction())
          dispatch(setScreenAction(''))
          dispatch(cleanOptionProductAction())
        })
      }
    }
  }

  const handleBuyAgain = (products: any) => {
    return async () => {
      try {
        setBuyAgainLoading(true)

        const location = getUserLocalLocation(user, addressTemporary)
        const data = await checkCartProducts({
          lat: location.lat,
          lng: location.lng,
          products
        })

        dispatch(resetCartAction())
        dispatch(setCartAction(data))

        await router.push('/checkout')
        alert.success('Carrinho atualizado com sucesso')
      } finally {
        setBuyAgainLoading(false)
      }
    }
  }

  return {
    loading,
    similars,
    categories,
    handleAddSimilarProduct,
    searchProductByName,
    getMoreProductOffset,
    data: products,
    suggest,
    handleAddCartItem,
    handleAllSimilarProduct,
    handleAddSimilarsProduct,
    handlePrescription,
    handleProductCategory,
    handleAddCartSingle,
    onIncrementProduct,
    onDecrementProduct,
    handleCartSimilar,
    handleAddCart,
    handleAddOfferCart,
    handleIncrement,
    handleDecrement,
    getProductSimilar,
    getProductOption,
    getIconOption,
    handleAddProductCart,
    handleSubmitOption,
    handleChargeOption,
    getEventName,
    handleConfirmDelivery,
    buyAgainLoading,
    handleBuyAgain,
    page,
    lastPage,
    searchSuggest,
    setIncrementPage,
    searchProductSplit,
    getFallBack
  }
}
