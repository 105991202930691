import styled from 'styled-components'

interface TextProps {
  mb?: number
  mt?: number
  mr?: number
  ml?: number
  size?: number
  fontWeight?: number | string
  color?: string
  textAlign?: string
  decoration?: string
  lineHeight?: string
  cursor?: boolean
}

export default styled.p<TextProps>`
  font-size: ${({ size = 16 }) => `${size}px`};
  font-weight: ${({ fontWeight = 100 }) => fontWeight};
  color: ${({ color = '#3E3E3E' }) => color};
  text-align: ${({ textAlign = 'left' }) => textAlign};
  text-decoration: ${({ decoration = 'auto' }) => decoration};
  line-height: ${({ lineHeight = '1.3' }) => lineHeight};
  ${({ cursor }) => cursor && 'cursor: pointer;'};

  ${({ mb = 0 }) => mb && `margin-bottom: ${mb}rem`};
  ${({ mt = 0 }) => mt && `margin-top: ${mt}rem`};
  ${({ mr = 0 }) => mr && `margin-right: ${mr}rem`};
  ${({ ml = 0 }) => ml && `margin-left: ${ml}rem`};
`
