import { Hidden, useScreenClass } from 'react-grid-system'

import Image from 'next/image'
import Link from 'next/link'

import { Grid } from '@material-ui/core'
import { Row, Text, View } from 'components/helpers'

import { Container, Content, Card, Box } from './styles'

import packageJson from '../../../package.json'

const Footer: React.FC = () => {
  // const setup = useSelector((state: RootState) => state.user.setup.customer?.static_pages ?? [])
  const screenClass = useScreenClass()

  const handleHelp = () => {
    window.open(
      'https://resource.farmazon.com.br/faq/index.html',
      '_blank',
      'noopener,noreferrer'
    )
  }

  return !['xs'].includes(screenClass) ? (
    <View id='footer-container' className='tabbar-safe'>
      <Container>
        <Content>
          <Grid container spacing={4}>
            <Hidden xs>
              <Grid item xs={12} md={4}>
                <Box>
                  <View width='6rem'>
                    <Text
                      color='#EA2A2F'
                      size={12}
                      fontWeight={700}
                      textAlign='center'
                    >
                      Baixe nosso app! Sempre On.
                    </Text>
                  </View>
                  <div className='apps__images'>
                    <img
                      src='/images/footer/app-ios.svg'
                      alt='App farmazon iOS'
                      height={42}
                      width={130}
                    />

                    <img
                      src='/images/footer/app-android.svg'
                      alt='App farmazon Android'
                      width={130}
                      height={42}
                    />
                  </div>
                </Box>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={4}>
              <Link
                href='https://api.whatsapp.com/send?phone=552140422272'
                passHref
              >
                <a target='_blank'>
                  <Box className='whatsApp__box'>
                    <View className='whatsApp__box-col'>
                      <Image
                        src='/images/footer/whatsapp.png'
                        height={35}
                        width={35}
                        alt='WhatsApp'
                        layout='fixed'
                      />
                    </View>

                    <View width='70%' className='whatsApp__text'>
                      <Text color='#474747' size={12} fontWeight={700}>
                        Nosso WhatsApp
                      </Text>
                      <View mt={0.4}>
                        <Text color='#00997F' size={18} fontWeight={700}>
                          (21) 99087-2272
                        </Text>
                      </View>
                    </View>
                  </Box>
                </a>
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className='time__box'>
                <View className='time__box-col'>
                  <Image
                    src='/images/footer/atendimento.svg'
                    height={40}
                    width={40}
                    alt='Atendimento'
                    layout='fixed'
                  />
                </View>
                <View className='time__box-text'>
                  <Text color='#474747' size={12} fontWeight={700}>
                    Nossos horários
                  </Text>
                  <View mt={0.4}>
                    <Text color='#EA2A2F' size={14} fontWeight={400}>
                      8h às 22h / Seg - Sex
                      <br />
                      8h às 12h e 13h às 17h / Sáb - Dom
                    </Text>
                  </View>
                </View>
              </Box>
            </Grid>
          </Grid>

          <View mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} className='footer-bottom__desk'>
                <Card className='socials__box'>
                  <View mb={1.2}>
                    <Image
                      src='/images/logo.svg'
                      width='64'
                      height='24'
                      alt='Logo Farmazon'
                    />
                  </View>

                  <Row>
                    <View mr={1}>
                      <a
                        href='https://www.facebook.com/farmazonbr/'
                        target='_blank'
                      >
                        <Image
                          src='/images/footer/facebook.svg'
                          height={20}
                          width={20}
                          alt='Facebook'
                          layout='fixed'
                        />
                      </a>
                    </View>
                    <View mr={1}>
                      <a
                        href='https://www.instagram.com/farmazon_digital'
                        target='_blank'
                      >
                        <Image
                          src='/images/footer/instagram.svg'
                          height={20}
                          width={20}
                          alt='Instagram'
                          layout='fixed'
                        />
                      </a>
                    </View>
                  </Row>

                  <View mt={3}>
                    <Text size={10} fontWeight={400}>
                      © 2024 Farmazon. Todos os direitos reservados.
                    </Text>
                    <Text size={10}>Versão: {packageJson.version}</Text>
                  </View>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4} md={2} className='nav-footer'>
                <Text fontWeight={600} size={13}>
                  Institucional
                </Text>
                <View pt={1}>
                  <View mb={0.6}>
                    <Link href='/sobre-farmazon'>
                      <a>
                        <Text size={10}>Sobre a Farmazon</Text>
                      </a>
                    </Link>
                  </View>
                  <View mb={0.6}>
                    <Link href='/termos'>
                      <a>
                        <Text size={10}>Termos de Uso</Text>
                      </a>
                    </Link>
                  </View>
                  <View mb={0.6}>
                    <Link href='/politica'>
                      <a>
                        <Text size={10}>Política de privacidade</Text>
                      </a>
                    </Link>
                  </View>
                  {/* <View mb={0.6}>
                  <Link href='/imprensa'>
                    <a>
                      <Text size={10}>Imprensa</Text>
                    </a>
                  </Link>
                </View> */}
                </View>
              </Grid>

              <Grid item xs={12} sm={4} md={2} className='nav-footer'>
                <Text fontWeight={600} size={13}>
                  Para as Farmácias
                </Text>
                <View pt={1}>
                  {/* <View mb={0.6}>
                  <Link href='/'>
                    <a>
                      <Text size={10}>Como funciona</Text>
                    </a>
                  </Link>
                </View> */}
                  <View mb={0.6}>
                    <Link href='https://loja.farmazon.com.br'>
                      <a target='_blank'>
                        <Text size={10}>Cadastrar farmácia</Text>
                      </a>
                    </Link>
                  </View>
                  <View mb={0.6}>
                    <Link href='https://parceiros.farmazon.com.br'>
                      <a target='_blank'>
                        <Text size={10}>Canal do parceiro</Text>
                      </a>
                    </Link>
                  </View>
                  <View mb={0.6}>
                    <Link href='https://integracao.farmazon.com.br'>
                      <a target='_blank'>
                        <Text size={10}>Para desenvolvedores</Text>
                      </a>
                    </Link>
                  </View>
                </View>
              </Grid>

              <Grid item xs={12} sm={4} md={2} className='nav-footer'>
                {/* <Text fontWeight={600} size={13}>
                  Para os Clientes
                </Text>
                <View pt={1}>
                  <View mb={0.6}>
                    <Link href='/'>
                      <a>
                        <Text size={10}>Como comprar no site</Text>
                      </a>
                    </Link>
                  </View>
                  <View mb={0.6}>
                    <Link href='/'>
                      <a>
                        <Text size={10}>Prazo de entrega</Text>
                      </a>
                    </Link>
                  </View>
                  <View mb={0.6}>
                    <Link href='/'>
                      <a>
                        <Text size={10}>Reembolso</Text>
                      </a>
                    </Link>
                  </View>
                  <View mb={0.6}>
                    <Link href='/'>
                      <a>
                        <Text size={10}>Troca ou devolução</Text>
                      </a>
                    </Link>
                  </View>
                  <View mb={0.6}>
                    <a onClick={handleHelp}>
                      <Text size={10}>Central de ajuda</Text>
                    </a>
                  </View>
                </View> */}
              </Grid>

              <Grid item xs={12} md={3} className='nav-footer'>
                <View mb={1}>
                  <Text fontWeight={600} size={13}>
                    Meios de pagamento
                  </Text>
                </View>

                <Row className='payment-cards__box'>
                  <View mr={1}>
                    <Image
                      src='/images/footer/credcard/visa.png'
                      height={37}
                      width={50}
                      alt='Visa'
                      layout='fixed'
                    />
                  </View>
                  <View mr={1}>
                    <Image
                      src='/images/footer/credcard/amex.png'
                      height={37}
                      width={50}
                      alt='Amex'
                      layout='fixed'
                    />
                  </View>
                  <View mr={1}>
                    <Image
                      src='/images/footer/credcard/dinners.png'
                      height={37}
                      width={50}
                      alt='Dinners'
                      layout='fixed'
                    />
                  </View>
                  <View mr={1}>
                    <Image
                      src='/images/footer/credcard/elo.png'
                      height={37}
                      width={50}
                      alt='Elo'
                      layout='fixed'
                    />
                  </View>
                  <View>
                    <Image
                      src='/images/footer/credcard/master.png'
                      height={37}
                      width={50}
                      alt='Master'
                      layout='fixed'
                    />
                  </View>
                </Row>

                <View mt={1} mb={1}>
                  <Text fontWeight={600} size={13}>
                    Segurança
                  </Text>
                </View>

                <Row className='payment-cards__box'>
                  <View mr={1}>
                    <Image
                      src='/images/footer/siteprotegido.png'
                      height={30}
                      width={100}
                      alt='Visa'
                      layout='fixed'
                    />
                  </View>
                  <View>
                    <Image
                      src='/images/footer/aws-certificado.png'
                      height={48}
                      width={48}
                      alt='Master'
                      layout='fixed'
                    />
                  </View>
                </Row>
              </Grid>

              <Grid item xs={12} md={3} className='footer-bottom__mob'>
                <Card className='socials__box'>
                  <View mb={1.2}>
                    <Image
                      src='/images/logo.svg'
                      width='64'
                      height='24'
                      alt='Logo Farmazon'
                    />
                  </View>

                  <Row>
                    <View mr={1}>
                      <Image
                        src='/images/footer/facebook.svg'
                        height={20}
                        width={20}
                        alt='Facebook'
                        layout='fixed'
                      />
                    </View>
                    <View mr={1}>
                      <Image
                        src='/images/footer/instagram.svg'
                        height={20}
                        width={20}
                        alt='Instagram'
                        layout='fixed'
                      />
                    </View>
                    <Image
                      src='/images/footer/youtube.svg'
                      height={20}
                      width={20}
                      alt='Youtube'
                      layout='fixed'
                    />
                  </Row>

                  <View mt={3}>
                    <Text size={10} fontWeight={400}>
                      © 2024 Farmazon. Todos os direitos reservados.
                    </Text>
                    <Text size={10}>Versão: {packageJson.version}</Text>
                  </View>
                </Card>
              </Grid>
            </Grid>
          </View>
        </Content>
      </Container>
    </View>
  ) : null
}

export default Footer
