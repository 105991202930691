import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.color.secondary};
  padding: 0.2rem 0.4rem;
  border-radius: 6px;
`

export const Text = styled.p`
  font-size: 15px;
  font-weight: 100;
  padding: 0 0.8rem;
`

export const Button = styled.div`
  color: #FF0037;
  font-weight: 300;
  font-size: 22px;
  padding: 0 0.3rem;
  cursor: pointer;
  
`
