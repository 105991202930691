import { forwardRef } from 'react'

import { Container, Input, Text } from './styles'

import { InputTextProps } from './types'

const InputText = ({
  errorMessage = '',
  type = 'text',
  ...props
}: InputTextProps, forwardedRef: React.Ref<HTMLInputElement>) => {
  return (
    <>
      <Container>
        <Input type={type} ref={forwardedRef} {...props} />
      </Container>
      {errorMessage && (<Text>{errorMessage}</Text>)}
    </>
  )
}

export default forwardRef(InputText)
