import ServerCookie from 'next-cookies'

import { Auth } from 'aws-amplify'
import Cookie from 'js-cookie'
import { updateCPF } from 'store/reducers/userReducer'

export interface SignInProps {
  username: string
  password: string
}

export interface TokenApiResponse {
  token_type: string
  token: string
}

export interface ForgetConfirmProps {
  telephone: string
  key: string
  new_password1: string
  new_password2: string
}

export const TOKEN_STORAGE_KEY = 'farmazon.ecommerce'

export const cleanPhoneNumber = (userLogin: string) =>
  `${userLogin.replace(/[^0-9]+/g, '')}`

export const getContextToken = (context: any) =>
  ServerCookie(context)[TOKEN_STORAGE_KEY]

export const setToken = (token: string) => {
  Cookie.set(TOKEN_STORAGE_KEY, token, { expires: 7 })
}

export const clearToken = () =>
  Cookie.remove(TOKEN_STORAGE_KEY)

export const getToken = () =>
  Cookie.get(TOKEN_STORAGE_KEY)

export const isLogged = () =>
  typeof Cookie.get(TOKEN_STORAGE_KEY) === 'string'

export const setTokenAndType = (TokenType: string, IdToken: string) =>
  localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify({ TokenType, IdToken }))

export const postSignIn = async (username: string, password: string) => {
  const signInResponse = await Auth.signIn({
    username,
    password
  })

  let payloadSign
  if (signInResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
    const { challengeParam } = signInResponse
    const verifyUser = await Auth.completeNewPassword(signInResponse, password,
      {
        email: challengeParam.userAttributes.email,
        phone_number: challengeParam.userAttributes.phone_number
      })

    const { signInUserSession: { idToken } } = verifyUser
    payloadSign = {
      email: idToken.payload.email,
      phoneNumber: idToken.payload.phone_number,
      accessToken: idToken.jwtToken,
      userRole: idToken.payload['cognito:groups'],
      cpf: idToken.payload['custom:cpf']
    }
  } else {
    const { signInUserSession: { idToken } } = signInResponse
    payloadSign = {
      email: idToken.payload.email,
      phoneNumber: idToken.payload.phone_number,
      accessToken: idToken.jwtToken,
      userRole: idToken.payload['cognito:groups'],
      cpf: idToken.payload['custom:cpf']
    }
  }

  return {
    ...payloadSign,
    loggedInWith: 'Bearer',
    status: signInResponse.challengeName
  }
}

export const updateCognitoUserAttribute = async (attributes: Record<string, string>) =>  {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  await Auth.updateUserAttributes(cognitoUser, attributes)
  
  if (attributes['custom:cpf']) {
    updateCPF(attributes['custom:cpf'])
  }
}