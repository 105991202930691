import { parsePhoneNumber } from 'libphonenumber-js'
import VMasker from 'vanilla-masker'

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)

export function formatCep (text: string = '') {
  return text ? VMasker.toPattern(text, '99999-999') : ''
}

export function formatCPF (text: string = '') {
  return text ? VMasker.toPattern(text, '999.999.999-99') : ''
}

export function formatCPFOnlyNumber (text: string = '') {
  return text ? VMasker.toPattern(text, '99999999999') : ''
}

export function formatPhone (text: string = '') {
  return text ? VMasker.toPattern(text, '(99) 99999-9999') : ''
}

export function parseIntlPhone (text: string = '') {
  return text ? parsePhoneNumber(text).format('INTERNATIONAL') : ''
}

export function formatDateCard (text: string = '') {
  return text ? VMasker.toPattern(text, '99/99') : ''
}

export function formatCodeCard (text: string = '') {
  return text ? VMasker.toPattern(text, '999') : ''
}

export function formatNumberCard (text: string = '') {
  return text ? VMasker.toPattern(text, '9999 9999 9999 9999') : ''
}

export function truncate (input: string = '', limit = 30) {
  if (input.length > limit) return `${input.substring(0, limit)} ...`
  return input
}

export const unMaskOnlyNumber = (text: string) => {
  return text.match(/\d/g)?.join('') ?? ''
}

export const getFirstName = (str?: string) => {
  if (!str) return ''
  const arr = str.split(' ')
  if (arr.length === 1) {
    return arr[0]
  }
  return arr.slice(0, -1).join(' ')
}

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const generateKey = (pre: string) => {
  return `${pre}_${new Date().getTime()}`
}
