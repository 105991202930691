import { Visible } from 'react-grid-system'
import { useForm } from 'react-hook-form'

import Router from 'next/router'

import SearchComponet from '@material-ui/icons/Search'
import { View } from 'components/helpers'
import useIsMobile from 'hooks/useIsMobile'

import { Container, Input, Content, SearchButton } from './styles'
import { iconSize } from 'styles'

const SearchLayout: React.FC = () => {
  const { register, handleSubmit } = useForm()
  const mobile = useIsMobile()

  const onSubmit = (data: any) => {
    Router.push({
      pathname: '/busca',
      query: { q: data.name, limit: 30 }
    })
  }

  return (
    <Visible xs>
      <View padding={mobile ? 1 : 0}>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Content>
              <Input
                ref={register}
                placeholder='O que você está procurando?'
                name='name'
              />
              <SearchButton onClick={handleSubmit(onSubmit)}>
                <SearchComponet style={{ ...iconSize, color: mobile ? '#ffffff' : '#3e3e3e' }} />
                <input type='submit' style={{ position: 'absolute', left: '-9999px' }} />
              </SearchButton>
            </Content>
          </form>
        </Container>
      </View>
    </Visible>
  )
}

export default SearchLayout
