import styled from 'styled-components'

export const Container = styled.footer`
  width: 100%;

  padding: 1rem;
  padding-top: 40px;
  margin-top: 2rem;
  background-color: #EDEDED;

  .payment-cards__box {
    flex-wrap:wrap;
    justify-content:center;
    @media(min-width:600px){
      justify-content:flex-start;
    }
  }
  .nav-footer {
    p {
      text-align:center;
      @media(min-width:600px){
        text-align:left;
      }
    }
  }
  .footer-bottom {
    &__mob {
      @media(min-width:960px){
        display:none;
      }
    }
    &__desk {
      display:none;
      @media(min-width:960px){
        display:block;
      }
    }
  }
`

export const Content = styled.div`
  max-width: 1366px !important;
  margin: 0 auto;
`

export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  &.socials__box {
    align-items:center;
    @media(min-width:960px){
      align-items: flex-start;
    }
  }
`

export const Box = styled.div`
  background: #FFF;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  @media(min-width:500px){
    height: 85px;
    flex-direction: row;
  }
  .apps__images {
    display:inline-block;
    text-align:center;
    padding-top:10px;
    @media(min-width:500px){
      width:70%;
      display:block;
      text-align:left;
    }
    @media(min-width:960px){
      padding:4px 0 0 0;
      text-align:center;
    }
    @media(min-width:1350px){
      display:inline-block;
    }
    img {
      display:inline-block;
      margin:0 5px;
      width:110px;
      @media(min-width:360px){
        width:130px;
      }
      @media(min-width:500px){
        display:inline;
        margin:0 10px 0 0;
      }
      @media(min-width:960px){
        margin:0 10px;
        width:100px;
      }
      @media(min-width:1350px){
        margin:0 3px 0 0;
        width:130px;
      }
    }
  }
  .whatsApp__box {
    &-col {
      @media(min-width:500px){
        width:6rem;
        align-items:center;
      }
    }
  }
  .whatsApp__text {
    > p {
      margin-top:10px;
      @media(min-width:500px){
        margin-top:0;
      }
    }
    p {
      text-align:center;
      @media(min-width:500px){
        text-align:left;
      }
    }
  }
  .time__box {
    &-col {
      @media(min-width:500px){
        width:6rem;
        align-items:center;
      }
    }
    &-text {
      @media(min-width:500px){
        width:70%;
      }
      > p {
        margin-top:10px;
        @media(min-width:500px){
          margin-top:0;
        }
      }
      p {
        text-align:center;
        @media(min-width:500px){
          text-align:left;
        }
      }
    }
  }
`
