import styled from 'styled-components'

const SubCategory = styled.button<{selected: boolean}>`
  background: ${({ selected = false }) => selected ? '#3097E2' : '#FFFFFF'};
  border: 1px solid ${({ selected = false }) => selected ? '#3097E2' : '#C4C4C4'};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  color: ${({ selected = false }) => selected ? '#fff' : '#979797'};
  width: fit-content;
  margin-right: 4px;
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    background: #3097E2;
    border: 1px solid #3097E2;
    color: #FFFFFF;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-grow: 1;

    padding: 0.5rem;

    align-items: center;
    justify-content: center;
  }
`

export default SubCategory
