import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

const Content = styled<any>(motion.div)<{ isFull?: boolean, isMobile?: boolean }>`
  padding: 0 1rem;
  padding-bottom: 1rem;
  width: 100%;
  max-width: 1366px !important;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 1rem;

  ${({ isFull }) => isFull ? css`
  height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  ` : undefined}

  ${({ isMobile }) => isMobile ? css`
    padding-left: 0px;
    padding-right: 0px;
  ` : undefined}
`

export default Content
