import { Visible } from 'react-grid-system'

import Image from 'next/image'
import Link from 'next/link'
import Router from 'next/router'

import Icon from 'components/elements/Icon'
import { View } from 'components/helpers'
import { getMobileClassName } from 'utils/helper/className'

import { Container, Content } from './styles'

const HeaderPayment: React.FC = () => {
  const goBack = () => {
    Router.back()
  }

  return (
    <Container className={getMobileClassName()}>
      <Content>
        <Visible xs>
          <View onClick={goBack} className='float-goback'>
            <Icon name='caret-left' />
          </View>
        </Visible>

        <Link href='/' passHref>
          <a>
            <Image
              src='/images/logo-small.svg'
              width='36'
              height='40'
              alt='Farmazon Logo'
            />
          </a>
        </Link>
      </Content>
    </Container>
  )
}

export default HeaderPayment
