import styled from 'styled-components'

const LinkCategory = styled.div<{selected?: boolean}>`
  flex-grow: 1;
  width: 100%;
  background: ${({ selected = false }) => selected ? '#EA2A2F' : '#FFFFFF'};
  border: 1px solid rgb(237, 237, 237);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

  p {
    color: ${({ selected = false }) => selected && '#FFFFFF'};
  }

  path {
    fill: ${({ selected = false }) => selected ? '#FFFFFF' : '#EA2A2F'};
  }

  &:hover {
    background: #EA2A2F;
    p {
      color: #FFFFFF;
    }
    path {
      fill: #fff !important;
    }
  }
`

export default LinkCategory
