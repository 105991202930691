import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

interface ContainerProps {
  isFull: boolean
  paddingTop?: number
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  z-index: 10;
  padding-top: 3rem;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);

  display: flex;

  ${({ paddingTop }) => paddingTop && `
      padding-top: ${paddingTop}rem!important;
    `}

  @media screen and (max-width: 576px) {
    ${({ isFull }) => isFull && css`
      padding-top: 0px;
    `}
  }

  @media screen and (min-width: 576px) {
    padding-top: 8rem;
  }
`

export const View = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 1;
`

export const BackView = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  cursor: pointer;
  z-index: 1;
`

interface ContentProps {
  isFull: boolean
  onBottom: boolean
  width?: string
}

export const Content = styled(motion.div)<ContentProps>`
  position: relative;
  background-color: #fefefe;
  width: 94%;
  padding: 1rem 1.8rem 1.2rem 1.8rem;
  border: 1px solid #EDEDED;
  display: flex;
  flex-direction: column;
  margin-left:3%;
  align-items: center;

  @media screen and (max-width: 576px) {
    ${({ isFull }) => isFull && css`
      width: 100%;
      height: 100%;
      padding: 0px 0px 0px 0px;
      margin-left: 0px;
      border: 0px;
    `}

    ${({ onBottom }) => onBottom && css`
      height: auto;
      margin-top: auto;
      
      width: 100%;
      margin-left: 0;
    `}
  }


  @media screen and (min-width: 600px) {
    width: fit-content;
    height: auto;
    min-height: 22rem;
    max-height: 44rem;
    margin: auto;
  }

  @media screen and (min-width: 900px) {
    width: ${({ width }) => width || 'fit-content'};
    margin: auto;
  }
`
