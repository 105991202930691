import styled from 'styled-components'

const Jumbotron = styled.div`
  padding: 0 0;
  padding-bottom: 1rem;
  max-width: 1366px !important;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 2px;

  @media screen and (min-width: 600px) {
    margin-top: 3rem;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    flex-wrap: wrap;
    overflow: initial;
  }

`

export default Jumbotron
