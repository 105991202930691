import { AnyAction } from 'redux'

const ADD_ITEM_CART = 'cartReducer/ADD_ITEM_CART'
const SET_ITEM_COUNT = 'cartReducer/SET_ITEM_COUNT'
const REMOVE_ITEM_COUNT = 'cartReducer/REMOVE_ITEM_COUNT'
const RESET_CART = 'cartReducer/RESET_CART'
const SET_CART = 'cartReducer/SET_CART'

const TOGGLE_CANCEL = 'cartReducer/TOGGLE_CANCEL'
const TOGGLE_PARCIAL = 'cartReducer/TOGGLE_PARCIAL'
const TOGGLE_SIMILAR = 'cartReducer/TOGGLE_SIMILAR'
const SET_CUPOM = 'cartReducer/SET_CUPOM'
const SET_INSTALLMENTS = 'cartReducer/SET_INSTALLMENTS'
const SET_CUPOM_DATA = 'cartReducer/SET_CUPOM_DATA'
const RESET_CUPOM = 'cartReducer/RESET_CUPOM'
const SET_DELIVERY_TYPE = 'cartReducer/SET_DELIVERY_TYPE'
const ADD_NOTE = 'cartReducer/ADD_NOTE'
const ADD_SCHEDULE = 'cartReducer/ADD_SCHEDULE'
const ADD_PRODUCT_MAIN = 'cartReducer/ADD_PRODUCT_MAIN'
const CLEAN_PRODUCT_MAIN = 'cartReducer/CLEAN_PRODUCT_MAIN'
const SET_MEDICAL_PRESCRIPTION = 'cartReducer/SET_MEDICAL_PRESCRIPTION'
const ADD_DELIVERY_TYPE = 'cartReducer/ADD_DELIVERY_TYPE'
const SET_PAYMENT_MODE = 'cartReducer/SET_PAYMENT_MODE'
const SET_PAYMENT_TYPE = 'cartReducer/SET_PAYMENT_TYPE'
const SET_PAYMENT_CHANGE_FOR = 'cartReducer/SET_PAYMENT_CHANGE_FOR'
const ADD_PAYMENT_CARD = 'cartReducer/ADD_PAYMENT_CARD'
const ADD_CPF = 'cartReducer/ADD_CPF'
const UPDATE_ITEM_CART = 'cartReducer/UPDATE_ITEM_CART'

export interface CardProductProps {
  pub_id: string
  name: string
  quantity: number
  price: number
  min_price: number
  max_price: number
  description: string
  required: boolean | null
  substitutes: any[]
  prescription: boolean
  medical_prescription?: string
  characteristic: any
  img: string
  isOffer?: boolean
  store_out_range?: boolean
  available_quantity?: number
  store?: string
  store_product_id?: string
  is_available?: boolean
}

export interface CouponProps {
  value: number
  code: string
  created_at: number
  expiration: string
  description: string
  pub_id: string
  type: string
}

export interface PaymentType {
  mode: string | 'card' | 'cash'
  change_for: number
  description: string | 'app' | 'presential'
}

export interface StateCartProps {
  items: CardProductProps[]
  product: CardProductProps | null
  deliveryRate: number
  valueCoupon: string
  coupon: CouponProps | null
  cancelOption: boolean
  parcialOption: boolean
  similarOption: boolean
  installments: number
  note: string
  scheduleTo: any
  deliveryType: string | 'farmazon_delivery' | 'store_delivery'
  paymentType: PaymentType
  cardSeleted: null | {
    mask_number: string
    default: boolean
    card_token: string
    pub_id: string
    branch: string
  }
  cpf: string
}

const stateInit = {
  items: [],
  product: null,
  deliveryRate: 0,
  cancelOption: false,
  parcialOption: false,
  similarOption: false,
  installments: 1,
  valueCoupon: '',
  coupon: null,
  scheduleTo: null,
  note: '',
  deliveryType: 'store_delivery',
  paymentType: {
    mode: '',
    change_for: 0,
    description: 'app'
  },
  cardSeleted: null,
  cpf: ''
}

export const cartReducer = (state: StateCartProps = stateInit, { type, payload }: AnyAction) => {
  switch (type) {
    case ADD_CPF:
      return { ...state, cpf: payload }
    case ADD_PAYMENT_CARD:
      return { ...state, cardSeleted: payload }
    case SET_PAYMENT_CHANGE_FOR:
      return { ...state, paymentType: { ...state.paymentType, change_for: payload } }
    case SET_PAYMENT_TYPE:
      return { ...state, paymentType: { ...state.paymentType, description: payload } }
    case SET_PAYMENT_MODE:
      return { ...state, paymentType: { ...state.paymentType, mode: payload } }
    case ADD_DELIVERY_TYPE:
      return { ...state, deliveryType: payload }
    case ADD_PRODUCT_MAIN:
      return { ...state, product: payload }
    case CLEAN_PRODUCT_MAIN:
      return { ...state, product: null }
    case ADD_SCHEDULE:
      return { ...state, scheduleTo: payload }
    case ADD_NOTE:
      return { ...state, note: payload }
    case ADD_ITEM_CART:
      return { ...state, items: [...state.items, payload] }
    case UPDATE_ITEM_CART:
      return { ...state, items: state.items.map(item => item.pub_id === payload.pub_id ? payload : item) }
    case SET_CART:
      return { ...state, items: payload }
    case TOGGLE_CANCEL:
      return { ...state, cancelOption: payload }
    case TOGGLE_PARCIAL:
      return { ...state, parcialOption: payload }
    case TOGGLE_SIMILAR:
      return { ...state, similarOption: payload }
    case SET_CUPOM:
      return { ...state, valueCoupon: payload }
    case SET_INSTALLMENTS:
      return { ...state, installments: payload }
    case SET_ITEM_COUNT:
      return { ...state, items: state.items.map(item => item.pub_id === payload.id ? { ...item, quantity: payload.quantity } : item) }
    case SET_MEDICAL_PRESCRIPTION:
      return { ...state, items: state.items.map(item => item.pub_id === payload.id ? { ...item, medical_prescription: payload.medicalPrescription } : item) }
    case REMOVE_ITEM_COUNT:
      return { ...state, items: state.items.filter(item => item.pub_id !== payload.id) }
    case SET_CUPOM_DATA:
      return { ...state, coupon: payload }
    case RESET_CUPOM:
      return { ...state, coupon: null, valueCoupon: '' }
    case RESET_CART:
      return stateInit
    case SET_DELIVERY_TYPE:
      return {
        ...state,
        deliveryType: {
          id: payload.id,
          name: payload.name,
          price: payload.price
        }
      }
    default:
      return state
  }
}

export const updateItemCardAction = (payload: any) =>
  ({ type: UPDATE_ITEM_CART, payload })

export const addCPFOrderAction = (payload: string) =>
  ({ type: ADD_CPF, payload })

export const addCardSelectedAction = (payload: any) =>
  ({ type: ADD_PAYMENT_CARD, payload })

export const setPaymentChangeAction = (payload: number) =>
  ({ type: SET_PAYMENT_CHANGE_FOR, payload })

export const setPaymentTypeAction = (payload: string) =>
  ({ type: SET_PAYMENT_TYPE, payload })

export const setPaymentModeAction = (payload: string) =>
  ({ type: SET_PAYMENT_MODE, payload })

export const setDeliveryTypeAction = (type: string) =>
  ({ type: ADD_DELIVERY_TYPE, payload: type })

export const setMedicalPrescriptionAction = (id: string, medicalPrescription: string) =>
  ({ type: SET_MEDICAL_PRESCRIPTION, payload: { id, medicalPrescription } })

export const addProductMainAction = (payload: any) =>
  ({ type: ADD_PRODUCT_MAIN, payload })

export const cleanProductMainAction = () =>
  ({ type: CLEAN_PRODUCT_MAIN })

export const addScheduleAction = (payload: any) =>
  ({ type: ADD_SCHEDULE, payload })

export const addNoteCartAction = (payload: string) =>
  ({ type: ADD_NOTE, payload })

export const resetCupomAction = () =>
  ({ type: RESET_CUPOM })

export const setCupomDataAction = (payload: CouponProps) =>
  ({ type: SET_CUPOM_DATA, payload })

export const setCancelOptionAction = (payload: boolean) =>
  ({ type: TOGGLE_CANCEL, payload })

export const setParcialOptionAction = (payload: boolean) =>
  ({ type: TOGGLE_PARCIAL, payload })

export const setSimilarOptionAction = (payload: boolean) =>
  ({ type: TOGGLE_SIMILAR, payload })

export const setCupomAction = (payload: string) =>
  ({ type: SET_CUPOM, payload })

export const setInstallmentsAction = (payload: number) =>
  ({ type: SET_INSTALLMENTS, payload })

export const addItemCartAction = (payload: Partial<CardProductProps>) =>
  ({ type: ADD_ITEM_CART, payload })

export const setCountItemCartAction = (id: string, quantity: number) =>
  ({ type: SET_ITEM_COUNT, payload: { id, quantity } })

export const removeItemCartAction = (id: string) =>
  ({ type: REMOVE_ITEM_COUNT, payload: { id } })

export const resetCartAction = () =>
  ({ type: RESET_CART })

export const setCartAction = (payload: any[]) =>
  ({ type: SET_CART, payload })
