
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import CloseIcon from '@material-ui/icons/Close'
import { disableCartAction } from 'store/reducers/appReducer'

import { Container, Content, View } from './styles'
import { iconSize } from 'styles'

import { ModalCartProps } from './types'

const ModalCart: React.FC<ModalCartProps> = ({
  children,
  cartToggle
}) => {
  const dispatch = useDispatch()

  const onPressClose = () => {
    dispatch(disableCartAction())
  }

  useEffect(() => {
    if (cartToggle) document.body.style.overflow = 'hidden'
    return () => { if (cartToggle) document.body.style.overflow = 'auto' }
  }, [cartToggle])

  return (
    <>
      <Container className='fechar_carrinho' toggle={cartToggle ? 1 : 0} onClick={onPressClose} />
      <Content toggle={cartToggle ? 1 : 0}>
        <View className='fechar_carrinho' style={{ zIndex: 1 }} onClick={onPressClose}>
          <CloseIcon style={iconSize} />
        </View>
        {children}
      </Content>
    </>
  )
}

export default ModalCart
