import { ReactNode } from 'react'

import { Container } from './styles'

interface ButtonCardProps {
  children: ReactNode
  selected: boolean
  onClick: () => void
}

function ButtonCard ({
  children,
  selected,
  onClick
}: ButtonCardProps) {
  return (
    <Container
      selected={selected}
      onClick={onClick}
    >
      {children}
    </Container>
  )
};

export default ButtonCard
