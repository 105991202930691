import { ReactNode } from 'react'

import { Container } from './styles'

interface PaymentTypeProps {
  children: ReactNode
  selected?: boolean
  onClick?: () => void
}

function PaymentType ({ children, selected, onClick }: PaymentTypeProps) {
  return (
    <Container
      selected={selected}
      onClick={onClick}
    >
      {children}
    </Container>
  )
};

export default PaymentType
