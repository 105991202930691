import styled from 'styled-components'

export const Container = styled.div`
  background-color: #FF0037;
  padding: .8rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const Text = styled.p`
  color: #fff;
  font-size: 15px;
`

export const View = styled.div`
  margin-right: 1rem;
`
