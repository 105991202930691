import styled from 'styled-components'

interface ButtonProps {
  background?: string
  color?: string
  width?: string
  borderColor?: string
  decoration?: string
  fontSize?: number
}

export const ButtonC = styled.button<ButtonProps>`
  background-color: ${({ background = '#FF0037' }) => background};
  text-align: center;
  padding: 0.8rem 1rem;
  border: none;
  color: ${({ color = '#fff' }) => color};
  width: ${({ width = '100%' }) => width};
  outline: 0;
  font-weight: 500;
  text-decoration: ${({ decoration = 'none' }) => decoration};
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '14px'};
  border-radius: 4px;
  border: ${({ borderColor }) => borderColor ? `2px solid ${borderColor}` : 'none'}
`

export const Loading = styled.div<{ color?: string }>`
  border: 2px solid ${({ color }) => color};
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
