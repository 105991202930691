import '../styles/icons.css'
import '../styles/globals.css'

import { useEffect } from 'react'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { hotjar } from 'react-hotjar'
import { batch, Provider } from 'react-redux'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'

import Amplify, { Auth } from 'aws-amplify'
import { Alert } from 'components/elements'
import Analytics from 'components/helpers/Analytics'
import GoogleTagManager from 'components/helpers/GoogleTagManager'
import amplifyConfig from 'config/awsConfig'
import { ModalManager } from 'container'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { getToken } from 'services/AuthService'
import { getTokenAndRefresh } from 'services/product'
import { useStore } from 'store'
import { RootState } from 'store/reducers'
import { resetCartAction } from 'store/reducers/cartReducer'
import { cleanOptionProductAction } from 'store/reducers/optionProductReducer'
import { resetProductPrescriptionState } from 'store/reducers/prescriptionReducer'
import { cleanSimilarAction } from 'store/reducers/similarReducer'
import { resetUserState } from 'store/reducers/userReducer'
import { ThemeProvider } from 'styled-components'
import * as gtag from 'utils/gtag'

import { defaultTheme, GlobalStyle } from 'styles'

const options = {
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: '0px',
  transition: transitions.FADE
}

Amplify.configure(amplifyConfig)
Auth.configure(amplifyConfig)

function MyApp ({ Component, pageProps }: AppProps) {
  const store = useStore(pageProps.initialReduxState)
  const persistor = persistStore(store, {}, () => persistor.persist())

  const router = useRouter()

  const checkUserAndToken = () => {
    const token = getToken()
    const { user } = store.getState() as RootState

    if (!token && (user.username || user.email || user.name)) {
      batch(() => {
        store.dispatch(resetCartAction())
        store.dispatch(resetUserState())
        store.dispatch(cleanSimilarAction())
        store.dispatch(resetProductPrescriptionState())
        store.dispatch(cleanOptionProductAction())
      })
    }

    if (token) {
      const [, hash] = token?.split(' ')
      const payload = {
        type: 'signedIn',
        params: {
          token: hash,
          isUserAdmin: user.userRole?.includes('admin')
        }
      }

      if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify(payload))
      }
    }
  }

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    checkUserAndToken()
    getTokenAndRefresh(store)
    const hotjarID = process.env.HOTJAR_ID ? parseInt(process.env.HOTJAR_ID) : 0
    hotjar.initialize(hotjarID, 6)
  }, [])

  return (
    <>
      <Head>
        <title>Farmazon - Viva a experiência</title>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta property='og:locale' content='pt_BR' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Farmazon - Viva a experiência' />
        <meta property='og:description' content='Viva a experiência Farmazon Ajudamos você a receber o que precisa sem perder tempo e tranquilidade. Baixe o aplicativo no celular ou no tablet para ter acesso aos produtos oferecidos pelas farmácias e drogarias parceiras. Escolha o produto e nós o entregamos a você.' />
        <meta property='og:url' content='https://farmazon.com.br/' />
        <meta property='og:site_name' content='Farmazon' />
        <meta property='article:publisher' content='http://facebook.com/farmazonbr' />
        <meta property='article:modified_time' content='2020-12-09T04:53:40+00:00' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:label1' content='Written by' />
        <meta name='twitter:data1' content='webis' />
        <meta name='twitter:label2' content='Est. reading time' />
        <meta name='twitter:data2' content='0 minuto' />
        <meta name='apple-itunes-app' content='app-id=1453428847, app-argument=farmazon://' />
        <link rel='icon' href='/favicon.ico' />
        <link rel='preconnect' href='https://fonts.googleapis.com' />

        <Script
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${process.env.FACEBOOK_ID}');
              fbq('track', 'PageView');`
          }}
        />
        <script />
        <noscript>
          <img height='1' width='1' style={{ display: 'none' }} src={`https://www.facebook.com/tr?id=${process.env.FACEBOOK_ID}&ev=PageView&noscript=1`} />
        </noscript>
      </Head>

      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <GlobalStyle />
          <AlertProvider className='alert-custom' template={Alert} {...options}>
            <ThemeProvider theme={defaultTheme}>
              <GoogleTagManager>
                <ModalManager>
                  <Component {...pageProps} />
                  <Analytics />
                </ModalManager>
              </GoogleTagManager>
            </ThemeProvider>
          </AlertProvider>
        </PersistGate>
      </Provider>

    </>
  )
}

export default MyApp
