
import { ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import Image from 'next/image'
import Link from 'next/link'

import { Button, InputText } from 'components/form'
import { Text, View } from 'components/helpers'
import usePrescription from 'hooks/usePrescription'
import { MedicalPrescriptionProps } from 'services/order'
import { formatCPF, formatPhone } from 'utils/helper/string'

import { Container } from './styles'

export default function PrescriptionProduct () {
  const {
    loading,
    enableForm,
    handleToggleForm,
    handleMedicalPrescription
  } = usePrescription()

  const { register, handleSubmit, setValue, errors } = useForm<MedicalPrescriptionProps>()
  const dispatch = useDispatch()

  const handleSubmitPrescription = handleSubmit((data) => {
    dispatch(handleMedicalPrescription(data))
  })

  const onChangeCpf = (e: ChangeEvent<HTMLInputElement>) =>
    setValue('cpf', formatCPF(e.target.value))

  const onChangePhone = (e: ChangeEvent<HTMLInputElement>) =>
    setValue('phone', formatPhone(e.target.value))

  const handleClean = (event: any) => {
    event.target.value = null
  }

  return (
    <>
      {enableForm ? (
        <Container>
          <View justify='center' align='center' mt={2} mb={1}>
            <Image
              src='/images/receita.svg'
              width='28'
              height='35'
              alt='Receita'
              layout='fixed'
            />
          </View>

          <View mb={1}>
            <Text color='#3097E2' size={22} fontWeight={500} textAlign='center'>
              Envio da receita médica
            </Text>
          </View>

          <View mb={1}>
            <Text textAlign='center' size={14}>
              Para incluir este medicamento no seu carrinho, você precisa enviar a receita médica.
              <br />
              <br />
              Preencha os campos abaixo do paciente.
            </Text>
          </View>

          <form id='enviar_receita' onSubmit={handleSubmitPrescription}>
            <View mb={1}>
              <InputText
                name='name'
                ref={register({ required: 'Digite o nome completo.' })}
                placeholder='Nome completo do paciente'
                errorMessage={errors.name?.message}
              />
            </View>

            <View mb={1}>
              <InputText
                name='phone'
                inputMode='numeric'
                ref={register({ required: 'Digite seu telefone' })}
                placeholder='Telefone celular'
                errorMessage={errors.phone?.message}
                onChange={onChangePhone}
              />
            </View>

            <View mb={1}>
              <InputText
                name='cpf'
                inputMode='numeric'
                ref={register({ required: 'Digite seu CPF' })}
                placeholder='CPF do paciente'
                errorMessage={errors.cpf?.message}
                onChange={onChangeCpf}
              />
            </View>

            <View style={{ position: 'relative' }} mb={1}>
              <InputText
                name='file'
                type='file'
                ref={register({ required: 'Selecione um arquivo PDF' })}
                placeholder='Inserir arquivo'
                errorMessage={errors.file?.message}
                accept='.pdf'
                onClick={handleClean}
              />
              <p className='text-gray-500 text-xs mt-1'>* Somente arquivo PDF</p>
            </View>

            <View mb={1}>
              <Button
                label='Enviar receita'
                background='#3097E2'
                loading={loading}
              />
            </View>

          </form>
        </Container>
      ) : (
        <View width='16rem'>
          <View justify='center' align='center' mt={2} mb={1}>
            <Image
              src='/images/receita.svg'
              width='28'
              height='35'
              alt='Receita'
              layout='fixed'
            />
          </View>

          <View mb={1}>
            <Text color='#3097E2' size={22} fontWeight={500} textAlign='center'>
              Envio da receita médica
            </Text>
          </View>

          <View mb={1}>
            <Text textAlign='center' size={14}>
              Para enviar sua receita você deve enviar o arquivo no formato PDF ou image.
            </Text>
          </View>

          <View mb={1}>
            <Button
              id='ok_entendi_receita'
              label='Ok, entendi'
              background='#3097E2'
              onClick={handleToggleForm}
            />
          </View>

          <View>
            <Text textAlign='center' size={14}>
              Preciso de ajuda.
            </Text>

            <View>
              <Link
                href='https://api.whatsapp.com/send?phone=552140422272&text=Estou tendo problema para anexar uma receita.'
                passHref
              >
                <a target='_blank' id='quero_falar'>
                  <Text textAlign='center' size={14}>
                    <strong>Quero falar com um atendente</strong>
                  </Text>
                </a>
              </Link>
            </View>
          </View>
        </View>
      )}

    </>
  )
}
