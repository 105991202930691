import styled from 'styled-components'

export const Container = styled.div<{background?: string}>`
  background-color: ${({ background = '#51c472' }) => background};
  border-radius: 40px;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  margin-top: 1px;
`
