import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`

export const SimilarView = styled.div<{selected?: boolean}>`
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:  #F2F2F2;
  margin-top: 1rem;
  position: relative;
  cursor: pointer;
`

export const PrescriptionView = styled.div`
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F2F2F2;
  margin-top: 1rem;
  position: relative;
`

export const RangeView = styled.div`
  background: url('/images/range-price.png') no-repeat;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 36px;
  margin-top: 1rem;
`

export const Circle = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  background-color: #3097E2;
  position: absolute;
  right: 10px;
`
