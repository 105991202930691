import { useMemo } from 'react'
import { useAlert } from 'react-alert'
import { Visible } from 'react-grid-system'
import { batch, useDispatch, useSelector } from 'react-redux'

import Image from 'next/image'
import Router from 'next/router'

import SearchIcon from '@material-ui/icons/Search'
import { BadgeDelivery, Count } from 'components/elements'
import Icon from 'components/elements/Icon'
import { Button } from 'components/form'
import { Line, Row, Text, View } from 'components/helpers'
import { useProduct } from 'hooks/useProduct'
import { isLogged } from 'services/AuthService'
import { RootState } from 'store/reducers'
import { disableCartAction, disableLoadingAction, enableLoadingAction, enableLocationAction } from 'store/reducers/appReducer'
import { removeItemCartAction, setCountItemCartAction } from 'store/reducers/cartReducer'
import { addIdProductSubAction } from 'store/reducers/similarReducer'
import { getMoreExpensiveProductSubstitute, totalPriceProduct } from 'utils/helper/calc'
import { numberFormat } from 'utils/helper/currency'
import { formatCurrency, truncate } from 'utils/helper/string'

import { Container, Box, SimilarView, Circle, PrescriptionView } from './styles'

const CartBasket: React.FC = () => {
  const { cart, prescription, user, app, total } = useSelector((state: RootState) => ({
    cart: state.cart,
    user: state.user,
    app: state.app,
    prescription: state.prescription,
    total: totalPriceProduct(state.cart.items)
  }))
  const dispatch = useDispatch()
  const alert = useAlert()
  const {
    getProductOption,
    getIconOption,
    handleCartSimilar,
    handleChargeOption,
    handlePrescription
  } = useProduct()

  const setCountItem = (id: string, quantity: number) =>
    () => dispatch(setCountItemCartAction(id, quantity))

  const onDecrement = (id: string, quantity: number) =>
    () => {
      if (quantity >= 1) dispatch(setCountItemCartAction(id, quantity))
    }

  const removeItem = (id: string) =>
    () => dispatch(removeItemCartAction(id))

  const isPrescriptionValid = useMemo(() => {
    return cart
      .items
      .filter(item => item.prescription)
      .map(item => (item.prescription && typeof item.medical_prescription === 'string'))
      .some(item => item === false)
  }, [cart.items])

  const isAvaliableStore = useMemo(() => {
    return cart
      .items
      .some(item => item.is_available === true)
  }, [cart.items])

  const onPressPayment = () => {
    dispatch(enableLoadingAction())

    if (isPrescriptionValid) {
      alert.error('No seu carrinho tem produto que exige o envio da receita médica.')
      dispatch(disableLoadingAction())
      return
    }

    if (isLogged()) {
      batch(() => {
        dispatch(disableCartAction())
        dispatch(disableLoadingAction())
      })

      if (user.delivery_addresses.length === 0) {
        alert.info('Cadastre um endereço para continuar a compra.')
      }

      Router.push('/checkout')
    } else {
      batch(() => {
        dispatch(disableCartAction())
        dispatch(disableLoadingAction())
        dispatch(enableLocationAction())
      })
      Router.push('/signin')
    }
  }

  const onGoToSignInHandler = () => {
    dispatch(disableCartAction())
    dispatch(disableLoadingAction())
    dispatch(enableLocationAction())

    Router.push('/signin')
  }

  const onPressClose = () => {
    dispatch(disableCartAction())
  }

  const handleMedicalPrescription = (idProduct: string) =>
    () => {
      dispatch(addIdProductSubAction(idProduct))
      handlePrescription()
    }

  const handleGoToSearch = () => {
    dispatch(disableCartAction())

    Router.push('/busca?q=')
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      {cart.items.length ? (
        <>
          <View
            mt={0.1}
            padding={1}
          >
            <Text
              size={18}
              fontWeight={700}
              color='#EA2A2F'
            >
              Meu carrinho
            </Text>
          </View>

{/* ------------------------------ */}
{/* -- CART BODY - PRODUCT LIST -- */}
{/* ------------------------------ */}
          <View
            style={{ overflowY: 'auto' }}
            padding={1}
          >
            {cart.items.map((item, key) => {
              const isVerified = item && prescription.prescriptions.find(prescription => prescription.idProduct === item.pub_id)
              let availableLabel: string | null = 'Indisponível na região'

              if (item.is_available) {
                availableLabel = 'Entrega rápida'
              }

              if (item.isOffer) {
                availableLabel = null
              }

              return (
                <View key={key}>

                  <View
                    direction='row'
                    justify='space-between'
                  >
                    <Row>
                      <Image
                        src={item.img}
                        width={120}
                        height={120}
                        alt={item.name}
                        layout='fixed'
                      />
                    </Row>

                    <View
                      mb={1}
                      justify='space-between'
                      style={{ flex: 0.8 }}
                    >
                      <View mt={0.6}>
                        <Text size={13} fontWeight={600}>{item.name}</Text>
                        <div className='flex items-center'>
                          {item.is_available ? <BadgeDelivery /> : <BadgeDelivery background='#de8d40' />}
                          {availableLabel ? <Text size={12} fontWeight={300} mt={0.2}>{availableLabel}</Text> : null}
                        </div>
                      </View>
                      <View width='7rem'>
                        <Count
                          quantity={item.quantity}
                          disabledIncrement={!!(item.available_quantity && item.available_quantity === item.quantity)}
                          onIncrement={setCountItem(item.pub_id, (item.quantity + 1))}
                          onDecrement={onDecrement(item.pub_id, (item.quantity - 1))}
                        />
                      </View>
                    </View>

                    <View>
                      <View
                        ml={1}
                        mt={0.6}
                        mr={0.4}
                        align='flex-end'
                        cursor
                        onClick={removeItem(item.pub_id)}
                        className='excluir_produto'
                      >
                        <Image
                          src='/icon/icon-trash.svg'
                          width={18}
                          height={19}
                          alt='Remover'
                          layout='fixed'
                        />
                      </View>

                      {item?.isOffer ? (
                        <View align='flex-end' mt={1.8}>
                          {/* <Text size={11} fontWeight={100} mt={0.2} style={{ textDecoration: 'line-through' }}>
                            {numberFormat(item.min_price)}
                          </Text> */}
                          <Text size={13} fontWeight={700} mt={0.2}>
                            {numberFormat(item.max_price)}
                          </Text>
                        </View>
                      ) : (
                        <View align='flex-end' mt={1.8}>
                          <Text size={11}>Preço</Text>
                          <Text size={13} fontWeight={700} mt={0.2}>
                            {numberFormat(item.max_price)}
                            {/* {numberFormat(item.min_price)} - {numberFormat(item.max_price)} */}
                          </Text>
                        </View>
                      )}

                    </View>
                  </View>

                  {item?.store_out_range ? (
                    <View padding={1} mb={1} mt={1} style={{ backgroundColor: 'rgba(255, 0, 0, 0.3)' }}>
                      <Text size={12} fontWeight={700}>O produto não tem na região.</Text>
                    </View>
                  ) : null}

                  {!item?.isOffer && (
                    <View mt={0.8}>
                      <Text size={12} fontWeight={400}>Se a farmácia não tiver este produto</Text>
                      <View
                        mt={1.2}
                        mb={0.4}
                        direction='row'
                        justify='space-between'
                      >
                        <View direction='row'>
                          <Image
                            src={getIconOption(item.required, item.substitutes)}
                            width={20}
                            height={20}
                            alt='Trocar'
                            layout='fixed'
                          />
                          <Text ml={1} size={14} fontWeight={500}>
                            {getProductOption(item.required, item.substitutes)}
                          </Text>
                        </View>
                        <Text
                          size={12}
                          fontWeight={700}
                          color='#3097E2'
                          cursor
                          onClick={handleChargeOption(item)}
                        >
                          Trocar
                        </Text>
                      </View>

                      {item.substitutes?.length > 0 && (
                        <SimilarView onClick={handleCartSimilar(item.pub_id, item)}>
                          <Text size={11}>Você selecionou <strong>{item.substitutes.length} produtos</strong> substitutos para este item.</Text>
                          <Circle>
                            <SearchIcon style={{
                              fontSize: '14px',
                              color: '#fff'
                            }}
                            />
                          </Circle>
                        </SimilarView>
                      )}

                      {(item.substitutes?.length > 0 && getMoreExpensiveProductSubstitute(item.substitutes).max_price > item.max_price) && (
                        <View width='28rem' mt={0.4}>
                          <Text size={10} color='#EA2A2F'>
                            O produto substituto <strong>{truncate(getMoreExpensiveProductSubstitute(item.substitutes).name)}</strong> está com o valor acima do produto selecionado - valor máximo de <strong>{formatCurrency(getMoreExpensiveProductSubstitute(item.substitutes).max_price)}</strong>
                          </Text>
                        </View>
                      )}

                      {item.medical_prescription ? (
                        <PrescriptionView>
                          <View direction='row' align='center'>
                            <Image
                              src='/images/receita.svg'
                              width='18'
                              height='18'
                              alt='Receita'
                              layout='fixed'
                            />
                            <View ml={0.8}>
                              <Text size={11}>Receita anexada</Text>
                            </View>
                          </View>

                          <Image
                            src='/images/receita-verificada.svg'
                            width='24'
                            height='24'
                            alt='Verificada'
                            layout='fixed'
                          />
                        </PrescriptionView>
                      ) : item.prescription ? (
                        <View
                          style={{ background: '#F2F2F2', width: '100%', padding: '0.8rem', cursor: 'pointer' }}
                          mb={1}
                          direction='row'
                          justify='space-between'
                          align='center'
                          onClick={isLogged() ? handleMedicalPrescription(item.pub_id) : onGoToSignInHandler}
                        >
                          <Image
                            src='/images/receita.svg'
                            width='18'
                            height='18'
                            alt='Receita'
                            layout='fixed'
                          />
                          <View className='upload-pront-text'>
                            <Text size={12}>Este medicamento exige o envio da receita médica.</Text>
                            {!isLogged() ? <Text mt={0.2} color='#EA2A2F' size={10}>É necessário fazer login</Text> : null}
                          </View>

                          {isLogged() ? (
                            <>
                              {isVerified ? (
                                <Image
                                  src='/images/receita-verificada.svg'
                                  width='32'
                                  height='32'
                                  alt='Download'
                                  layout='fixed'
                                />
                              ) : (
                                <Image
                                  src='/images/receita-download.svg'
                                  width='32'
                                  height='32'
                                  alt='Download'
                                  layout='fixed'
                                />
                              )}
                            </>
                          ) : null}
                        </View>
                      ) : null}

                    </View>
                  )}

                  <View pt={1} pb={1}>
                    <Line type='dashed' />
                  </View>
                </View>
              )
            })}

{/* -------- ADD MORE ITENS BUTTON -- */}
            <View mt={1}>
              <View width='100%'>
                <Button
                  id='add_mais_produtos'
                  label='Adicionar mais produtos'
                  background='#fff'
                  color='#3097E2'
                  borderColor='#3097E2'
                  onClick={onPressClose}
                />
              </View>
            </View>
          </View>
{/* ------------------------------ */}


{/* ---------------------- */}
{/* ---- FLOAT RESUME ---- */}
{/* ---------------------- */}
          <View
            mt={2}
            padding={1}
            className='tabbar-safe-padding'
            style={{ width: '100%', background: '#fff', marginTop: 'auto', boxShadow: '1px 3px 10px 3px #00000022', paddingTop: 0 }}
          >
            <View mt={1} mb={1}>
              <div className='flex flex-row items-center justify-between'>
                <Text size={14} fontWeight={300} mt={0.2}>Subtotal do pedido</Text>
                <View direction='row' align='center'>
                  <Text ml={0.4} size={22} fontWeight={700}>{numberFormat(total)}</Text>
                </View>
              </div>

            </View>
            <Row justify='space-between'>
              <View width='100%'>
                <Button
                  id='finalizar_compra'
                  label='Finalizar compra'
                  background='#3097E2'
                  color='#fff'
                  borderColor='#3097E2'
                  onClick={onPressPayment}
                  loading={app.loading}
                  disabled={!isAvaliableStore}
                />
              </View>
            </Row>
          </View>
{/* ---------------------- */}

        </>
      ) : (
        <>
          <Box>
            <View mt={4}>
              <Icon name='shopping-cart-simple' size='xxxlg' />
            </View>

            <Text mt={2} fontWeight={600}>Seu carrinho esta vazio.</Text>

            <Visible xs>
              <View mt={2}>
                <Button
                  background='#fff'
                  color='#3097E2'
                  borderColor='#3097E2' onClick={handleGoToSearch} label='Continuar comprando'
                />
              </View>
            </Visible>
          </Box>
        </>
      )}

    </Container>
  )
}

export default CartBasket
