
import { Container } from './styles'

const BadgeDelivery: React.FC<{background?: string}> = ({
  background
}) => {
  return (
    <Container background={background} />
  )
}

export default BadgeDelivery
