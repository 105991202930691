import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'store/configStorage'

import { addressTemporaryReducer, StateAddressTemporaryProps } from './addressTemporaryReducer'
import { appReducer, StateAppProps } from './appReducer'
import { cartReducer, StateCartProps } from './cartReducer'
import { configReducer, StateConfigProps } from './configReducer'
import { optionProductReducer, StateOptionProductProps } from './optionProductReducer'
import { prescriptionReducer, StatePrescriptionrProps } from './prescriptionReducer'
import { similarReducer, StateSimilarProps } from './similarReducer'
import { userReducer, StateUserProps } from './userReducer'

export const cleanVersion = 'farmazon'
export const newVersion = 'faramzon-v2-0.0.1'

const persistConfig = {
  key: newVersion,
  storage,
  blacklist: ['app', 'similar']
}

export interface RootState {
  user: StateUserProps
  app: StateAppProps
  config: StateConfigProps
  cart: StateCartProps
  similar: StateSimilarProps
  prescription: StatePrescriptionrProps
  addressTemporary: StateAddressTemporaryProps
  optionProduct: StateOptionProductProps
}

const reducersRoot = {
  user: userReducer,
  app: appReducer,
  cart: cartReducer,
  similar: similarReducer,
  prescription: prescriptionReducer,
  addressTemporary: addressTemporaryReducer,
  optionProduct: optionProductReducer,
  config: configReducer
}

const reducers = combineReducers(reducersRoot)

export const persistedReducer = persistReducer(persistConfig, reducers)

export default reducers
