import styled from 'styled-components'

const Dot = styled.div<{selected?: boolean}>`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 1px solid ${({ selected = false }) => selected ? 'transparent' : '#C4C4C4'};
  background-color: ${({ selected = false }) => selected ? '#52C372' : 'transparent'};
`

export default Dot
