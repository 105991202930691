import React from 'react'

import { Container } from './styles'

const BuyFooter = ({ children }: any) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

export default BuyFooter
